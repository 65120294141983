<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  props: {
    data: {
      type: Array,
      default: () => ([])
    },
  },
  data () {
    return {
      cdata: {
        xData: ["AC", "WA", "RE", "TLE", "CE"],
        seriesData: [
          { value: 0, name: "AC", result: 0 },
          { value: 0, name: "WA", result: -1 },
          { value: 0, name: "RE", result: 4 },
          { value: 0, name: "TLE", result: 1 },
          { value: 0, name: "CE", result: -2 },
        ]
      }
    }
  },
  components: {
    Chart,
  },
  created() {
    console.log(this.data);
    for(let i = 0; i < this.data.length; i++) {
      if(this.data[i].result === 0) {
        this.cdata.seriesData[0].value++;
      } else if(this.data[i].result === -1) {
        this.cdata.seriesData[1].value++;
      } else if(this.data[i].result === 4) {
        this.cdata.seriesData[2].value++;
      } else if(this.data[i].result === 1) {
        this.cdata.seriesData[3].value++;
      } else if(this.data[i].result === -2) {
        this.cdata.seriesData[4].value++;
      }
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>