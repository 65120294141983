<template>
  <div id="centerLeft1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-bar" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">{{title}}</span>
          <dv-decoration-3 class="dv-dec-3" />
        </div>
      </div>
      <div class="d-flex jc-center">
        <CenterLeft1Chart :data="needData"/>
      </div>
      <!-- 4个主要的数据 -->
      <div class="bottom-data">
        <div
          class="item-box mt-2"
          v-for="(item, index) in numberData"
          :key="index"
        >
          <div class="d-flex" style="text-align: center">
<!--            <span class="coin">￥</span>-->
<!--            <dv-digital-flop class="dv-digital-flop" style="text-align: center" :config="item.number" />-->
            <p style="text-align: center; font-size: 24px; color: #33cea0" class="dv-digital-flop">{{item.number.number[0]}}</p>
          </div>
          <p class="text" style="text-align: center;">
            {{ item.text }}
<!--            <span class="colorYellow">(次)</span>-->
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CenterLeft1Chart from '@/components/echart/centerLeft/centerLeft1Chart'

export default {
  props: {
    title: {
      type: String,
      default: '数据统计图'
    },
    problem_id: {
      type: Number,
      default: 0
    }
  },
  data() {
    // 传入图表的标题

    return {
      numberData: [
        {
          number: {
            number: [0],
            toFixed: 0,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 24
            }
          },
          text: '提交数量'
        },
        {
          number: {
            number: [0],
            toFixed: 0,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 24
            }
          },
          text: 'AC数量'
        },
        {
          number: {
            number: [0],
            toFixed: 0,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 24
            }
          },
          text: '正在编译数量'
        },
        {
          number: {
            number: [0],
            toFixed: 0,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 24
            }
          },
          text: '未通过数量'
        }
      ],
      needData: [],
      allData: [
        {
          "id": "41d90f26d959799b908f1494eac997e6",
          "problem_id": 1,
          "create_time": "15/10/2022 16:21:47.148945+08",
          "result": 0
        },
        {
          "id": "ad38d30da04424fa8a8c2a65e67e444b",
          "problem_id": 2,
          "create_time": "18/10/2022 10:06:13.414992+08",
          "result": 0
        },
        {
          "id": "b4b5f2b989fe21970f0b4923f8102f70",
          "problem_id": 2,
          "create_time": "15/10/2022 16:39:21.412746+08",
          "result": 0
        },
        {
          "id": "2b9b0af915e693bf0c626841b67fb56c",
          "problem_id": 1,
          "create_time": "15/10/2022 16:19:00.873383+08",
          "result": 0
        },
        {
          "id": "67d2d9bf4b6028b5b8a665bea0354ba6",
          "problem_id": 1,
          "create_time": "15/10/2022 19:33:30.203586+08",
          "result": 0
        },
        {
          "id": "8cf281de6c01f84a2a33845d0251cf33",
          "problem_id": 1,
          "create_time": "15/10/2022 17:48:59.18503+08",
          "result": 0
        },
        {
          "id": "257d5a440f7fc4f0086749d5fa1f9f81",
          "problem_id": 1,
          "create_time": "15/10/2022 18:20:06.177982+08",
          "result": 0
        },
        {
          "id": "de1e825c8eba901dc4a4fdce0b3520b4",
          "problem_id": 2,
          "create_time": "18/10/2022 20:27:38.117735+08",
          "result": 0
        },
        {
          "id": "6f3c02ba13570119f0520184555f7cca",
          "problem_id": 1,
          "create_time": "15/10/2022 19:27:50.989293+08",
          "result": -2
        },
        {
          "id": "b5e68166691768290959ee681bcf696a",
          "problem_id": 1,
          "create_time": "15/10/2022 19:28:19.315816+08",
          "result": -1
        },
        {
          "id": "b541edad3f94b7ed35c014463cf3d4b5",
          "problem_id": 2,
          "create_time": "16/10/2022 09:41:09.588283+08",
          "result": 4
        },
        {
          "id": "d092ced79ae5b535afc24b831179a6f0",
          "problem_id": 2,
          "create_time": "16/10/2022 04:11:58.595292+08",
          "result": 0
        },
        {
          "id": "c677b9664f95c33cd63d7765f748129d",
          "problem_id": 2,
          "create_time": "15/10/2022 23:00:58.172363+08",
          "result": 0
        },
        {
          "id": "65a6d70925ac584ecbfeb935214a4c4f",
          "problem_id": 2,
          "create_time": "18/10/2022 09:51:01.565763+08",
          "result": -1
        },
        {
          "id": "c7997a2ecdbf9b44e63c46e1cd08b95c",
          "problem_id": 2,
          "create_time": "15/10/2022 23:01:17.694427+08",
          "result": 0
        },
        {
          "id": "df43e4d0c28a5ce29c9d5d4f8b17f634",
          "problem_id": 19,
          "create_time": "16/10/2022 14:55:58.785895+08",
          "result": -1
        },
        {
          "id": "9e5fc69d97751b78ba64568b119735fd",
          "problem_id": 2,
          "create_time": "15/10/2022 23:01:33.949992+08",
          "result": 0
        },
        {
          "id": "1c0c3ad5499531a5929834a3caccb1f4",
          "problem_id": 21,
          "create_time": "18/10/2022 18:37:40.731599+08",
          "result": -1
        },
        {
          "id": "f867b7a9e65dced2ea6ab68dddb59ad6",
          "problem_id": 2,
          "create_time": "16/10/2022 04:12:41.015643+08",
          "result": 1
        },
        {
          "id": "830c5b5f7d92eeaaefded4dbd244c602",
          "problem_id": 1,
          "create_time": "16/10/2022 08:40:28.250594+08",
          "result": -1
        },
        {
          "id": "424f68a49d8adfa602835b362c583384",
          "problem_id": 2,
          "create_time": "15/10/2022 23:34:29.209351+08",
          "result": -2
        },
        {
          "id": "dac5d65040ccfc1eeeea88a723c9a13a",
          "problem_id": 19,
          "create_time": "16/10/2022 14:56:07.520951+08",
          "result": -1
        },
        {
          "id": "3d95e56d3494e8338a86776460ca2a2a",
          "problem_id": 20,
          "create_time": "18/10/2022 10:30:32.898148+08",
          "result": 4
        },
        {
          "id": "86fa8c79c0daa2be2de82026c2702dc6",
          "problem_id": 2,
          "create_time": "16/10/2022 10:47:02.309689+08",
          "result": -1
        },
        {
          "id": "4d7459bfae184ee1ed932597c669a973",
          "problem_id": 2,
          "create_time": "16/10/2022 04:13:11.563422+08",
          "result": 0
        },
        {
          "id": "6c8cf6316c0fa90ccf61ba6d79737ae4",
          "problem_id": 2,
          "create_time": "18/10/2022 09:51:08.933955+08",
          "result": -1
        },
        {
          "id": "75dd7663a35a1094da91a5bb01cacaaf",
          "problem_id": 2,
          "create_time": "18/10/2022 10:01:13.000807+08",
          "result": -1
        },
        {
          "id": "d1cf4e6f91d5888f66f2c6ee015a43ad",
          "problem_id": 2,
          "create_time": "17/10/2022 14:09:31.700276+08",
          "result": 4
        },
        {
          "id": "c02d8322440f39ee1e4c6518f412999a",
          "problem_id": 20,
          "create_time": "18/10/2022 10:11:54.026802+08",
          "result": -2
        },
        {
          "id": "fed2bf0b72fcd5b71f89f35b19fbbed0",
          "problem_id": 2,
          "create_time": "16/10/2022 04:13:28.916739+08",
          "result": 3
        },
        {
          "id": "6c76f3f26fc0c24bc15ece97259e9990",
          "problem_id": 1,
          "create_time": "16/10/2022 00:29:04.814127+08",
          "result": 0
        },
        {
          "id": "d5262de6a9cc41aa87494e591c2e86b8",
          "problem_id": 2,
          "create_time": "17/10/2022 17:17:17.661993+08",
          "result": -2
        },
        {
          "id": "4bed4d2bc27583deaf6610e5e6a38d6e",
          "problem_id": 2,
          "create_time": "16/10/2022 10:56:17.774922+08",
          "result": 0
        },
        {
          "id": "78e63b92386f17dce9180dc1163c1eea",
          "problem_id": 20,
          "create_time": "18/10/2022 10:30:54.790843+08",
          "result": 4
        },
        {
          "id": "f177cb9f9ce72ec75b1a655549cc83a8",
          "problem_id": 2,
          "create_time": "15/10/2022 23:06:02.611719+08",
          "result": 0
        },
        {
          "id": "95a968b7fbfa457c31db3383f482c8e9",
          "problem_id": 1,
          "create_time": "16/10/2022 08:27:32.060756+08",
          "result": -1
        },
        {
          "id": "a33f8708e00fdff0013dc92029f8813a",
          "problem_id": 2,
          "create_time": "16/10/2022 13:20:18.928917+08",
          "result": 0
        },
        {
          "id": "e148da53a362489148611913f4a9ee77",
          "problem_id": 1,
          "create_time": "16/10/2022 12:52:28.094476+08",
          "result": 0
        },
        {
          "id": "439d381ca83acee0545db897eb6760ba",
          "problem_id": 2,
          "create_time": "16/10/2022 10:34:10.153941+08",
          "result": -1
        },
        {
          "id": "43008381ad4343a4bc86867303768434",
          "problem_id": 2,
          "create_time": "16/10/2022 08:31:20.59376+08",
          "result": -2
        },
        {
          "id": "527125d260c084d093d7c945ef255723",
          "problem_id": 20,
          "create_time": "18/10/2022 10:13:52.972684+08",
          "result": 4
        },
        {
          "id": "da8f5a1b052c555debba20743865b908",
          "problem_id": 2,
          "create_time": "16/10/2022 10:48:37.014152+08",
          "result": 0
        },
        {
          "id": "37cfb370ada784f3ea12d5bdc33ea690",
          "problem_id": 2,
          "create_time": "18/10/2022 10:49:18.951018+08",
          "result": 4
        },
        {
          "id": "14ee2e5f26aec00a98ff700c616ed119",
          "problem_id": 1,
          "create_time": "16/10/2022 16:15:29.707479+08",
          "result": 3
        },
        {
          "id": "dbb7053634bc8581cdd4a880620cf763",
          "problem_id": 2,
          "create_time": "16/10/2022 04:05:25.378997+08",
          "result": 0
        },
        {
          "id": "cd252cff34bc0e9cae845accd51c7347",
          "problem_id": 21,
          "create_time": "18/10/2022 18:38:56.870412+08",
          "result": -2
        },
        {
          "id": "c23c1e6ef58f6c4cc238ad0aa9d1103a",
          "problem_id": 2,
          "create_time": "16/10/2022 08:31:52.545771+08",
          "result": 1
        },
        {
          "id": "19bd077083d128f3ac5c6f8cc5a6fafd",
          "problem_id": 2,
          "create_time": "16/10/2022 12:54:54.480539+08",
          "result": -2
        },
        {
          "id": "e60dd2e95b773487173a557af5c97f65",
          "problem_id": 2,
          "create_time": "18/10/2022 10:11:19.057155+08",
          "result": -2
        },
        {
          "id": "514c587af164c063402afe268a2e5b80",
          "problem_id": 20,
          "create_time": "18/10/2022 10:12:11.910299+08",
          "result": 4
        },
        {
          "id": "dff0dc969639985b3ed5bbfdca708745",
          "problem_id": 2,
          "create_time": "15/10/2022 23:34:57.660993+08",
          "result": -1
        },
        {
          "id": "705c7e5e590f1b500d465dd8d78aa298",
          "problem_id": 2,
          "create_time": "18/10/2022 14:04:08.202719+08",
          "result": -1
        },
        {
          "id": "ecc0c326b83195b6a5e14caa8bc4bdb0",
          "problem_id": 1,
          "create_time": "16/10/2022 08:37:13.12991+08",
          "result": -1
        },
        {
          "id": "e891aee57c50497fb2db32f757d7bd9c",
          "problem_id": 2,
          "create_time": "16/10/2022 11:04:22.69631+08",
          "result": 0
        },
        {
          "id": "c7dff4327fdc5e65705e1f76618794e4",
          "problem_id": 1,
          "create_time": "16/10/2022 11:16:24.727786+08",
          "result": -1
        },
        {
          "id": "4dfa4ddd6d1bd525cc7b03905450cda4",
          "problem_id": 2,
          "create_time": "16/10/2022 12:55:02.104252+08",
          "result": -1
        },
        {
          "id": "d268b5b7c080857feee5d0e4dd334e0a",
          "problem_id": 1,
          "create_time": "16/10/2022 11:18:59.718373+08",
          "result": -1
        },
        {
          "id": "bf28714ceb34172062d846d0e1eeaac4",
          "problem_id": 1,
          "create_time": "16/10/2022 17:16:57.742049+08",
          "result": -1
        },
        {
          "id": "552c5fbffd42dbd9cd85fa0468661204",
          "problem_id": 2,
          "create_time": "17/10/2022 21:32:07.673102+08",
          "result": 4
        },
        {
          "id": "8aed036f01a3d9bd4092307a95d4d7cd",
          "problem_id": 2,
          "create_time": "17/10/2022 17:17:22.51448+08",
          "result": -2
        },
        {
          "id": "7a07d71638ecb5238e876bc6342af91d",
          "problem_id": 1,
          "create_time": "16/10/2022 11:56:09.282164+08",
          "result": -1
        },
        {
          "id": "ccfca49180ae44f8c53f43e5f1a2a15e",
          "problem_id": 19,
          "create_time": "18/10/2022 18:44:24.788323+08",
          "result": -2
        },
        {
          "id": "246a44fdd0ffa6fdeffd1bbcb643d14b",
          "problem_id": 2,
          "create_time": "18/10/2022 10:01:45.07775+08",
          "result": -1
        },
        {
          "id": "1d24c920e4220b4acd5af6b6a432f34c",
          "problem_id": 2,
          "create_time": "16/10/2022 10:51:28.327814+08",
          "result": -1
        },
        {
          "id": "63aefa5f27269807df633e21f1cc68af",
          "problem_id": 1,
          "create_time": "16/10/2022 13:42:02.403859+08",
          "result": -1
        },
        {
          "id": "45c5bab5ad77ec7353d26ae69fe0e7c3",
          "problem_id": 1,
          "create_time": "16/10/2022 11:56:39.57087+08",
          "result": -1
        },
        {
          "id": "66f51806d0a20cabe06ce2898209b648",
          "problem_id": 2,
          "create_time": "16/10/2022 13:13:50.545487+08",
          "result": -2
        },
        {
          "id": "b65e0b2047c442412ca0c360d0a72c17",
          "problem_id": 1,
          "create_time": "16/10/2022 11:57:22.851568+08",
          "result": 3
        },
        {
          "id": "8b66d4d08e83ecc5eda71f2ed23c5174",
          "problem_id": 2,
          "create_time": "16/10/2022 13:29:21.168688+08",
          "result": 0
        },
        {
          "id": "5e879e834aa00adefc0e9cc56bd11159",
          "problem_id": 2,
          "create_time": "17/10/2022 17:02:34.466486+08",
          "result": 4
        },
        {
          "id": "e14b64e4f4aa924e28ac8b3f7fea1bc3",
          "problem_id": 1,
          "create_time": "16/10/2022 12:45:56.288912+08",
          "result": -2
        },
        {
          "id": "343e425e18fb948d1a1f130ce4bbace4",
          "problem_id": 2,
          "create_time": "16/10/2022 13:14:42.37645+08",
          "result": -2
        },
        {
          "id": "945ca5674ece87c905ad051034aeb0d7",
          "problem_id": 1,
          "create_time": "16/10/2022 12:46:07.026729+08",
          "result": -2
        },
        {
          "id": "3ae56f64e07523c9324325d45a88c6cd",
          "problem_id": 1,
          "create_time": "16/10/2022 13:35:26.709457+08",
          "result": -2
        },
        {
          "id": "f03b1e2985a72ca53ef26c7f9aff17ed",
          "problem_id": 2,
          "create_time": "18/10/2022 10:04:13.931162+08",
          "result": -1
        },
        {
          "id": "faa28e30b6e26d71627538c02efca173",
          "problem_id": 2,
          "create_time": "18/10/2022 22:05:32.995215+08",
          "result": 1
        },
        {
          "id": "8ece7f4316902d4e8c26257f646857c7",
          "problem_id": 1,
          "create_time": "16/10/2022 12:46:50.304002+08",
          "result": -1
        },
        {
          "id": "379109889d132160bd863c0b92bcadca",
          "problem_id": 2,
          "create_time": "16/10/2022 13:16:46.271221+08",
          "result": -2
        },
        {
          "id": "3ab3accbda962bbe142ec91e7fce80c5",
          "problem_id": 1,
          "create_time": "17/10/2022 19:38:25.327161+08",
          "result": -1
        },
        {
          "id": "42d351f83c32e1f8445b92ebf5749bd4",
          "problem_id": 1,
          "create_time": "16/10/2022 13:41:31.649469+08",
          "result": -2
        },
        {
          "id": "dee720e3a67114c670c7ac1e9707915a",
          "problem_id": 1,
          "create_time": "16/10/2022 17:23:28.736836+08",
          "result": -1
        },
        {
          "id": "e03fb8db59d08648800ac0ed48d1b8aa",
          "problem_id": 1,
          "create_time": "16/10/2022 13:43:06.565155+08",
          "result": -2
        },
        {
          "id": "12159a79b2545eb39314b427efbe7094",
          "problem_id": 1,
          "create_time": "16/10/2022 13:43:23.961443+08",
          "result": -1
        },
        {
          "id": "f44f5464848359399a065af551b24316",
          "problem_id": 20,
          "create_time": "18/10/2022 03:46:05.869023+08",
          "result": -2
        },
        {
          "id": "c3903e4ea2fb5e32112cd5b03affd2f4",
          "problem_id": 19,
          "create_time": "18/10/2022 18:44:45.406364+08",
          "result": -1
        },
        {
          "id": "52d397e671e7398044d2002537c3555b",
          "problem_id": 1,
          "create_time": "16/10/2022 14:18:05.478978+08",
          "result": -1
        },
        {
          "id": "3e17f260813e580bb48b8422cf474ed0",
          "problem_id": 20,
          "create_time": "18/10/2022 12:37:10.535051+08",
          "result": 4
        },
        {
          "id": "f97e811a20ad62579d51a478dbf60887",
          "problem_id": 1,
          "create_time": "16/10/2022 17:24:03.516818+08",
          "result": -1
        },
        {
          "id": "8c79e9eca880a76adf4c2cef8f946b40",
          "problem_id": 2,
          "create_time": "16/10/2022 21:16:34.628015+08",
          "result": -1
        },
        {
          "id": "fde26b2b9a87b898e1751fa01137f909",
          "problem_id": 2,
          "create_time": "16/10/2022 09:45:53.322348+08",
          "result": -1
        },
        {
          "id": "4ccf5f7924b83d687a7ff439805e11a2",
          "problem_id": 1,
          "create_time": "16/10/2022 14:19:08.253212+08",
          "result": 0
        },
        {
          "id": "65cb56b2fd859e76a44c82d9837bdf1e",
          "problem_id": 2,
          "create_time": "16/10/2022 21:05:43.61533+08",
          "result": -1
        },
        {
          "id": "71bec92e6ada5f99fed4d32a4e664e65",
          "problem_id": 2,
          "create_time": "16/10/2022 13:07:25.325131+08",
          "result": -1
        },
        {
          "id": "3e40f85f950b25ef2dda5c8b3359281b",
          "problem_id": 19,
          "create_time": "16/10/2022 14:33:31.914252+08",
          "result": 0
        },
        {
          "id": "73a06969c1d445f61d6d8242486778ff",
          "problem_id": 1,
          "create_time": "16/10/2022 17:27:16.128848+08",
          "result": -1
        },
        {
          "id": "ccf8dc667983ede8cbfdbd118c2cde21",
          "problem_id": 2,
          "create_time": "18/10/2022 10:11:34.465846+08",
          "result": 0
        },
        {
          "id": "5317829c564d34e64da6b319e01802ba",
          "problem_id": 1,
          "create_time": "16/10/2022 15:08:10.622232+08",
          "result": -1
        },
        {
          "id": "160e8f09785f1c2c62aebc51dcbd66fb",
          "problem_id": 2,
          "create_time": "16/10/2022 21:07:37.790755+08",
          "result": -1
        },
        {
          "id": "d63bce6cabd5eadaa58bdeae08efa53a",
          "problem_id": 2,
          "create_time": "16/10/2022 14:41:52.829021+08",
          "result": -1
        },
        {
          "id": "5ff9afd713cacf11e5da3cfa0b33e485",
          "problem_id": 1,
          "create_time": "16/10/2022 17:30:02.756323+08",
          "result": 0
        },
        {
          "id": "e84e8ac4575151e4cae1dc265de4d7f7",
          "problem_id": 1,
          "create_time": "16/10/2022 15:30:36.853451+08",
          "result": 0
        },
        {
          "id": "d898017b97eb27e6600813ae94801fe6",
          "problem_id": 2,
          "create_time": "16/10/2022 15:31:44.425249+08",
          "result": 1
        },
        {
          "id": "d8ae8323da3790a833bac8f5e4687604",
          "problem_id": 2,
          "create_time": "17/10/2022 17:03:02.059667+08",
          "result": 4
        },
        {
          "id": "8bdc4d6682846caac17ec9b1fa703701",
          "problem_id": 2,
          "create_time": "18/10/2022 09:17:32.816691+08",
          "result": 0
        },
        {
          "id": "7e3f22bb20200ede1934f866ab68c088",
          "problem_id": 19,
          "create_time": "16/10/2022 15:54:16.877502+08",
          "result": 0
        },
        {
          "id": "211bdc1f30f84f248044bccbd4750e9d",
          "problem_id": 1,
          "create_time": "16/10/2022 21:16:21.929932+08",
          "result": -1
        },
        {
          "id": "4176c8b53a1f7bd2801eed32d3d2243a",
          "problem_id": 2,
          "create_time": "16/10/2022 21:30:52.026924+08",
          "result": -1
        },
        {
          "id": "e439aa969241c74c91d962fbe38cdecc",
          "problem_id": 1,
          "create_time": "17/10/2022 15:54:31.01056+08",
          "result": -2
        },
        {
          "id": "caf16fbc1bca2ce03d2eda5eab047d28",
          "problem_id": 19,
          "create_time": "16/10/2022 21:38:56.846718+08",
          "result": -1
        },
        {
          "id": "71fe06cd9ba01a580c9eb742bb6a9580",
          "problem_id": 1,
          "create_time": "17/10/2022 15:57:34.035892+08",
          "result": -2
        },
        {
          "id": "16bb51124d26768b314be482a3c1289d",
          "problem_id": 2,
          "create_time": "18/10/2022 09:21:09.611013+08",
          "result": 0
        },
        {
          "id": "12821a7e2bcd57576387c02bfbf07f7d",
          "problem_id": 2,
          "create_time": "17/10/2022 17:05:07.175792+08",
          "result": 1
        },
        {
          "id": "6cb2352edd4e7151c528c1603342874e",
          "problem_id": 1,
          "create_time": "16/10/2022 21:18:11.049517+08",
          "result": 0
        },
        {
          "id": "59b3a34486ea04c42e839a2aaec738ca",
          "problem_id": 2,
          "create_time": "17/10/2022 14:38:38.964748+08",
          "result": -1
        },
        {
          "id": "1bbc339396399e1048e0c5529540e05f",
          "problem_id": 1,
          "create_time": "17/10/2022 16:32:58.326783+08",
          "result": 0
        },
        {
          "id": "e16bbab8cdc8c5276cb27fdb2da17cd3",
          "problem_id": 2,
          "create_time": "16/10/2022 21:26:01.723971+08",
          "result": -2
        },
        {
          "id": "de503eaa1d2c1a9b24e95c1fe64ce014",
          "problem_id": 20,
          "create_time": "18/10/2022 03:38:42.029952+08",
          "result": -1
        },
        {
          "id": "a31e88887ee654d03bf5c92e61e5caf2",
          "problem_id": 1,
          "create_time": "18/10/2022 12:37:58.742118+08",
          "result": 0
        },
        {
          "id": "5375ab38013f4f8942d86e72c9377ad9",
          "problem_id": 2,
          "create_time": "18/10/2022 14:04:12.0105+08",
          "result": -1
        },
        {
          "id": "eb40297bf2ca2ac3435c16a446e6b1db",
          "problem_id": 19,
          "create_time": "16/10/2022 21:37:18.840209+08",
          "result": -2
        },
        {
          "id": "13bed83d1735d89097a8711629050308",
          "problem_id": 2,
          "create_time": "17/10/2022 19:12:12.338768+08",
          "result": -1
        },
        {
          "id": "9af6914faef6cce08f0bb1efe914362b",
          "problem_id": 2,
          "create_time": "16/10/2022 14:37:49.800586+08",
          "result": -1
        },
        {
          "id": "bacc17654f945ed91ebade017dd29cd5",
          "problem_id": 2,
          "create_time": "17/10/2022 21:33:32.585404+08",
          "result": 4
        },
        {
          "id": "349dbbff1570ccbdaf5d2d4f671d9983",
          "problem_id": 19,
          "create_time": "16/10/2022 21:33:20.25574+08",
          "result": -1
        },
        {
          "id": "a33d02cc03367f03120966d41d82af93",
          "problem_id": 2,
          "create_time": "17/10/2022 16:36:51.874626+08",
          "result": 4
        },
        {
          "id": "637ce0732b77de16fcef91330af85db5",
          "problem_id": 19,
          "create_time": "18/10/2022 18:45:36.0532+08",
          "result": 0
        },
        {
          "id": "f75f10f812db8e2ac882122819c45da0",
          "problem_id": 2,
          "create_time": "17/10/2022 17:04:46.217119+08",
          "result": 1
        },
        {
          "id": "d2df976026dfa6c026e9bc8060d05fd0",
          "problem_id": 1,
          "create_time": "17/10/2022 15:58:24.733933+08",
          "result": 0
        },
        {
          "id": "c50a257ced461faf1b5d9db21f4ecd54",
          "problem_id": 2,
          "create_time": "17/10/2022 19:12:16.61722+08",
          "result": -1
        },
        {
          "id": "826b006174f31a458a7a36a35029c878",
          "problem_id": 19,
          "create_time": "16/10/2022 21:33:39.660862+08",
          "result": -1
        },
        {
          "id": "5f096708999f943b69846803a6c5cd4e",
          "problem_id": 20,
          "create_time": "18/10/2022 10:33:23.304064+08",
          "result": 4
        },
        {
          "id": "26e16dae934aabdac5a05e923ccc87f0",
          "problem_id": 1,
          "create_time": "17/10/2022 16:33:17.69424+08",
          "result": 0
        },
        {
          "id": "ac67a7bca53f6528297f4883aaf89374",
          "problem_id": 2,
          "create_time": "16/10/2022 21:42:20.89123+08",
          "result": 0
        },
        {
          "id": "9a4ccde6d3c3543e38b1607e17e33e52",
          "problem_id": 2,
          "create_time": "17/10/2022 17:06:32.522554+08",
          "result": 1
        },
        {
          "id": "9a2db98d3f01cc10180f7c4ba5ba7e2c",
          "problem_id": 19,
          "create_time": "16/10/2022 21:33:48.374894+08",
          "result": 0
        },
        {
          "id": "84a120f8d2ca8d76b83c430479dbb2d4",
          "problem_id": 2,
          "create_time": "17/10/2022 18:43:52.760341+08",
          "result": -1
        },
        {
          "id": "15a9814c3b0d4c348b01b9f8f05472f7",
          "problem_id": 2,
          "create_time": "16/10/2022 10:44:11.166899+08",
          "result": -1
        },
        {
          "id": "9dc30409a0b773fe2e03e9d42b316600",
          "problem_id": 2,
          "create_time": "17/10/2022 16:40:54.416791+08",
          "result": 4
        },
        {
          "id": "2afb62f52b3432427557fd377e3b6a33",
          "problem_id": 2,
          "create_time": "16/10/2022 21:54:45.361676+08",
          "result": -1
        },
        {
          "id": "54a8a64a3841b4f12008be5f016cc99b",
          "problem_id": 2,
          "create_time": "17/10/2022 21:41:11.807892+08",
          "result": 4
        },
        {
          "id": "3002563b1df362b7088cf3ab0745acc5",
          "problem_id": 2,
          "create_time": "16/10/2022 22:09:37.193207+08",
          "result": 0
        },
        {
          "id": "251a05dc415089540cdac374bbb16c99",
          "problem_id": 2,
          "create_time": "17/10/2022 21:33:16.364951+08",
          "result": 4
        },
        {
          "id": "ec75da641ba021ec5014c40f0cf911aa",
          "problem_id": 2,
          "create_time": "17/10/2022 15:46:22.489906+08",
          "result": 4
        },
        {
          "id": "8f1ab3eae0069b16d409e22d39f4e57c",
          "problem_id": 1,
          "create_time": "17/10/2022 15:45:55.540266+08",
          "result": -1
        },
        {
          "id": "e821a45c4db869901b59f5c3666ae7f9",
          "problem_id": 2,
          "create_time": "18/10/2022 10:04:27.97764+08",
          "result": -1
        },
        {
          "id": "7fd4b37d962c4211e8a01ec96b4e7d0f",
          "problem_id": 2,
          "create_time": "17/10/2022 16:41:04.756041+08",
          "result": 4
        },
        {
          "id": "a334616e543c7ff72034fe8f9ce3bd1c",
          "problem_id": 2,
          "create_time": "17/10/2022 17:08:43.258819+08",
          "result": 4
        },
        {
          "id": "8c2a9061e7ac092e87abe2f1f66d2ecb",
          "problem_id": 2,
          "create_time": "17/10/2022 17:12:32.471662+08",
          "result": 1
        },
        {
          "id": "4a6196c8746c230d547ea5c80277afd8",
          "problem_id": 2,
          "create_time": "17/10/2022 17:09:44.235367+08",
          "result": 1
        },
        {
          "id": "a81a53e94e3530ad1aa9bc72f72b931a",
          "problem_id": 20,
          "create_time": "18/10/2022 12:39:01.543821+08",
          "result": -1
        },
        {
          "id": "551197b32304071f3b9b3aef2bffdc2b",
          "problem_id": 20,
          "create_time": "18/10/2022 12:36:36.367534+08",
          "result": -1
        },
        {
          "id": "5342764fd4315cc78f758931f90201e1",
          "problem_id": 1,
          "create_time": "17/10/2022 19:05:59.698535+08",
          "result": -1
        },
        {
          "id": "b65a7ff9652a66b32e4b8e94b079aa88",
          "problem_id": 1,
          "create_time": "17/10/2022 19:18:20.56377+08",
          "result": -2
        },
        {
          "id": "e90fe6f0e07181b5644e7d06ed3ca53c",
          "problem_id": 1,
          "create_time": "17/10/2022 19:24:13.710887+08",
          "result": -1
        },
        {
          "id": "70f3f0f22e51ccb898b809a60727648a",
          "problem_id": 1,
          "create_time": "17/10/2022 19:33:14.905311+08",
          "result": -1
        },
        {
          "id": "cfad705c556af6eff5167d79e501bdae",
          "problem_id": 2,
          "create_time": "17/10/2022 17:15:01.105427+08",
          "result": 1
        },
        {
          "id": "c9dc8700a9e1623f114040c08cb95492",
          "problem_id": 21,
          "create_time": "17/10/2022 23:26:26.716377+08",
          "result": 0
        },
        {
          "id": "4bb76edb3c9d3ef500d0398ddb1dd99d",
          "problem_id": 1,
          "create_time": "17/10/2022 19:33:55.384798+08",
          "result": -1
        },
        {
          "id": "bab9ebe58e10d18cd7fe89e9d33b04d8",
          "problem_id": 2,
          "create_time": "17/10/2022 23:36:06.402637+08",
          "result": -2
        },
        {
          "id": "b4ab9c3321a303f0bb5a05fda9428d8b",
          "problem_id": 2,
          "create_time": "17/10/2022 21:33:24.361529+08",
          "result": 4
        },
        {
          "id": "6e880d99ce52a169bfedd0d41a8db8db",
          "problem_id": 2,
          "create_time": "17/10/2022 17:16:21.010525+08",
          "result": 1
        },
        {
          "id": "202647ce610584d69a1da3a38dd06a95",
          "problem_id": 2,
          "create_time": "17/10/2022 21:41:21.239119+08",
          "result": -1
        },
        {
          "id": "bb7c687d2030df665bcf01bcb6c5dbcc",
          "problem_id": 1,
          "create_time": "17/10/2022 19:34:19.88926+08",
          "result": -1
        },
        {
          "id": "1148b70b91b312f5d82763619334a3bb",
          "problem_id": 2,
          "create_time": "16/10/2022 21:27:44.843312+08",
          "result": -1
        },
        {
          "id": "a8bd964c0a7439f45942af948f4e38d5",
          "problem_id": 2,
          "create_time": "17/10/2022 17:18:26.065552+08",
          "result": 1
        },
        {
          "id": "46fead1aa765e15f4721ecbe5cc29733",
          "problem_id": 2,
          "create_time": "17/10/2022 21:41:31.399014+08",
          "result": 4
        },
        {
          "id": "60ae175958cc090d944795abe6af1507",
          "problem_id": 2,
          "create_time": "17/10/2022 19:14:29.485795+08",
          "result": -1
        },
        {
          "id": "1389e7fe163a3741510eca972742e65c",
          "problem_id": 2,
          "create_time": "17/10/2022 18:52:40.996998+08",
          "result": -1
        },
        {
          "id": "e145d7938a4b80065e9d7564a17acd3c",
          "problem_id": 2,
          "create_time": "18/10/2022 14:04:38.780123+08",
          "result": 0
        },
        {
          "id": "8ade31885eee62ba50f35a153f169d59",
          "problem_id": 1,
          "create_time": "17/10/2022 19:38:22.111848+08",
          "result": -1
        },
        {
          "id": "505f629524e336f30fb238b6cd6a682b",
          "problem_id": 2,
          "create_time": "17/10/2022 17:21:36.862517+08",
          "result": 1
        },
        {
          "id": "68cea6a2db6b937800cff5544b8ff3ac",
          "problem_id": 2,
          "create_time": "17/10/2022 17:25:17.724704+08",
          "result": 1
        },
        {
          "id": "ca474cc0e88d70d9541f53ad3eba9137",
          "problem_id": 1,
          "create_time": "17/10/2022 17:27:45.298764+08",
          "result": -1
        },
        {
          "id": "b56b232f398f4d71ff1c46f3b4a58c3e",
          "problem_id": 2,
          "create_time": "16/10/2022 09:40:14.99181+08",
          "result": -1
        },
        {
          "id": "f300aefd7dd55f8d522a331c46ebafed",
          "problem_id": 2,
          "create_time": "17/10/2022 17:41:13.480012+08",
          "result": 1
        },
        {
          "id": "f25b9937178db3095f561ba6231f93a2",
          "problem_id": 2,
          "create_time": "17/10/2022 19:14:53.258277+08",
          "result": -1
        },
        {
          "id": "9282202d7f9a044f199ed48eb9ee12f1",
          "problem_id": 2,
          "create_time": "17/10/2022 18:50:56.938862+08",
          "result": -1
        },
        {
          "id": "a365c1d30e6c981c35f7bbcdec6844e8",
          "problem_id": 1,
          "create_time": "17/10/2022 17:45:27.646017+08",
          "result": -1
        },
        {
          "id": "41effb55f632d34407c4ee87dd52b2bb",
          "problem_id": 2,
          "create_time": "17/10/2022 22:02:46.101649+08",
          "result": -1
        },
        {
          "id": "abd66a87bca254efff1589fd059bbbe4",
          "problem_id": 2,
          "create_time": "18/10/2022 19:32:27.929861+08",
          "result": -1
        },
        {
          "id": "5f3d3e5d8280e44a71b65eb602fb9e8f",
          "problem_id": 1,
          "create_time": "17/10/2022 18:46:01.408708+08",
          "result": -1
        },
        {
          "id": "d0246bcf22fea5221fa7c26d9b2f5f4b",
          "problem_id": 2,
          "create_time": "16/10/2022 21:41:01.401045+08",
          "result": -1
        },
        {
          "id": "ead26e313eea71313c386e24ae862378",
          "problem_id": 2,
          "create_time": "18/10/2022 10:04:40.025675+08",
          "result": -1
        },
        {
          "id": "26d5c9e3106aba59cb2fa764a724ebe3",
          "problem_id": 1,
          "create_time": "17/10/2022 19:04:18.033872+08",
          "result": 4
        },
        {
          "id": "2182f312609eba2ddb892d6f27ba66a5",
          "problem_id": 2,
          "create_time": "17/10/2022 21:46:51.203812+08",
          "result": -2
        },
        {
          "id": "dedfaba9736098c180eaaf58bdc75209",
          "problem_id": 1,
          "create_time": "17/10/2022 19:17:07.419398+08",
          "result": -2
        },
        {
          "id": "51e988b58c06b6b45fc669cfac13bd7a",
          "problem_id": 1,
          "create_time": "17/10/2022 19:04:52.63265+08",
          "result": -1
        },
        {
          "id": "54d8a182664e56d198ed71fc91e8fc30",
          "problem_id": 1,
          "create_time": "17/10/2022 19:33:52.032084+08",
          "result": -1
        },
        {
          "id": "9b5d14d99bafa9fbacb5949b25f08481",
          "problem_id": 1,
          "create_time": "17/10/2022 19:39:18.206657+08",
          "result": -2
        },
        {
          "id": "b0debd816f5d378ef7560f8a8d722e9f",
          "problem_id": 20,
          "create_time": "18/10/2022 12:01:50.472606+08",
          "result": -1
        },
        {
          "id": "e08172c8e03c828ed030bad6242e4366",
          "problem_id": 1,
          "create_time": "17/10/2022 19:39:22.456966+08",
          "result": -2
        },
        {
          "id": "4d3dc644136ee493b4c1dd0cf33512ab",
          "problem_id": 1,
          "create_time": "17/10/2022 19:39:36.037571+08",
          "result": -1
        },
        {
          "id": "58b6b550fa763473c82b8c2f487c75ba",
          "problem_id": 1,
          "create_time": "17/10/2022 20:37:06.501288+08",
          "result": -1
        },
        {
          "id": "ec110ea1a7b210f3782b3cc0bd2af216",
          "problem_id": 2,
          "create_time": "17/10/2022 23:37:13.464087+08",
          "result": -2
        },
        {
          "id": "ab7971b734f3f49551ca2c89dc351fb0",
          "problem_id": 1,
          "create_time": "17/10/2022 20:38:15.723114+08",
          "result": -1
        },
        {
          "id": "21d9b6d630b53b7a3bd961b49e1b30c7",
          "problem_id": 2,
          "create_time": "18/10/2022 15:36:19.993578+08",
          "result": 0
        },
        {
          "id": "f034f4fc680d7245d784a65461331f6a",
          "problem_id": 2,
          "create_time": "17/10/2022 21:46:28.811247+08",
          "result": 4
        },
        {
          "id": "113cbc0985d43bbe345a4114a7e842f2",
          "problem_id": 2,
          "create_time": "17/10/2022 21:43:53.03069+08",
          "result": -1
        },
        {
          "id": "98d0b1e376bcebcf21f0f0ebb23e0ee2",
          "problem_id": 2,
          "create_time": "17/10/2022 21:30:21.152023+08",
          "result": -1
        },
        {
          "id": "21dbd1950520fbfbe9776de5c0523372",
          "problem_id": 2,
          "create_time": "17/10/2022 21:00:06.40321+08",
          "result": 4
        },
        {
          "id": "de6ce8a1c9573cedd179dcc89803d199",
          "problem_id": 1,
          "create_time": "17/10/2022 20:38:58.110073+08",
          "result": 0
        },
        {
          "id": "6f20fecad9add212345713d3e6910ef8",
          "problem_id": 2,
          "create_time": "17/10/2022 22:02:36.990302+08",
          "result": -1
        },
        {
          "id": "b3264b7a4086e1044696c5a5f4e00b88",
          "problem_id": 2,
          "create_time": "17/10/2022 17:41:38.224512+08",
          "result": 1
        },
        {
          "id": "b4df5e21e5e1edc5a826d542f303b700",
          "problem_id": 19,
          "create_time": "17/10/2022 20:40:06.562312+08",
          "result": -1
        },
        {
          "id": "c99078dbb3f46398dd03a42602d4e5b1",
          "problem_id": 2,
          "create_time": "17/10/2022 20:48:42.122374+08",
          "result": 1
        },
        {
          "id": "2e10fb403b71b64c497b924bff4ed730",
          "problem_id": 19,
          "create_time": "17/10/2022 20:41:00.037291+08",
          "result": -1
        },
        {
          "id": "92f6cc0469c52c9461382bfaa2fac2d1",
          "problem_id": 2,
          "create_time": "17/10/2022 21:45:21.000228+08",
          "result": -1
        },
        {
          "id": "83eaeacd48e6d955daf280717258d0b6",
          "problem_id": 20,
          "create_time": "18/10/2022 12:41:16.602525+08",
          "result": -1
        },
        {
          "id": "dd12b1f2ccb975e644db757b9db2a1c9",
          "problem_id": 19,
          "create_time": "17/10/2022 20:42:16.267124+08",
          "result": -1
        },
        {
          "id": "c7cd26395c9dbba8c693a95b901949a7",
          "problem_id": 19,
          "create_time": "17/10/2022 20:42:41.305008+08",
          "result": -1
        },
        {
          "id": "6798b2c5d907ba4df9892cb69216ff43",
          "problem_id": 19,
          "create_time": "17/10/2022 20:46:41.5029+08",
          "result": -1
        },
        {
          "id": "bfff5b4cefd4f648a2c8ae79b7cb5e39",
          "problem_id": 19,
          "create_time": "17/10/2022 20:50:32.500087+08",
          "result": 0
        },
        {
          "id": "d0f20f9c13dc182ed28ce4137d74a486",
          "problem_id": 2,
          "create_time": "17/10/2022 20:57:55.008611+08",
          "result": 4
        },
        {
          "id": "3153ae93938590f4d92a20e30deb3426",
          "problem_id": 2,
          "create_time": "17/10/2022 21:31:39.063387+08",
          "result": 4
        },
        {
          "id": "8ebcea7908ce000714d867ba9c9efb58",
          "problem_id": 2,
          "create_time": "17/10/2022 17:18:26.071282+08",
          "result": -1
        },
        {
          "id": "f71e0d4f409d1c281d93890348e889aa",
          "problem_id": 2,
          "create_time": "16/10/2022 14:38:31.951209+08",
          "result": -1
        },
        {
          "id": "77b9c29187cbad765503504041feb165",
          "problem_id": 2,
          "create_time": "17/10/2022 22:02:56.419606+08",
          "result": -2
        },
        {
          "id": "7323d76839a24d3f05aa064677b5d57a",
          "problem_id": 2,
          "create_time": "17/10/2022 21:44:03.969794+08",
          "result": -1
        },
        {
          "id": "c6fea99348691cbf67992304566cdd2e",
          "problem_id": 2,
          "create_time": "17/10/2022 20:59:11.307999+08",
          "result": 4
        },
        {
          "id": "630ce7258faa3e0cfe248bc9e7803a9e",
          "problem_id": 2,
          "create_time": "17/10/2022 21:38:02.243144+08",
          "result": -2
        },
        {
          "id": "8c617517dbce3b280bb051596f012d8c",
          "problem_id": 2,
          "create_time": "17/10/2022 17:17:29.663298+08",
          "result": -1
        },
        {
          "id": "97dd2a451af53ee12a92d9679049baa5",
          "problem_id": 2,
          "create_time": "17/10/2022 22:27:20.632899+08",
          "result": 4
        },
        {
          "id": "8e0fcc9e70cccf0657a7c2be326069b2",
          "problem_id": 2,
          "create_time": "17/10/2022 22:03:15.893619+08",
          "result": -1
        },
        {
          "id": "38f4d2e2c33e605d22565e021ffdd36a",
          "problem_id": 20,
          "create_time": "18/10/2022 11:32:28.639928+08",
          "result": -2
        },
        {
          "id": "d90217322afb8faabba06dea01973bfe",
          "problem_id": 2,
          "create_time": "17/10/2022 21:48:36.943957+08",
          "result": 4
        },
        {
          "id": "8b5791c008ff3d115ebb6cd676f5ab7a",
          "problem_id": 2,
          "create_time": "17/10/2022 21:44:15.191202+08",
          "result": 4
        },
        {
          "id": "f4726594e25e31f10f9fbf595b9b3f83",
          "problem_id": 2,
          "create_time": "17/10/2022 21:38:24.25466+08",
          "result": 4
        },
        {
          "id": "d2ffa2fe0214f39e0191d2bf9f47173d",
          "problem_id": 1,
          "create_time": "17/10/2022 17:12:08.845939+08",
          "result": -1
        },
        {
          "id": "3fb79b8bb96f2b334877155f98438493",
          "problem_id": 2,
          "create_time": "16/10/2022 21:12:33.14071+08",
          "result": -1
        },
        {
          "id": "ca6d4ce268a44229918378b5664da71e",
          "problem_id": 2,
          "create_time": "17/10/2022 21:49:29.130437+08",
          "result": 4
        },
        {
          "id": "911a37517cde8a8976733b04ce49bb9b",
          "problem_id": 2,
          "create_time": "17/10/2022 21:45:05.610757+08",
          "result": -1
        },
        {
          "id": "6c8c8c3162480ed9cbc987f5702d4df6",
          "problem_id": 2,
          "create_time": "17/10/2022 21:39:32.052122+08",
          "result": 4
        },
        {
          "id": "4eebccfcf3d3e10dbcb0c2086705e668",
          "problem_id": 2,
          "create_time": "17/10/2022 21:36:00.272681+08",
          "result": 1
        },
        {
          "id": "a4b6491bcc70c866b1f1533b9c6b085d",
          "problem_id": 2,
          "create_time": "17/10/2022 23:48:09.999917+08",
          "result": -1
        },
        {
          "id": "62ae476177bb7981f08207d4b6492889",
          "problem_id": 2,
          "create_time": "16/10/2022 09:11:24.977777+08",
          "result": -1
        },
        {
          "id": "f91ad0a231078d6e62d86132b70a08b8",
          "problem_id": 2,
          "create_time": "16/10/2022 14:41:59.754533+08",
          "result": -1
        },
        {
          "id": "7f1fc72d315f33898f2fa16fa3b806f1",
          "problem_id": 2,
          "create_time": "18/10/2022 09:48:56.807668+08",
          "result": 4
        },
        {
          "id": "f5038078a2b171c4bebaa9b730d9eb39",
          "problem_id": 2,
          "create_time": "17/10/2022 23:36:54.956199+08",
          "result": -1
        },
        {
          "id": "3850af84c69a21fb9a134a751c080c18",
          "problem_id": 2,
          "create_time": "17/10/2022 23:33:57.870078+08",
          "result": 4
        },
        {
          "id": "832777660079524e29b10698f6c89432",
          "problem_id": 2,
          "create_time": "17/10/2022 22:49:24.102606+08",
          "result": -1
        },
        {
          "id": "e1460b4e23b4d3c0f5c804bc17c35f1c",
          "problem_id": 2,
          "create_time": "16/10/2022 09:04:44.161143+08",
          "result": -1
        },
        {
          "id": "d4a8ee5d162904ab04516beeba18935f",
          "problem_id": 2,
          "create_time": "17/10/2022 16:23:14.956656+08",
          "result": -1
        },
        {
          "id": "b8818f7fd938b25b86db022358a35009",
          "problem_id": 2,
          "create_time": "18/10/2022 12:09:35.363984+08",
          "result": 4
        },
        {
          "id": "a34dbbb3576aea569dd5b1e6ee868416",
          "problem_id": 2,
          "create_time": "17/10/2022 14:09:47.362808+08",
          "result": -1
        },
        {
          "id": "6ccf864169ae04540befbbf809d2f4da",
          "problem_id": 2,
          "create_time": "17/10/2022 22:58:01.707434+08",
          "result": -1
        },
        {
          "id": "54bb44c82120a2084371a9c2c187f625",
          "problem_id": 2,
          "create_time": "17/10/2022 22:49:36.300544+08",
          "result": -1
        },
        {
          "id": "7e7cb4bba34d5ab581dc869c15c23371",
          "problem_id": 2,
          "create_time": "17/10/2022 21:42:52.781732+08",
          "result": -1
        },
        {
          "id": "94e45d6eb4d0df06edfb257a442d8c1b",
          "problem_id": 2,
          "create_time": "18/10/2022 22:05:46.592832+08",
          "result": 1
        },
        {
          "id": "cde9f4efa61d7517c9292cc0b89018b6",
          "problem_id": 2,
          "create_time": "17/10/2022 23:48:28.358491+08",
          "result": 1
        },
        {
          "id": "4b64995030def24b35ffd1eeb1c8ef0d",
          "problem_id": 2,
          "create_time": "17/10/2022 23:49:02.736528+08",
          "result": 1
        },
        {
          "id": "42137236e343a672d693330ea45e99fb",
          "problem_id": 2,
          "create_time": "17/10/2022 16:15:55.400678+08",
          "result": -1
        },
        {
          "id": "198a96192e751cb884a797c4b467595e",
          "problem_id": 2,
          "create_time": "17/10/2022 23:45:29.982649+08",
          "result": 4
        },
        {
          "id": "b40ff3905cffc97e6cd49e4ca6b93a61",
          "problem_id": 2,
          "create_time": "17/10/2022 22:29:17.832338+08",
          "result": -1
        },
        {
          "id": "8985dfc743914515955cb48603f8e039",
          "problem_id": 2,
          "create_time": "17/10/2022 21:41:48.350304+08",
          "result": 4
        },
        {
          "id": "8bb7b15be8091bbd71b68f0cf3ccfb8f",
          "problem_id": 2,
          "create_time": "17/10/2022 21:32:21.297926+08",
          "result": -1
        },
        {
          "id": "970a478f0628273cce76460bc0a832c2",
          "problem_id": 1,
          "create_time": "18/10/2022 10:41:38.780968+08",
          "result": 0
        },
        {
          "id": "234f7b568d52277113b24f4cf1f56659",
          "problem_id": 20,
          "create_time": "19/10/2022 18:19:02.900784+08",
          "result": -1
        },
        {
          "id": "b3a8586369bf33fbf8da28cb8c85f8c1",
          "problem_id": 2,
          "create_time": "17/10/2022 23:40:31.477966+08",
          "result": -1
        },
        {
          "id": "aac04e190cf88f7d8359a34def0f214e",
          "problem_id": 2,
          "create_time": "17/10/2022 23:36:40.175643+08",
          "result": -1
        },
        {
          "id": "142cd3d4b19ca75486e2a2271a7d3929",
          "problem_id": 2,
          "create_time": "17/10/2022 23:09:52.435167+08",
          "result": -1
        },
        {
          "id": "2df101816c19ff1ceb8d10a308af1eb4",
          "problem_id": 2,
          "create_time": "18/10/2022 00:21:49.084973+08",
          "result": -1
        },
        {
          "id": "a29ff750a532f4d4acdb03401a033c58",
          "problem_id": 2,
          "create_time": "17/10/2022 23:32:47.742018+08",
          "result": -1
        },
        {
          "id": "4f8e3d35b3d48b0d775fb33fa95f5d0b",
          "problem_id": 2,
          "create_time": "17/10/2022 23:43:43.242595+08",
          "result": 0
        },
        {
          "id": "890ba8ebf05591b32499a937b8c91a23",
          "problem_id": 1,
          "create_time": "18/10/2022 14:21:32.60401+08",
          "result": 4
        },
        {
          "id": "6dfec1907af1e7cd5fda694c4f4c31d8",
          "problem_id": 2,
          "create_time": "17/10/2022 23:28:35.203151+08",
          "result": 4
        },
        {
          "id": "4a0c9fdfcac755dd6ec248f0d9b02b1c",
          "problem_id": 2,
          "create_time": "17/10/2022 21:45:30.718947+08",
          "result": 4
        },
        {
          "id": "1fdb3c08d3c5bb1dacc536aba5a267f1",
          "problem_id": 2,
          "create_time": "17/10/2022 21:41:01.197659+08",
          "result": 4
        },
        {
          "id": "3430f38ff52969313edaade52ad82549",
          "problem_id": 2,
          "create_time": "17/10/2022 21:30:04.568442+08",
          "result": -1
        },
        {
          "id": "c7130076a60c15de4d56a1548558c4f6",
          "problem_id": 2,
          "create_time": "17/10/2022 21:35:39.708855+08",
          "result": 1
        },
        {
          "id": "2bd664a642953b8587ce0d6f71f5f871",
          "problem_id": 2,
          "create_time": "17/10/2022 23:37:31.438551+08",
          "result": 4
        },
        {
          "id": "d6daf1b0b534de66b7283da346315fd6",
          "problem_id": 20,
          "create_time": "18/10/2022 12:49:46.584773+08",
          "result": -1
        },
        {
          "id": "570cecac9f5a27b69f12dc54b58d52f4",
          "problem_id": 2,
          "create_time": "17/10/2022 23:41:47.575898+08",
          "result": -1
        },
        {
          "id": "5b88ae1c7286ebb272a8fb833cb0c28f",
          "problem_id": 2,
          "create_time": "17/10/2022 23:33:41.846376+08",
          "result": -1
        },
        {
          "id": "660302d67cd0f8ed93b05e9a806e3faa",
          "problem_id": 2,
          "create_time": "17/10/2022 23:28:32.535794+08",
          "result": -1
        },
        {
          "id": "b882ddb810132798e254ee101d085b9e",
          "problem_id": 2,
          "create_time": "18/10/2022 00:22:32.778657+08",
          "result": -1
        },
        {
          "id": "ae02f328724431f9f0effd3c0a474ebf",
          "problem_id": 2,
          "create_time": "17/10/2022 23:49:10.650853+08",
          "result": 0
        },
        {
          "id": "410c647de75662184e62f4554b3f17dd",
          "problem_id": 2,
          "create_time": "17/10/2022 23:39:18.295617+08",
          "result": -1
        },
        {
          "id": "e772fad232910ae559a0139caaccb3b3",
          "problem_id": 2,
          "create_time": "17/10/2022 23:42:08.523333+08",
          "result": -1
        },
        {
          "id": "6e74e756ffa841076c39114e3d5f6c91",
          "problem_id": 2,
          "create_time": "17/10/2022 23:31:13.170786+08",
          "result": 0
        },
        {
          "id": "facf32bf202be34a81e5f5f1b4d37501",
          "problem_id": 2,
          "create_time": "17/10/2022 23:49:46.589596+08",
          "result": -1
        },
        {
          "id": "ecd741d5d94b7a034b8f5eba99af3cb6",
          "problem_id": 2,
          "create_time": "17/10/2022 23:08:12.022485+08",
          "result": -1
        },
        {
          "id": "205fb19badc45e5feef94682b1017819",
          "problem_id": 2,
          "create_time": "17/10/2022 23:33:30.556958+08",
          "result": -1
        },
        {
          "id": "3e29b9ecd68686658f7867895c45d271",
          "problem_id": 2,
          "create_time": "17/10/2022 23:32:55.285645+08",
          "result": -1
        },
        {
          "id": "68f9265474a931a614f967f7b8c66e52",
          "problem_id": 2,
          "create_time": "18/10/2022 00:24:50.75309+08",
          "result": -1
        },
        {
          "id": "415db6d03d21a03c360cfa681f9427e2",
          "problem_id": 2,
          "create_time": "17/10/2022 23:57:06.249685+08",
          "result": -1
        },
        {
          "id": "fb1302ec24f974e8cc26c5dc02305a12",
          "problem_id": 2,
          "create_time": "17/10/2022 15:48:16.016741+08",
          "result": 4
        },
        {
          "id": "58419e8554d5e8a8c28c17cc127ae41a",
          "problem_id": 2,
          "create_time": "17/10/2022 23:37:42.581468+08",
          "result": -1
        },
        {
          "id": "13b4b05c99a2b4e6b886dbc3569dabfa",
          "problem_id": 2,
          "create_time": "17/10/2022 23:34:03.985836+08",
          "result": -1
        },
        {
          "id": "9f12ce47fe1dea8737b366f1b6b10dea",
          "problem_id": 2,
          "create_time": "17/10/2022 23:44:23.531518+08",
          "result": -2
        },
        {
          "id": "68d8da392f8335e76dba4d99e67c09b8",
          "problem_id": 2,
          "create_time": "18/10/2022 00:21:05.511681+08",
          "result": 0
        },
        {
          "id": "31ad153f618ec9e34da4d91142d91194",
          "problem_id": 2,
          "create_time": "18/10/2022 00:00:57.013859+08",
          "result": -1
        },
        {
          "id": "555b10f6663c3af99f7e474f88f5d484",
          "problem_id": 2,
          "create_time": "17/10/2022 23:34:21.889846+08",
          "result": -2
        },
        {
          "id": "37e9827d36b0b39aa28db7daaabb41e5",
          "problem_id": 2,
          "create_time": "17/10/2022 23:36:00.615549+08",
          "result": -2
        },
        {
          "id": "d8ac97e8204979407e87d6bf3c7f7b46",
          "problem_id": 2,
          "create_time": "17/10/2022 15:47:16.843149+08",
          "result": 4
        },
        {
          "id": "18cf6373f6f076613d1701b71ed2829c",
          "problem_id": 2,
          "create_time": "17/10/2022 23:44:44.540093+08",
          "result": -1
        },
        {
          "id": "d363bc268d887ddb9b41b73277b83539",
          "problem_id": 2,
          "create_time": "17/10/2022 23:36:12.731745+08",
          "result": -2
        },
        {
          "id": "6c882a406989f3055f5313d2515cf6bf",
          "problem_id": 2,
          "create_time": "18/10/2022 10:42:50.562323+08",
          "result": 4
        },
        {
          "id": "4c51e8dcbb790603c2cf0bbfbfe234f1",
          "problem_id": 2,
          "create_time": "18/10/2022 00:01:25.626914+08",
          "result": -1
        },
        {
          "id": "928011988a91ddf5ef85abd946d62897",
          "problem_id": 2,
          "create_time": "17/10/2022 23:48:47.015326+08",
          "result": 4
        },
        {
          "id": "d993e05891bf7091f52b38a787fa8b1b",
          "problem_id": 2,
          "create_time": "17/10/2022 23:24:10.860094+08",
          "result": -1
        },
        {
          "id": "5c223ddf231dbce5a37b3aedc6757bef",
          "problem_id": 2,
          "create_time": "18/10/2022 15:30:45.717055+08",
          "result": 0
        },
        {
          "id": "5f0e360b8727d9f9d30310fce1f00426",
          "problem_id": 2,
          "create_time": "17/10/2022 23:07:23.090332+08",
          "result": -1
        },
        {
          "id": "7a4b7dd72d748e275fb757213dc17bc5",
          "problem_id": 20,
          "create_time": "18/10/2022 03:47:49.789885+08",
          "result": -2
        },
        {
          "id": "f69925ce004a533ca5ba47e27767ac66",
          "problem_id": 2,
          "create_time": "17/10/2022 23:06:16.41388+08",
          "result": 0
        },
        {
          "id": "504e6b70134142ad4c505f5b563983c5",
          "problem_id": 2,
          "create_time": "17/10/2022 22:09:26.620237+08",
          "result": -1
        },
        {
          "id": "dd2df24179f26e2e29ce72ad84b37a01",
          "problem_id": 2,
          "create_time": "17/10/2022 22:33:27.170417+08",
          "result": -1
        },
        {
          "id": "4d76c741b3beb6c071879079d5af6a41",
          "problem_id": 2,
          "create_time": "17/10/2022 22:28:04.738776+08",
          "result": -1
        },
        {
          "id": "ba9ba89a5fed289a0e39e78bdd75848d",
          "problem_id": 20,
          "create_time": "18/10/2022 10:33:03.988525+08",
          "result": -1
        },
        {
          "id": "355070e110fe96511a21257cf12566a8",
          "problem_id": 2,
          "create_time": "15/10/2022 23:36:59.726807+08",
          "result": -1
        },
        {
          "id": "44bcfc0f7042b9020d425bc10cf478e8",
          "problem_id": 2,
          "create_time": "16/10/2022 18:50:37.162081+08",
          "result": -1
        },
        {
          "id": "c3776e981dac4beb5b7afecbbcfbc58c",
          "problem_id": 2,
          "create_time": "17/10/2022 22:12:38.754291+08",
          "result": -1
        },
        {
          "id": "1e94f5d752dc104059af72ab95b5c6a0",
          "problem_id": 2,
          "create_time": "17/10/2022 15:46:39.787823+08",
          "result": 4
        },
        {
          "id": "11b1e76e7f74d51dc5d2720945c4ca0e",
          "problem_id": 1,
          "create_time": "18/10/2022 10:08:35.071581+08",
          "result": -2
        },
        {
          "id": "5f1e2fcceffc22c39f1f660efabc8dbd",
          "problem_id": 2,
          "create_time": "16/10/2022 21:23:15.66709+08",
          "result": -1
        },
        {
          "id": "bff03008b449fe945498a908bb5d4972",
          "problem_id": 2,
          "create_time": "18/10/2022 01:49:34.573924+08",
          "result": 0
        },
        {
          "id": "bb23eb8bdb2139e157dbd49fc0219562",
          "problem_id": 2,
          "create_time": "18/10/2022 12:33:53.283139+08",
          "result": 4
        },
        {
          "id": "46d8022d0b77246600bcaceee858d71e",
          "problem_id": 2,
          "create_time": "17/10/2022 22:14:59.629355+08",
          "result": -1
        },
        {
          "id": "b7a0bcba72411f1af25076abb5e2b706",
          "problem_id": 2,
          "create_time": "17/10/2022 14:39:25.59184+08",
          "result": -1
        },
        {
          "id": "ba796151a43daefa07511a9f360fe472",
          "problem_id": 2,
          "create_time": "18/10/2022 01:53:36.661127+08",
          "result": -1
        },
        {
          "id": "3106b343c9abf80b3df77acfc3c4d703",
          "problem_id": 20,
          "create_time": "18/10/2022 03:29:51.538597+08",
          "result": -1
        },
        {
          "id": "41cec71e30506e34fb34f94deff2ce65",
          "problem_id": 2,
          "create_time": "18/10/2022 10:10:05.168082+08",
          "result": -2
        },
        {
          "id": "4a371d0731a02446dd9c57b1626f2cde",
          "problem_id": 20,
          "create_time": "18/10/2022 03:38:54.557119+08",
          "result": -1
        },
        {
          "id": "dbcf1caad4d15f440f5605ecf2cb12b7",
          "problem_id": 20,
          "create_time": "18/10/2022 03:46:19.420139+08",
          "result": -2
        },
        {
          "id": "d6e3a0ba871132b521d9bd60567d6798",
          "problem_id": 2,
          "create_time": "17/10/2022 22:30:44.080726+08",
          "result": 1
        },
        {
          "id": "ea1e60d109ea1bfe4c037833135b19c9",
          "problem_id": 20,
          "create_time": "18/10/2022 10:34:54.091636+08",
          "result": -1
        },
        {
          "id": "f4aba15990ac9820dd6cbcfeb9d48840",
          "problem_id": 2,
          "create_time": "17/10/2022 21:35:05.184032+08",
          "result": -1
        },
        {
          "id": "c71e819da0edfdf620040d21d41c3907",
          "problem_id": 2,
          "create_time": "17/10/2022 21:34:01.628802+08",
          "result": -1
        },
        {
          "id": "d61de96a176520c319cd1e9134573267",
          "problem_id": 2,
          "create_time": "17/10/2022 21:27:05.470106+08",
          "result": -1
        },
        {
          "id": "d8df4e98a42c1ee9a1d15974fea816ca",
          "problem_id": 2,
          "create_time": "18/10/2022 01:53:54.551447+08",
          "result": 0
        },
        {
          "id": "5a0cb5bd525f040e8550649b2ba89ecd",
          "problem_id": 2,
          "create_time": "17/10/2022 21:22:00.628224+08",
          "result": -1
        },
        {
          "id": "56ea6fbb4151be09b11c23ea23874b27",
          "problem_id": 2,
          "create_time": "17/10/2022 14:39:08.825841+08",
          "result": -1
        },
        {
          "id": "7cea15cd65e7f41a4e60e8d66d6e9632",
          "problem_id": 1,
          "create_time": "16/10/2022 21:17:07.468887+08",
          "result": -1
        },
        {
          "id": "989ea13f9a9596dd685ca7684b619c18",
          "problem_id": 2,
          "create_time": "16/10/2022 21:32:45.481881+08",
          "result": -1
        },
        {
          "id": "f13765dc89397c9f121fe87bd2fbdad5",
          "problem_id": 2,
          "create_time": "16/10/2022 20:45:35.276683+08",
          "result": -1
        },
        {
          "id": "6ba18c49f06ff3f81e67b88d3eeab8db",
          "problem_id": 2,
          "create_time": "18/10/2022 01:54:02.590076+08",
          "result": 0
        },
        {
          "id": "2d70029fe60d0df8d1ed80b4832a6669",
          "problem_id": 20,
          "create_time": "18/10/2022 03:31:45.23454+08",
          "result": -1
        },
        {
          "id": "ea8178893dc9b303fc4e68553d8247f6",
          "problem_id": 20,
          "create_time": "18/10/2022 03:39:00.636254+08",
          "result": -1
        },
        {
          "id": "13e3dcd8ec2210e620235b0d7888fbb9",
          "problem_id": 20,
          "create_time": "18/10/2022 03:46:35.876662+08",
          "result": -2
        },
        {
          "id": "6b858302ade0ed3132782257aebd267d",
          "problem_id": 20,
          "create_time": "18/10/2022 03:48:11.055242+08",
          "result": -1
        },
        {
          "id": "10f1953ee5f053ea9bd56dd984c49438",
          "problem_id": 2,
          "create_time": "18/10/2022 09:48:19.028077+08",
          "result": -2
        },
        {
          "id": "d813a446f4ab99e0a0958ff3861b31c9",
          "problem_id": 2,
          "create_time": "16/10/2022 13:25:25.64508+08",
          "result": -1
        },
        {
          "id": "eaea31880eeb4d988b95f159f6a74bbd",
          "problem_id": 2,
          "create_time": "16/10/2022 21:03:21.877771+08",
          "result": -1
        },
        {
          "id": "b8354df79b105ef98f6a3d925a09eec1",
          "problem_id": 2,
          "create_time": "18/10/2022 12:49:45.594111+08",
          "result": 1
        },
        {
          "id": "14b861823abafdd74fbe93ca59b5b9e0",
          "problem_id": 2,
          "create_time": "18/10/2022 01:54:13.293733+08",
          "result": 0
        },
        {
          "id": "a5f22e6ee8525fdc85d19fa8d4990674",
          "problem_id": 20,
          "create_time": "18/10/2022 03:47:38.904609+08",
          "result": -2
        },
        {
          "id": "9d44831ae30fef7b25df540f7ba3eaf7",
          "problem_id": 20,
          "create_time": "18/10/2022 03:56:52.322579+08",
          "result": 4
        },
        {
          "id": "e3205f4a83126a9880dd534aedbb53c5",
          "problem_id": 21,
          "create_time": "18/10/2022 08:42:08.867684+08",
          "result": 0
        },
        {
          "id": "56eb4895bbd618fdd7006853da0181ff",
          "problem_id": 1,
          "create_time": "16/10/2022 12:49:49.222895+08",
          "result": -1
        },
        {
          "id": "b74e23a9804939078b32ef907296116f",
          "problem_id": 2,
          "create_time": "18/10/2022 10:05:32.526118+08",
          "result": 0
        },
        {
          "id": "8013e4df71859987229c2e62263583d6",
          "problem_id": 2,
          "create_time": "16/10/2022 13:19:52.360862+08",
          "result": -1
        },
        {
          "id": "a697566466c18e144ed910c50ae8b352",
          "problem_id": 2,
          "create_time": "18/10/2022 01:54:23.842574+08",
          "result": 0
        },
        {
          "id": "e0742ceec3b144a41fad0792358e0d53",
          "problem_id": 20,
          "create_time": "18/10/2022 03:37:06.966443+08",
          "result": -1
        },
        {
          "id": "32676f2fa04b317537ac6c8f6cf5d2e7",
          "problem_id": 2,
          "create_time": "18/10/2022 09:48:37.80612+08",
          "result": 0
        },
        {
          "id": "2eeebcb914de16d7e183e9772c4d15d9",
          "problem_id": 2,
          "create_time": "18/10/2022 09:52:27.37016+08",
          "result": -1
        },
        {
          "id": "8f3566d18fa7184e814ea8661af9f6f4",
          "problem_id": 2,
          "create_time": "18/10/2022 10:01:57.486515+08",
          "result": -1
        },
        {
          "id": "c363758a64a4833cab157ef6bc0fc0ef",
          "problem_id": 2,
          "create_time": "18/10/2022 10:03:39.752163+08",
          "result": -1
        },
        {
          "id": "b5103ab377fc74471363c969da8c23e2",
          "problem_id": 2,
          "create_time": "18/10/2022 10:04:06.007535+08",
          "result": -1
        },
        {
          "id": "9ef90961724ac336a0e4271b5fbe0214",
          "problem_id": 20,
          "create_time": "18/10/2022 03:57:07.558044+08",
          "result": 0
        },
        {
          "id": "d5f8fd038443e036c69556c1ca7fe196",
          "problem_id": 20,
          "create_time": "18/10/2022 10:06:29.225793+08",
          "result": -1
        },
        {
          "id": "a1e5316559ee2b148e7d24c1d566b216",
          "problem_id": 20,
          "create_time": "18/10/2022 15:06:21.687876+08",
          "result": 0
        },
        {
          "id": "a7f3522ea311760f61552c5232da61e0",
          "problem_id": 2,
          "create_time": "18/10/2022 10:02:01.742171+08",
          "result": -1
        },
        {
          "id": "8bf4b775ffee562159e261c03c3dc3aa",
          "problem_id": 2,
          "create_time": "18/10/2022 10:03:06.972344+08",
          "result": -1
        },
        {
          "id": "d7c2873ea71d2ea68dabf5a83785f36f",
          "problem_id": 2,
          "create_time": "18/10/2022 12:36:03.912015+08",
          "result": -1
        },
        {
          "id": "b9b598b2ce780f1e42303a1595c6dfb9",
          "problem_id": 21,
          "create_time": "18/10/2022 16:09:59.304328+08",
          "result": -2
        },
        {
          "id": "3e88bd47141f0b39aff58e91e494ad31",
          "problem_id": 20,
          "create_time": "18/10/2022 10:36:45.098179+08",
          "result": -2
        },
        {
          "id": "2104b4d21ec5c3eb7ea7b353900c2bce",
          "problem_id": 2,
          "create_time": "18/10/2022 09:49:33.926256+08",
          "result": 4
        },
        {
          "id": "d23fba3030d058f759e48ffe03d5caa5",
          "problem_id": 20,
          "create_time": "18/10/2022 10:33:13.705889+08",
          "result": -1
        },
        {
          "id": "eab221124870213e07d257a83f5d0055",
          "problem_id": 20,
          "create_time": "18/10/2022 10:43:58.617284+08",
          "result": 4
        },
        {
          "id": "cea1607960482e66343981b555c49d92",
          "problem_id": 2,
          "create_time": "18/10/2022 10:07:26.474556+08",
          "result": -1
        },
        {
          "id": "b3805dfb4b144cb4c2bb30c5923c017c",
          "problem_id": 1,
          "create_time": "18/10/2022 10:06:54.750007+08",
          "result": -1
        },
        {
          "id": "528fa6a81e472a151d3e290c4dcd1033",
          "problem_id": 21,
          "create_time": "18/10/2022 12:51:03.291141+08",
          "result": 0
        },
        {
          "id": "ebbf8928a308d708b47d8bb2a85d412a",
          "problem_id": 20,
          "create_time": "18/10/2022 10:39:54.027557+08",
          "result": 4
        },
        {
          "id": "6b96046d0e92b5e37e6a8a9b43e21d9c",
          "problem_id": 2,
          "create_time": "18/10/2022 12:36:19.705169+08",
          "result": 0
        },
        {
          "id": "d86ac5fbbdd4a6cdf7d1f9c6fc0f74db",
          "problem_id": 2,
          "create_time": "18/10/2022 16:14:21.371493+08",
          "result": 4
        },
        {
          "id": "aa8479c9447f28097ff60f2238daa5a0",
          "problem_id": 20,
          "create_time": "18/10/2022 11:40:24.397246+08",
          "result": -1
        },
        {
          "id": "e941a30b13a04d7ea1a71d913fe83a0a",
          "problem_id": 1,
          "create_time": "18/10/2022 10:08:21.094835+08",
          "result": -2
        },
        {
          "id": "b67617dce5c4c549494f615f66e98a75",
          "problem_id": 2,
          "create_time": "18/10/2022 10:45:34.038748+08",
          "result": 4
        },
        {
          "id": "55fa50e3f34c86228714cf8241711a91",
          "problem_id": 20,
          "create_time": "18/10/2022 10:38:35.657421+08",
          "result": -1
        },
        {
          "id": "79ec9be3d23374223fcb7965dd10388b",
          "problem_id": 21,
          "create_time": "18/10/2022 18:46:00.036322+08",
          "result": -2
        },
        {
          "id": "22b20cbc69b6742633f6269a45afdb87",
          "problem_id": 2,
          "create_time": "18/10/2022 12:36:25.675532+08",
          "result": -1
        },
        {
          "id": "e18002cae656c13e1310477d6289aed8",
          "problem_id": 21,
          "create_time": "18/10/2022 12:51:59.890459+08",
          "result": 0
        },
        {
          "id": "d3230b5ff25282ae3f7463d8cbe18de7",
          "problem_id": 20,
          "create_time": "18/10/2022 10:10:37.230957+08",
          "result": -2
        },
        {
          "id": "fa9eaef32378114af8c484b8f9c941d7",
          "problem_id": 21,
          "create_time": "18/10/2022 12:54:40.475861+08",
          "result": -1
        },
        {
          "id": "8c0993cfad15dcb992e33f6d70ee74ab",
          "problem_id": 20,
          "create_time": "18/10/2022 10:33:34.410759+08",
          "result": -1
        },
        {
          "id": "afc7106515cb54522a2869788f812eb2",
          "problem_id": 20,
          "create_time": "18/10/2022 13:11:57.8284+08",
          "result": -1
        },
        {
          "id": "2d45169f12753bcacba70ecb573a1f63",
          "problem_id": 1,
          "create_time": "18/10/2022 20:50:36.19436+08",
          "result": -2
        },
        {
          "id": "e3904f5ce67d5656512042eb01d09462",
          "problem_id": 20,
          "create_time": "18/10/2022 10:43:44.079854+08",
          "result": -2
        },
        {
          "id": "ec0ee64c7a390eeb6a78a1240858af4e",
          "problem_id": 20,
          "create_time": "18/10/2022 12:06:03.077153+08",
          "result": -1
        },
        {
          "id": "68dd3a5508d87b5fdb8212d047764578",
          "problem_id": 20,
          "create_time": "18/10/2022 08:49:40.6118+08",
          "result": 4
        },
        {
          "id": "af6ad824ddb4c8c16ebb21426e9e7d9a",
          "problem_id": 20,
          "create_time": "18/10/2022 09:41:07.746974+08",
          "result": -1
        },
        {
          "id": "12df9ed5d597c329aa66db3cb33a8c58",
          "problem_id": 1,
          "create_time": "18/10/2022 10:50:04.351358+08",
          "result": -1
        },
        {
          "id": "674c110be0d066b21dda7816108f935f",
          "problem_id": 1,
          "create_time": "18/10/2022 10:12:25.252141+08",
          "result": -2
        },
        {
          "id": "98b8892e0210fc5bfcdb9037632a24f2",
          "problem_id": 20,
          "create_time": "18/10/2022 11:40:48.15209+08",
          "result": -1
        },
        {
          "id": "833b797d8271cf2c16dc5d5bc4e938d4",
          "problem_id": 20,
          "create_time": "18/10/2022 10:35:02.794359+08",
          "result": 4
        },
        {
          "id": "8c3e4bd11154b6fc93209179414e1b39",
          "problem_id": 1,
          "create_time": "18/10/2022 14:07:42.199437+08",
          "result": -1
        },
        {
          "id": "a17e86d46947490346ad8ebd75e60f54",
          "problem_id": 1,
          "create_time": "18/10/2022 10:40:11.78202+08",
          "result": -1
        },
        {
          "id": "1935900436c67b87eb40a7e3ed3c8a32",
          "problem_id": 2,
          "create_time": "18/10/2022 20:51:31.940002+08",
          "result": -2
        },
        {
          "id": "8187fe524cb769d8516f7d1558813208",
          "problem_id": 2,
          "create_time": "18/10/2022 10:28:00.310993+08",
          "result": -1
        },
        {
          "id": "30c03a6a2cbb8f20246361e05ecada45",
          "problem_id": 2,
          "create_time": "18/10/2022 11:50:17.534536+08",
          "result": -1
        },
        {
          "id": "f30e728aeb5cc400a0fb43abab9a8139",
          "problem_id": 20,
          "create_time": "18/10/2022 12:53:31.395197+08",
          "result": -2
        },
        {
          "id": "633e88c0911b6c98f7798cd09a00f16b",
          "problem_id": 1,
          "create_time": "18/10/2022 10:28:15.629976+08",
          "result": -1
        },
        {
          "id": "fd08564cadec6471e1222099466dd4a4",
          "problem_id": 2,
          "create_time": "18/10/2022 14:08:42.570629+08",
          "result": 4
        },
        {
          "id": "34aa0de43a0a3e4855ef9211ecc39151",
          "problem_id": 20,
          "create_time": "18/10/2022 14:28:39.497819+08",
          "result": -1
        },
        {
          "id": "bb234f838529cdaa557d3214ddd57c02",
          "problem_id": 20,
          "create_time": "18/10/2022 10:30:25.889209+08",
          "result": 4
        },
        {
          "id": "a3274eef640c7df2864089f12373d2f8",
          "problem_id": 20,
          "create_time": "18/10/2022 10:48:28.46864+08",
          "result": -1
        },
        {
          "id": "7c5b20eef21088d550ca0a4021c9e618",
          "problem_id": 21,
          "create_time": "18/10/2022 12:54:03.002767+08",
          "result": -1
        },
        {
          "id": "28b227ca11c3b9c685095630ea15ed92",
          "problem_id": 20,
          "create_time": "18/10/2022 10:54:41.36083+08",
          "result": -2
        },
        {
          "id": "10cd731ebccc573fc5361806227ea132",
          "problem_id": 20,
          "create_time": "18/10/2022 09:40:49.68365+08",
          "result": -2
        },
        {
          "id": "50ae5708af398e7acbe3c2231bd4897f",
          "problem_id": 1,
          "create_time": "18/10/2022 10:35:49.028818+08",
          "result": -1
        },
        {
          "id": "7682736493c1968fd458d8b6cad7a64d",
          "problem_id": 1,
          "create_time": "18/10/2022 15:20:04.917105+08",
          "result": 0
        },
        {
          "id": "4aa27195ef045d97f58eadd542c28159",
          "problem_id": 20,
          "create_time": "18/10/2022 10:32:44.665133+08",
          "result": -1
        },
        {
          "id": "88d3500db96b414ad8584e95abc22a6d",
          "problem_id": 20,
          "create_time": "18/10/2022 10:32:56.311853+08",
          "result": -1
        },
        {
          "id": "4690defd36116374bb4d1a636eab61fb",
          "problem_id": 21,
          "create_time": "20/10/2022 17:32:44.81948+08",
          "result": -1
        },
        {
          "id": "e7aea1d618dc4d151828ef2fa85b51f2",
          "problem_id": 2,
          "create_time": "18/10/2022 14:10:25.088723+08",
          "result": 0
        },
        {
          "id": "cefb7e85a33401d1520481fe157abd05",
          "problem_id": 1,
          "create_time": "18/10/2022 10:51:35.746088+08",
          "result": -1
        },
        {
          "id": "1dc03541f818d32435133f87a79f7374",
          "problem_id": 21,
          "create_time": "19/10/2022 20:20:59.716174+08",
          "result": -2
        },
        {
          "id": "8eda2f32a50cdf1ce7466d34dd483b2a",
          "problem_id": 20,
          "create_time": "18/10/2022 11:53:57.95171+08",
          "result": -2
        },
        {
          "id": "ea94450863553053943a29709700db9d",
          "problem_id": 20,
          "create_time": "18/10/2022 10:55:40.115464+08",
          "result": 4
        },
        {
          "id": "6e27be6e70ab4b24649840da48ca3362",
          "problem_id": 20,
          "create_time": "18/10/2022 10:50:11.272172+08",
          "result": 4
        },
        {
          "id": "1c182eeb1f1db6996cd586f409f134bb",
          "problem_id": 2,
          "create_time": "18/10/2022 19:32:34.715807+08",
          "result": 0
        },
        {
          "id": "c942a762d0d05d477c2177f9026999a4",
          "problem_id": 21,
          "create_time": "18/10/2022 13:05:09.86965+08",
          "result": 0
        },
        {
          "id": "c4907131b1b9d01af27a2016daf36f56",
          "problem_id": 2,
          "create_time": "18/10/2022 10:53:48.587076+08",
          "result": -1
        },
        {
          "id": "184c76f4b16a9747d9bc843569198c5f",
          "problem_id": 20,
          "create_time": "18/10/2022 11:44:25.072158+08",
          "result": -1
        },
        {
          "id": "ca839b7554eb2a608ec1622fda181a66",
          "problem_id": 20,
          "create_time": "18/10/2022 10:54:02.381582+08",
          "result": 4
        },
        {
          "id": "f718b745ef0c3cc6d2ac92ab0322c63f",
          "problem_id": 2,
          "create_time": "18/10/2022 11:56:12.850662+08",
          "result": 4
        },
        {
          "id": "c2b22455dcac99ddb53fdc4e46d2a07f",
          "problem_id": 1,
          "create_time": "18/10/2022 10:54:30.346639+08",
          "result": -2
        },
        {
          "id": "32d8985e321706200a409cbd75e97634",
          "problem_id": 20,
          "create_time": "18/10/2022 14:30:41.529643+08",
          "result": -1
        },
        {
          "id": "be2efb48832cd1679f6c7164d8f013f2",
          "problem_id": 20,
          "create_time": "18/10/2022 12:01:38.853506+08",
          "result": -1
        },
        {
          "id": "4f54bb6f46ebdcc7dd30b7aec6eec424",
          "problem_id": 20,
          "create_time": "18/10/2022 11:40:08.910822+08",
          "result": -1
        },
        {
          "id": "f3795955ee8e415427e17f0f506bdf16",
          "problem_id": 20,
          "create_time": "18/10/2022 11:46:17.470444+08",
          "result": -1
        },
        {
          "id": "d7c875983842b42387cd0e08fa8c750d",
          "problem_id": 21,
          "create_time": "18/10/2022 21:46:12.989797+08",
          "result": -1
        },
        {
          "id": "4811d40ba9cb85ff1fb2c76207834888",
          "problem_id": 1,
          "create_time": "18/10/2022 10:55:06.87248+08",
          "result": 0
        },
        {
          "id": "bbaab3e201885814c241f0aaa9034605",
          "problem_id": 20,
          "create_time": "18/10/2022 10:54:25.664104+08",
          "result": 4
        },
        {
          "id": "defc93e63aaee8c58bf54afa365dea0e",
          "problem_id": 20,
          "create_time": "18/10/2022 11:57:02.728209+08",
          "result": -1
        },
        {
          "id": "8fd8c6c44a9a74597eb21190b7c63b98",
          "problem_id": 20,
          "create_time": "18/10/2022 12:01:54.101264+08",
          "result": -1
        },
        {
          "id": "66892ee2a996049686c583e1f7cc1076",
          "problem_id": 1,
          "create_time": "18/10/2022 10:54:51.986484+08",
          "result": -2
        },
        {
          "id": "b011797aea4fc9551012d07bd575d86e",
          "problem_id": 2,
          "create_time": "18/10/2022 11:03:01.806395+08",
          "result": 0
        },
        {
          "id": "dfb6569d8df15cea867eba7274d34716",
          "problem_id": 20,
          "create_time": "19/10/2022 18:54:40.947198+08",
          "result": 0
        },
        {
          "id": "b12c89dcc5ca2d47988e35465fc36404",
          "problem_id": 20,
          "create_time": "18/10/2022 08:47:04.862494+08",
          "result": 4
        },
        {
          "id": "a6dee31fda8d3328781835f87fac20f6",
          "problem_id": 20,
          "create_time": "18/10/2022 08:44:28.992944+08",
          "result": -1
        },
        {
          "id": "79c8edf4ac5b8f36ea9bc6a7726fbfe9",
          "problem_id": 2,
          "create_time": "18/10/2022 20:51:42.545436+08",
          "result": -1
        },
        {
          "id": "52c61f43181d40513106d2447fa5fa3e",
          "problem_id": 20,
          "create_time": "18/10/2022 13:12:56.769317+08",
          "result": -1
        },
        {
          "id": "b516ff8a9ae0f9512f62b479b3a68fae",
          "problem_id": 20,
          "create_time": "18/10/2022 11:53:50.99987+08",
          "result": 4
        },
        {
          "id": "c643fd88744cbb86d12eca2f6a83dfd7",
          "problem_id": 1,
          "create_time": "18/10/2022 10:59:17.8328+08",
          "result": -2
        },
        {
          "id": "476f8934b99762a77adc40e60fb576c2",
          "problem_id": 20,
          "create_time": "18/10/2022 11:51:32.28076+08",
          "result": -1
        },
        {
          "id": "f3670d89f49ec5d1f406ada8e2a4451c",
          "problem_id": 20,
          "create_time": "18/10/2022 10:59:52.800535+08",
          "result": -1
        },
        {
          "id": "b9b82489f47b16a3444d0b491d11a75e",
          "problem_id": 1,
          "create_time": "18/10/2022 11:00:55.513638+08",
          "result": -2
        },
        {
          "id": "454b9c4239c448517e0768ea34b88bcf",
          "problem_id": 20,
          "create_time": "18/10/2022 11:19:38.191094+08",
          "result": -2
        },
        {
          "id": "ab06520087db146bc1519cda0e213faa",
          "problem_id": 1,
          "create_time": "18/10/2022 14:47:20.786757+08",
          "result": 0
        },
        {
          "id": "23750975c249f28ac7688bbe75057f60",
          "problem_id": 20,
          "create_time": "18/10/2022 11:47:10.093409+08",
          "result": -1
        },
        {
          "id": "ac17c27859a22051e1bea9d319d88bc3",
          "problem_id": 21,
          "create_time": "18/10/2022 11:19:20.208442+08",
          "result": -2
        },
        {
          "id": "d57a1f80f62acb219e3839ac0adbe6a4",
          "problem_id": 20,
          "create_time": "18/10/2022 12:11:24.485758+08",
          "result": 4
        },
        {
          "id": "1581f36ff8b74b323d4ebf6dd398223b",
          "problem_id": 1,
          "create_time": "18/10/2022 11:02:16.137794+08",
          "result": -1
        },
        {
          "id": "ae648e995bedaac4e1b83eb4b0e25480",
          "problem_id": 20,
          "create_time": "18/10/2022 12:35:30.245171+08",
          "result": -1
        },
        {
          "id": "6f51b0b31cd05cfd5a59df87927c2144",
          "problem_id": 2,
          "create_time": "18/10/2022 11:14:51.18814+08",
          "result": 1
        },
        {
          "id": "6e188fa96e93994f43f74e4575acff5a",
          "problem_id": 21,
          "create_time": "18/10/2022 11:19:36.190905+08",
          "result": -2
        },
        {
          "id": "7fac31b190d341d806b2affa07d8cd83",
          "problem_id": 20,
          "create_time": "18/10/2022 13:16:06.285162+08",
          "result": -1
        },
        {
          "id": "927d8406b36d25ba081a9653defde78f",
          "problem_id": 1,
          "create_time": "18/10/2022 11:08:31.070024+08",
          "result": -1
        },
        {
          "id": "76d671576b01fcfcf993c035aebbed57",
          "problem_id": 20,
          "create_time": "18/10/2022 11:51:46.650661+08",
          "result": -1
        },
        {
          "id": "6c8ff399306e8a1f73af0fc4d761afe1",
          "problem_id": 2,
          "create_time": "18/10/2022 11:15:46.551744+08",
          "result": -1
        },
        {
          "id": "e955f56c14271cdecdc3133806b7b5b9",
          "problem_id": 2,
          "create_time": "18/10/2022 11:21:59.363381+08",
          "result": 0
        },
        {
          "id": "10503bf99d10bd4af03412407429cc2c",
          "problem_id": 1,
          "create_time": "18/10/2022 11:09:00.433996+08",
          "result": -1
        },
        {
          "id": "f4498f03bfef029c920dc5db9e6b0e6c",
          "problem_id": 20,
          "create_time": "18/10/2022 10:55:16.307787+08",
          "result": -1
        },
        {
          "id": "b8ebdee3eca9564324180601fa4bc4c5",
          "problem_id": 2,
          "create_time": "18/10/2022 11:52:00.997352+08",
          "result": -1
        },
        {
          "id": "7344ca6da6afce504584f4dafbeda897",
          "problem_id": 20,
          "create_time": "18/10/2022 14:12:41.814719+08",
          "result": 0
        },
        {
          "id": "dddea8830fb68688a73213ed01ead6fa",
          "problem_id": 20,
          "create_time": "18/10/2022 12:11:53.008086+08",
          "result": -1
        },
        {
          "id": "3730729b38fc2f49d67f11c861430e85",
          "problem_id": 20,
          "create_time": "18/10/2022 11:57:51.920212+08",
          "result": -2
        },
        {
          "id": "62ef7e02d55577ae36da5af6f6a364dc",
          "problem_id": 1,
          "create_time": "18/10/2022 11:09:31.060832+08",
          "result": -1
        },
        {
          "id": "93c36a8c6492ac343de4be3cc8c36be4",
          "problem_id": 20,
          "create_time": "18/10/2022 11:10:12.287533+08",
          "result": -2
        },
        {
          "id": "740f184a69fbbfd45899a640b6ec0eed",
          "problem_id": 2,
          "create_time": "18/10/2022 11:15:50.30019+08",
          "result": -2
        },
        {
          "id": "8fdceeac9fa4579093d56dfdb9e4eac6",
          "problem_id": 20,
          "create_time": "18/10/2022 11:31:34.975672+08",
          "result": -2
        },
        {
          "id": "2fcc3817ee530ff8c34f09805edb3990",
          "problem_id": 1,
          "create_time": "18/10/2022 11:10:19.443692+08",
          "result": -1
        },
        {
          "id": "b16af972030dd95aa965308ccccb5944",
          "problem_id": 20,
          "create_time": "18/10/2022 11:04:31.997884+08",
          "result": 4
        },
        {
          "id": "88e4c7eac971a7a56eef41da565a132d",
          "problem_id": 20,
          "create_time": "18/10/2022 11:16:12.587049+08",
          "result": -2
        },
        {
          "id": "8991c278600f88eccb0643a8427db314",
          "problem_id": 1,
          "create_time": "18/10/2022 11:11:26.835133+08",
          "result": 0
        },
        {
          "id": "451f107d883744456307486edd180181",
          "problem_id": 20,
          "create_time": "18/10/2022 12:15:36.161284+08",
          "result": -1
        },
        {
          "id": "ade5ce803836e0149dafceaaef1d3964",
          "problem_id": 2,
          "create_time": "18/10/2022 11:16:00.985627+08",
          "result": 1
        },
        {
          "id": "2ff2825c47650c7ddba0ebea90dd9192",
          "problem_id": 20,
          "create_time": "18/10/2022 11:52:33.09708+08",
          "result": -2
        },
        {
          "id": "a797ad53ddadf21582791c60bcc9a930",
          "problem_id": 20,
          "create_time": "18/10/2022 11:12:43.819027+08",
          "result": -2
        },
        {
          "id": "655f661acd3c6642cd39f9fc0e17dd9c",
          "problem_id": 20,
          "create_time": "22/10/2022 00:13:39.237737+08",
          "result": -1
        },
        {
          "id": "c98a9aaf2b9b5a3b331110cbe9934894",
          "problem_id": 20,
          "create_time": "18/10/2022 20:01:20.866922+08",
          "result": -2
        },
        {
          "id": "a7e3437b4ba46cd231a584351f6bfa97",
          "problem_id": 20,
          "create_time": "18/10/2022 11:13:26.801764+08",
          "result": -2
        },
        {
          "id": "9c85fa43358a66455292b6cc775d3b98",
          "problem_id": 1,
          "create_time": "18/10/2022 11:13:27.036564+08",
          "result": -1
        },
        {
          "id": "8fc05c04a11a392a361b23d27d104c96",
          "problem_id": 2,
          "create_time": "18/10/2022 11:17:48.117816+08",
          "result": -1
        },
        {
          "id": "18ad8b3d71d5ba85ad1fe6810911921d",
          "problem_id": 20,
          "create_time": "18/10/2022 13:21:51.227604+08",
          "result": -1
        },
        {
          "id": "aad54e28d63ebc96d65e2217a6cf1a6e",
          "problem_id": 21,
          "create_time": "19/10/2022 20:21:09.433782+08",
          "result": -2
        },
        {
          "id": "f4a43de3740960358362745012a02c46",
          "problem_id": 21,
          "create_time": "18/10/2022 21:56:41.465832+08",
          "result": 0
        },
        {
          "id": "ad3b1748583167f61c68d20ead207f9f",
          "problem_id": 1,
          "create_time": "18/10/2022 11:31:28.339956+08",
          "result": -1
        },
        {
          "id": "149b5fdf4aa21974db946fe00218d075",
          "problem_id": 20,
          "create_time": "18/10/2022 11:35:50.426469+08",
          "result": -1
        },
        {
          "id": "b35371308668aefed1970b8bafe52075",
          "problem_id": 20,
          "create_time": "18/10/2022 11:35:03.382039+08",
          "result": -1
        },
        {
          "id": "cd08457cdf814df5c215e00192379d6d",
          "problem_id": 1,
          "create_time": "18/10/2022 11:53:03.969678+08",
          "result": -2
        },
        {
          "id": "baefe02c470539c5c10ce21b9f8a14ad",
          "problem_id": 20,
          "create_time": "18/10/2022 12:18:12.545712+08",
          "result": -1
        },
        {
          "id": "c8f33bdc8543e334693f6a83d29f6b8f",
          "problem_id": 1,
          "create_time": "18/10/2022 11:36:14.235397+08",
          "result": -1
        },
        {
          "id": "e67a3629c56f8ad60450ab0b15645891",
          "problem_id": 20,
          "create_time": "18/10/2022 11:57:07.129044+08",
          "result": -1
        },
        {
          "id": "ce7b5adec62c771e9e5d8ab19d41530d",
          "problem_id": 1,
          "create_time": "18/10/2022 20:52:30.04343+08",
          "result": -2
        },
        {
          "id": "9b25f007911e0802747a7b0c13e6c8b7",
          "problem_id": 20,
          "create_time": "18/10/2022 11:58:53.002376+08",
          "result": -1
        },
        {
          "id": "f05caf79cd15ce55819385ee53841ba8",
          "problem_id": 20,
          "create_time": "18/10/2022 11:43:48.835068+08",
          "result": -1
        },
        {
          "id": "42003a4c6a1a7bac88ec06b73e7c8925",
          "problem_id": 20,
          "create_time": "18/10/2022 11:37:27.226701+08",
          "result": -1
        },
        {
          "id": "1700ce7466b5bec3420e580081feb2ed",
          "problem_id": 20,
          "create_time": "18/10/2022 12:03:44.965528+08",
          "result": -1
        },
        {
          "id": "741cc14b4275288e59eef955fc340eae",
          "problem_id": 20,
          "create_time": "18/10/2022 11:04:14.238086+08",
          "result": -1
        },
        {
          "id": "1c079f748ae10e42c3e86b61368a1a3b",
          "problem_id": 2,
          "create_time": "18/10/2022 12:07:56.437389+08",
          "result": -1
        },
        {
          "id": "541076116b987fdb31e5853cd22c6c6d",
          "problem_id": 20,
          "create_time": "18/10/2022 13:23:03.531444+08",
          "result": -1
        },
        {
          "id": "97f7b5a4fd12605d9d051d45a10e8228",
          "problem_id": 21,
          "create_time": "19/10/2022 11:21:05.826303+08",
          "result": -1
        },
        {
          "id": "febb79460e437c6d36cb9e68bd788a85",
          "problem_id": 20,
          "create_time": "18/10/2022 09:12:00.691628+08",
          "result": 4
        },
        {
          "id": "3f3ac69f18e49cf491320dbf3dd08908",
          "problem_id": 20,
          "create_time": "18/10/2022 09:26:13.783698+08",
          "result": -1
        },
        {
          "id": "24e9d3b5d9574930683b15ae603ab016",
          "problem_id": 20,
          "create_time": "18/10/2022 08:52:20.395404+08",
          "result": -1
        },
        {
          "id": "c172b594d4a622198ab49bd61a96e9ad",
          "problem_id": 20,
          "create_time": "18/10/2022 11:27:29.595749+08",
          "result": -1
        },
        {
          "id": "999a007d80093db826c8fb1e63d4072a",
          "problem_id": 20,
          "create_time": "18/10/2022 12:12:08.197457+08",
          "result": 4
        },
        {
          "id": "b6a73931a0acde388a9045e4b45d1130",
          "problem_id": 20,
          "create_time": "18/10/2022 12:19:26.769186+08",
          "result": -2
        },
        {
          "id": "5a545275fe2e286af5586d9cac402fee",
          "problem_id": 20,
          "create_time": "18/10/2022 14:34:25.179193+08",
          "result": 0
        },
        {
          "id": "eea77bc5e089a042736e65ea279a9508",
          "problem_id": 20,
          "create_time": "18/10/2022 16:42:41.749325+08",
          "result": -2
        },
        {
          "id": "6633460482ea8aa51addc9cfb235d701",
          "problem_id": 20,
          "create_time": "18/10/2022 08:54:47.474951+08",
          "result": -1
        },
        {
          "id": "f3d21dfe4a1c84822a7390db3adb36be",
          "problem_id": 20,
          "create_time": "18/10/2022 10:02:16.498481+08",
          "result": -1
        },
        {
          "id": "e2312181cb81a1622c63873dc3227862",
          "problem_id": 20,
          "create_time": "18/10/2022 12:05:53.971909+08",
          "result": -1
        },
        {
          "id": "dc318a86b58ced8ac81331239607337d",
          "problem_id": 20,
          "create_time": "18/10/2022 12:12:23.8375+08",
          "result": -1
        },
        {
          "id": "3e45cc668dbc7bbf32f7b65670c40c8e",
          "problem_id": 20,
          "create_time": "18/10/2022 12:24:46.59185+08",
          "result": 4
        },
        {
          "id": "c96122d6048bfc6eda4b92931577d855",
          "problem_id": 20,
          "create_time": "18/10/2022 08:55:41.819098+08",
          "result": -1
        },
        {
          "id": "344c8e16b7358dc2323c82e93739f79f",
          "problem_id": 20,
          "create_time": "18/10/2022 10:02:56.922595+08",
          "result": -1
        },
        {
          "id": "a055dadba605f04574543c18f8c52edd",
          "problem_id": 21,
          "create_time": "18/10/2022 13:34:16.831765+08",
          "result": -1
        },
        {
          "id": "14e814fc0994c28c03b9337840fe17d9",
          "problem_id": 2,
          "create_time": "18/10/2022 17:11:04.17894+08",
          "result": 0
        },
        {
          "id": "ba7d2476edce49ed1773a7994059e271",
          "problem_id": 20,
          "create_time": "18/10/2022 10:10:40.061839+08",
          "result": -2
        },
        {
          "id": "f5bdd8c34d93b4774df74b5642eeaca5",
          "problem_id": 20,
          "create_time": "18/10/2022 09:03:04.661412+08",
          "result": -1
        },
        {
          "id": "e2ba49b41e20ba438dfd1daea4092e29",
          "problem_id": 20,
          "create_time": "18/10/2022 09:16:30.596451+08",
          "result": -1
        },
        {
          "id": "4ea4377fef818e6ebf8148a2f9de2e77",
          "problem_id": 2,
          "create_time": "18/10/2022 12:06:40.846649+08",
          "result": 4
        },
        {
          "id": "731611e8783f3d9f28f8f96fbe2384b5",
          "problem_id": 20,
          "create_time": "18/10/2022 12:12:33.394752+08",
          "result": 4
        },
        {
          "id": "5fd43b2779c272b79fbb9d367da3734f",
          "problem_id": 1,
          "create_time": "18/10/2022 12:16:24.516929+08",
          "result": -1
        },
        {
          "id": "b3d74600b7d4bafd460423cb4207c221",
          "problem_id": 20,
          "create_time": "18/10/2022 09:40:00.363008+08",
          "result": -1
        },
        {
          "id": "9b23a5869ab638d95142691a8ecfe593",
          "problem_id": 1,
          "create_time": "18/10/2022 14:13:41.031557+08",
          "result": 4
        },
        {
          "id": "d41af0e8afbc882e6501d40edccdb60e",
          "problem_id": 2,
          "create_time": "18/10/2022 12:13:04.993537+08",
          "result": 4
        },
        {
          "id": "36da74263736e8e825ca5a193c46cdd0",
          "problem_id": 20,
          "create_time": "18/10/2022 12:16:27.146676+08",
          "result": -1
        },
        {
          "id": "b0be3c698d7e832d2fef77fad722378f",
          "problem_id": 20,
          "create_time": "18/10/2022 09:24:14.006755+08",
          "result": -1
        },
        {
          "id": "6bd3bdd09c8619eb355d66a0dbc28e1e",
          "problem_id": 20,
          "create_time": "18/10/2022 09:16:48.155117+08",
          "result": -1
        },
        {
          "id": "e2dd6dd85dff823b9fcadf405830d2dc",
          "problem_id": 20,
          "create_time": "18/10/2022 10:32:08.100751+08",
          "result": 4
        },
        {
          "id": "17b9d3e4ccc0072e61b97053712207a9",
          "problem_id": 2,
          "create_time": "18/10/2022 12:08:33.185499+08",
          "result": 4
        },
        {
          "id": "7f73efede134b46a76f5a2739a9c261a",
          "problem_id": 2,
          "create_time": "18/10/2022 12:13:18.70447+08",
          "result": -1
        },
        {
          "id": "1c159866dea7a866bef2966ccd2eb426",
          "problem_id": 20,
          "create_time": "18/10/2022 10:07:51.418925+08",
          "result": -1
        },
        {
          "id": "53234fc1175a1bd581147ba755812eac",
          "problem_id": 21,
          "create_time": "18/10/2022 13:35:22.474539+08",
          "result": 4
        },
        {
          "id": "5628d54f974c0f2af665728e95f70e7b",
          "problem_id": 2,
          "create_time": "18/10/2022 14:03:08.74737+08",
          "result": 1
        },
        {
          "id": "a31ae71e6c05100bf94b0542a55baa04",
          "problem_id": 20,
          "create_time": "18/10/2022 10:31:36.633167+08",
          "result": -1
        },
        {
          "id": "fbf8ff8fecaaf81543d30dfadbaef25f",
          "problem_id": 2,
          "create_time": "18/10/2022 12:15:06.829526+08",
          "result": -2
        },
        {
          "id": "a8249bf6d131ea415faa66d53c633a01",
          "problem_id": 20,
          "create_time": "18/10/2022 10:15:02.176308+08",
          "result": -1
        },
        {
          "id": "86b8d495ac73314399361530066fd006",
          "problem_id": 20,
          "create_time": "18/10/2022 17:49:22.18359+08",
          "result": -1
        },
        {
          "id": "c21fafd0fb21c83bc15de5380be7e431",
          "problem_id": 20,
          "create_time": "18/10/2022 12:09:46.858327+08",
          "result": -1
        },
        {
          "id": "979cfae2001d67ca124317ca7e5c0320",
          "problem_id": 20,
          "create_time": "18/10/2022 12:16:52.097937+08",
          "result": 4
        },
        {
          "id": "84cdc806ec981eca6ed37e3ccf27708c",
          "problem_id": 20,
          "create_time": "18/10/2022 12:27:19.837167+08",
          "result": 4
        },
        {
          "id": "9e5451a3a3f5532cd38b905cabe1925d",
          "problem_id": 20,
          "create_time": "18/10/2022 10:30:01.276961+08",
          "result": -1
        },
        {
          "id": "ae17cec15ab0c53a8c9847c8d757349d",
          "problem_id": 20,
          "create_time": "18/10/2022 21:47:34.789172+08",
          "result": -1
        },
        {
          "id": "a7de8cdd4ebdfe66eba1dbea6e227737",
          "problem_id": 20,
          "create_time": "18/10/2022 12:11:12.19214+08",
          "result": 4
        },
        {
          "id": "2024b6550b906c4a2830853f8363fc03",
          "problem_id": 20,
          "create_time": "19/10/2022 15:38:48.055098+08",
          "result": -1
        },
        {
          "id": "38f958376deddffda2f40aa46f0724df",
          "problem_id": 2,
          "create_time": "18/10/2022 12:15:11.708322+08",
          "result": 0
        },
        {
          "id": "37f8aa562329a2f738507e48b708e063",
          "problem_id": 20,
          "create_time": "18/10/2022 12:17:44.79543+08",
          "result": 4
        },
        {
          "id": "165b31f681149ebb180a583dc22316f6",
          "problem_id": 2,
          "create_time": "18/10/2022 12:31:04.908794+08",
          "result": 4
        },
        {
          "id": "3c536e0a47892b8a3112b4d49f43278b",
          "problem_id": 21,
          "create_time": "18/10/2022 13:35:42.773142+08",
          "result": 0
        },
        {
          "id": "9cc0cbe7434512ba152ccafb32944255",
          "problem_id": 20,
          "create_time": "18/10/2022 12:24:25.321673+08",
          "result": 4
        },
        {
          "id": "59b7d882944351008d4f1750efb6dfbf",
          "problem_id": 1,
          "create_time": "18/10/2022 14:17:48.924162+08",
          "result": -1
        },
        {
          "id": "73385219e14cb2f77f98e0c6d90d514c",
          "problem_id": 21,
          "create_time": "18/10/2022 14:37:22.334839+08",
          "result": 4
        },
        {
          "id": "b391dd669af2eaddbc8d0981f645fa3e",
          "problem_id": 20,
          "create_time": "18/10/2022 12:24:53.044427+08",
          "result": -1
        },
        {
          "id": "a5816c21fc977e149c9336d7e2ead0f0",
          "problem_id": 21,
          "create_time": "18/10/2022 20:25:21.305675+08",
          "result": -2
        },
        {
          "id": "18bbe45c395d13c4995759765e5a1e68",
          "problem_id": 20,
          "create_time": "18/10/2022 12:27:11.075092+08",
          "result": -1
        },
        {
          "id": "29d691d5cf0069a12cbf8e6c2b641f30",
          "problem_id": 20,
          "create_time": "18/10/2022 13:38:11.5565+08",
          "result": -1
        },
        {
          "id": "e3ebb997dccbbdd7310368e34a2be556",
          "problem_id": 20,
          "create_time": "18/10/2022 12:28:53.181331+08",
          "result": 4
        },
        {
          "id": "31c083aa28e1be5eb08d2c50faf593fd",
          "problem_id": 21,
          "create_time": "18/10/2022 15:46:20.575484+08",
          "result": -1
        },
        {
          "id": "959ca4daab8577c8a088505fbff930b0",
          "problem_id": 20,
          "create_time": "18/10/2022 10:53:02.742309+08",
          "result": -1
        },
        {
          "id": "8410821c9c83f02d307b34587259c40c",
          "problem_id": 20,
          "create_time": "18/10/2022 18:45:59.536646+08",
          "result": -1
        },
        {
          "id": "c750ea10d82f349e72145d70468745c0",
          "problem_id": 20,
          "create_time": "18/10/2022 13:41:18.095229+08",
          "result": -1
        },
        {
          "id": "ae22d118b21b35d3a68046140974e1da",
          "problem_id": 21,
          "create_time": "18/10/2022 14:37:38.641658+08",
          "result": 4
        },
        {
          "id": "52d6cb0e98d4aacf08cdb8bae4732b98",
          "problem_id": 21,
          "create_time": "19/10/2022 11:23:17.071487+08",
          "result": 0
        },
        {
          "id": "9df1ad09671c2863d35f6d25adb5b43b",
          "problem_id": 1,
          "create_time": "18/10/2022 14:47:25.030995+08",
          "result": 4
        },
        {
          "id": "f9fafeb60469c554b7345c9d0dbf1dab",
          "problem_id": 20,
          "create_time": "18/10/2022 17:50:40.937768+08",
          "result": -2
        },
        {
          "id": "45835e8879ad4af1e5f886989284494e",
          "problem_id": 20,
          "create_time": "18/10/2022 18:04:04.839456+08",
          "result": -1
        },
        {
          "id": "2ebdd54e6785e961d13f4214ed1cae27",
          "problem_id": 20,
          "create_time": "22/10/2022 00:14:16.941593+08",
          "result": -1
        },
        {
          "id": "c686aa0f7160900149d2d43df66bd3c6",
          "problem_id": 21,
          "create_time": "18/10/2022 15:51:46.123683+08",
          "result": 0
        },
        {
          "id": "ebab0651474c5aba1b0fe474dd491157",
          "problem_id": 21,
          "create_time": "18/10/2022 18:44:30.771727+08",
          "result": -2
        },
        {
          "id": "9c803f6b51848652eaf45d69e338ea2b",
          "problem_id": 1,
          "create_time": "18/10/2022 14:49:36.169446+08",
          "result": 4
        },
        {
          "id": "327066f4baf4c51555e22923fa8013a4",
          "problem_id": 1,
          "create_time": "18/10/2022 14:49:41.703217+08",
          "result": 4
        },
        {
          "id": "c9402ed052101d409bec396408b8910c",
          "problem_id": 20,
          "create_time": "18/10/2022 14:49:49.650389+08",
          "result": -1
        },
        {
          "id": "c213f61bfe48ec7c2d955558e217b728",
          "problem_id": 20,
          "create_time": "18/10/2022 15:33:28.870749+08",
          "result": -1
        },
        {
          "id": "2abae41f81f0a938ece73d4e4fdebc0f",
          "problem_id": 20,
          "create_time": "18/10/2022 16:43:11.081541+08",
          "result": -2
        },
        {
          "id": "c8fcc048aad1819458136d4f0e5e225a",
          "problem_id": 2,
          "create_time": "21/10/2022 20:12:28.110933+08",
          "result": 0
        },
        {
          "id": "608fa32969013f8987c99432b7222b07",
          "problem_id": 1,
          "create_time": "18/10/2022 14:52:46.011992+08",
          "result": 0
        },
        {
          "id": "c0be4131a1ea028dd7d89242f12f1cf7",
          "problem_id": 20,
          "create_time": "18/10/2022 15:34:51.987893+08",
          "result": -1
        },
        {
          "id": "675ef9bfe17762ce016121a3a31de60b",
          "problem_id": 20,
          "create_time": "22/10/2022 00:29:59.160711+08",
          "result": -1
        },
        {
          "id": "1df3ffd11947f2d5d9b2f3153a72f68b",
          "problem_id": 20,
          "create_time": "18/10/2022 15:38:14.443049+08",
          "result": -1
        },
        {
          "id": "3f15f80159ed50c15325a6694c7b7c3a",
          "problem_id": 20,
          "create_time": "18/10/2022 16:55:39.586685+08",
          "result": -1
        },
        {
          "id": "e40e16281791fd80e1175b3be303205d",
          "problem_id": 20,
          "create_time": "18/10/2022 18:46:06.65507+08",
          "result": -1
        },
        {
          "id": "7d070fe77b4a02a192f405b2efdcca95",
          "problem_id": 21,
          "create_time": "18/10/2022 15:04:46.92089+08",
          "result": 0
        },
        {
          "id": "3824102b2a87b5ea4ce8b86942f560a3",
          "problem_id": 21,
          "create_time": "18/10/2022 15:05:53.168665+08",
          "result": -1
        },
        {
          "id": "1740c166214d30ab79486c23c34ad608",
          "problem_id": 2,
          "create_time": "18/10/2022 16:14:27.815866+08",
          "result": 0
        },
        {
          "id": "2f8e3e99ac10b8bec471d694ce1fe401",
          "problem_id": 20,
          "create_time": "18/10/2022 15:36:24.678046+08",
          "result": -1
        },
        {
          "id": "d5dc677211b463a4d9a040f93ea5ad7e",
          "problem_id": 19,
          "create_time": "18/10/2022 21:32:48.034994+08",
          "result": -1
        },
        {
          "id": "47fedcdf0c79ef32bdf4776cfa40043e",
          "problem_id": 20,
          "create_time": "18/10/2022 15:39:50.758289+08",
          "result": -1
        },
        {
          "id": "3253ea37d71b93c5f522601e2a3b21e9",
          "problem_id": 21,
          "create_time": "18/10/2022 15:47:41.423758+08",
          "result": -1
        },
        {
          "id": "d1f6771bfdfbbe62f677507f41f6fac0",
          "problem_id": 20,
          "create_time": "18/10/2022 15:51:44.976223+08",
          "result": 1
        },
        {
          "id": "8edf1229c06cc838d021dfd14ab7cfbd",
          "problem_id": 20,
          "create_time": "18/10/2022 15:53:09.478344+08",
          "result": -1
        },
        {
          "id": "ec3381734e19311d510d1ffb46766e6f",
          "problem_id": 20,
          "create_time": "18/10/2022 15:57:43.338703+08",
          "result": -1
        },
        {
          "id": "683356e152a716186916ff2e084d0bcd",
          "problem_id": 20,
          "create_time": "18/10/2022 16:47:09.802463+08",
          "result": -1
        },
        {
          "id": "bea2643bae490985a01491577ebfdced",
          "problem_id": 21,
          "create_time": "18/10/2022 18:59:10.357163+08",
          "result": -2
        },
        {
          "id": "785fd9f4cbbe3e3cedb149a20fa67ce6",
          "problem_id": 20,
          "create_time": "18/10/2022 16:56:40.089114+08",
          "result": -1
        },
        {
          "id": "ad87ad1e18a043f4068e89f25872a5eb",
          "problem_id": 20,
          "create_time": "18/10/2022 19:05:00.363492+08",
          "result": 0
        },
        {
          "id": "3419058c9c7be8baedf077bc794eaafa",
          "problem_id": 20,
          "create_time": "19/10/2022 14:46:03.398559+08",
          "result": -1
        },
        {
          "id": "34ed8547cce87c09b838a833695bb840",
          "problem_id": 20,
          "create_time": "18/10/2022 17:50:49.781966+08",
          "result": 0
        },
        {
          "id": "2b9ec0ea61adf6ff22a606f8b184c762",
          "problem_id": 20,
          "create_time": "18/10/2022 16:51:01.857027+08",
          "result": 0
        },
        {
          "id": "20647044cb7c4ab94d3c4490ced3ddf1",
          "problem_id": 20,
          "create_time": "18/10/2022 18:46:18.92444+08",
          "result": -1
        },
        {
          "id": "227ac86833e954818ff92d96657856af",
          "problem_id": 21,
          "create_time": "18/10/2022 19:01:41.300636+08",
          "result": -2
        },
        {
          "id": "d7e3c5356c6f131f1b89b712f8e28b16",
          "problem_id": 20,
          "create_time": "18/10/2022 18:50:25.640322+08",
          "result": -1
        },
        {
          "id": "d18c3e5cf0860cee840606d99dfa4759",
          "problem_id": 21,
          "create_time": "18/10/2022 18:54:03.220367+08",
          "result": -2
        },
        {
          "id": "8a69732e19d900139bde07608f7db724",
          "problem_id": 20,
          "create_time": "18/10/2022 16:56:59.221585+08",
          "result": -1
        },
        {
          "id": "ff57e67e6872c534528635802488ac97",
          "problem_id": 20,
          "create_time": "19/10/2022 14:59:30.680508+08",
          "result": 4
        },
        {
          "id": "c723440dee20f47f9fc6ba9eaf604eb3",
          "problem_id": 20,
          "create_time": "18/10/2022 18:00:23.96449+08",
          "result": -1
        },
        {
          "id": "7e9c55e7844696c0fe0ccbe649591dee",
          "problem_id": 20,
          "create_time": "18/10/2022 18:52:53.38489+08",
          "result": -1
        },
        {
          "id": "91679842e52516f639201a3404a8c942",
          "problem_id": 21,
          "create_time": "18/10/2022 19:19:06.597021+08",
          "result": -1
        },
        {
          "id": "51f6fb610871253c0be80f8415370b83",
          "problem_id": 20,
          "create_time": "18/10/2022 16:59:43.798155+08",
          "result": 0
        },
        {
          "id": "b8764153aeba6a61831ae4a37632eca1",
          "problem_id": 20,
          "create_time": "18/10/2022 17:20:08.980827+08",
          "result": 1
        },
        {
          "id": "191bd40027aede994690be8fe2bf7d13",
          "problem_id": 20,
          "create_time": "18/10/2022 17:29:16.048372+08",
          "result": -1
        },
        {
          "id": "3b6a96a8d7d8291dbf0868f026b3a372",
          "problem_id": 2,
          "create_time": "18/10/2022 19:31:34.02093+08",
          "result": -1
        },
        {
          "id": "111fe81a1501663bf698ce8af321b50e",
          "problem_id": 20,
          "create_time": "18/10/2022 18:54:02.761186+08",
          "result": -1
        },
        {
          "id": "235b9e55fee4d996ebf34edd14099a00",
          "problem_id": 2,
          "create_time": "18/10/2022 20:03:25.40467+08",
          "result": -2
        },
        {
          "id": "b7d8ae007b729ff9e7e6de79bcc5e9a7",
          "problem_id": 20,
          "create_time": "18/10/2022 17:20:45.427029+08",
          "result": -1
        },
        {
          "id": "44fcec122398b2c04f46df7fb12136f3",
          "problem_id": 20,
          "create_time": "18/10/2022 18:07:39.968998+08",
          "result": -2
        },
        {
          "id": "617515cbe5a4f361b4b80d2facaed835",
          "problem_id": 20,
          "create_time": "18/10/2022 19:02:47.555608+08",
          "result": 0
        },
        {
          "id": "29090a27b6145437caf5b889db368472",
          "problem_id": 2,
          "create_time": "18/10/2022 20:26:42.268228+08",
          "result": 0
        },
        {
          "id": "351dd17bbdfc6787350df7ef914a2c91",
          "problem_id": 20,
          "create_time": "18/10/2022 17:27:43.769634+08",
          "result": -2
        },
        {
          "id": "354abeb18a015123cd8a5b64ad375a40",
          "problem_id": 21,
          "create_time": "20/10/2022 11:10:06.145978+08",
          "result": -1
        },
        {
          "id": "db7bd28a7e31b1fcae7c4fd70d10b7af",
          "problem_id": 20,
          "create_time": "18/10/2022 17:29:01.44606+08",
          "result": -2
        },
        {
          "id": "77e11e55e04503a2d42d173d5f24dccf",
          "problem_id": 2,
          "create_time": "18/10/2022 19:31:49.801738+08",
          "result": -1
        },
        {
          "id": "b53b493c02991481a2468c5f66bd05cf",
          "problem_id": 20,
          "create_time": "18/10/2022 17:39:06.041975+08",
          "result": -1
        },
        {
          "id": "fd64ef5199e8b3e9c2ba13c87fcc05bb",
          "problem_id": 20,
          "create_time": "18/10/2022 17:30:21.964627+08",
          "result": -1
        },
        {
          "id": "1acc1e1289c9c41173bb611f9988d22e",
          "problem_id": 20,
          "create_time": "18/10/2022 18:09:45.063873+08",
          "result": -1
        },
        {
          "id": "62854181710f0d278f824d9c7318b643",
          "problem_id": 20,
          "create_time": "19/10/2022 18:48:28.030861+08",
          "result": 1
        },
        {
          "id": "982e5d49ce90325a9b0687d6218c7986",
          "problem_id": 20,
          "create_time": "18/10/2022 17:31:15.161283+08",
          "result": -1
        },
        {
          "id": "9116651474aeb94e04fed2e9d0dafcef",
          "problem_id": 21,
          "create_time": "18/10/2022 18:45:52.059606+08",
          "result": -1
        },
        {
          "id": "cdf9fbb2f186ec2ee46115e3c7df8aa5",
          "problem_id": 21,
          "create_time": "18/10/2022 21:36:51.583011+08",
          "result": -1
        },
        {
          "id": "26fb2139fce1f194906048836cce4ecf",
          "problem_id": 1,
          "create_time": "18/10/2022 19:33:02.127737+08",
          "result": -2
        },
        {
          "id": "c5d995421c2a9034629de6b684ae539f",
          "problem_id": 21,
          "create_time": "18/10/2022 22:19:04.466119+08",
          "result": -1
        },
        {
          "id": "8dd7d388c02c46f17b9e1b870deca610",
          "problem_id": 1,
          "create_time": "18/10/2022 19:33:09.15533+08",
          "result": -1
        },
        {
          "id": "7a9fb53427fa2d89ab5e2c2ed41bae8c",
          "problem_id": 2,
          "create_time": "18/10/2022 20:03:15.876082+08",
          "result": -2
        },
        {
          "id": "ec3cf728737b2e6073914671274e5bf2",
          "problem_id": 1,
          "create_time": "18/10/2022 19:35:17.787953+08",
          "result": -1
        },
        {
          "id": "625d010c4b40419fd5b12ca0a8ea5add",
          "problem_id": 20,
          "create_time": "19/10/2022 19:07:04.601168+08",
          "result": 0
        },
        {
          "id": "99f6b4a3beac2ff3d97cfbba48f9dffa",
          "problem_id": 1,
          "create_time": "18/10/2022 19:36:02.166998+08",
          "result": 0
        },
        {
          "id": "eaa467ef09f9ca0f1319a0bf73edf3ad",
          "problem_id": 21,
          "create_time": "18/10/2022 21:48:17.818182+08",
          "result": -1
        },
        {
          "id": "ca1fd6ce4dff6bef296e807ca77a67eb",
          "problem_id": 2,
          "create_time": "18/10/2022 20:03:38.805555+08",
          "result": -1
        },
        {
          "id": "89050ab194628cd89abfbd7aec395e2a",
          "problem_id": 21,
          "create_time": "18/10/2022 19:37:05.15668+08",
          "result": 0
        },
        {
          "id": "494eedcf0a6c0cb2a0c420e31d9cb078",
          "problem_id": 21,
          "create_time": "18/10/2022 21:38:07.955601+08",
          "result": -1
        },
        {
          "id": "969e397a58d597db9e44c81d73b480a8",
          "problem_id": 2,
          "create_time": "21/10/2022 22:01:11.78456+08",
          "result": -1
        },
        {
          "id": "feb3b955fc469935222aaeb7e13a9b09",
          "problem_id": 2,
          "create_time": "18/10/2022 20:03:03.855172+08",
          "result": -1
        },
        {
          "id": "60df003d53ef08e1065bcc88712326e1",
          "problem_id": 20,
          "create_time": "18/10/2022 21:57:36.538039+08",
          "result": -1
        },
        {
          "id": "de9057be8cdc4ccff25db524a11b0290",
          "problem_id": 2,
          "create_time": "18/10/2022 20:03:47.653939+08",
          "result": -1
        },
        {
          "id": "b5b3de19b6ddc2bd7f2c72d76d47f71b",
          "problem_id": 2,
          "create_time": "20/10/2022 11:15:14.177683+08",
          "result": -1
        },
        {
          "id": "d157ea6cb7a296c7082d00faa59511bc",
          "problem_id": 2,
          "create_time": "18/10/2022 20:34:40.698266+08",
          "result": -2
        },
        {
          "id": "1e35036ed791bd240613da057a8ce444",
          "problem_id": 2,
          "create_time": "18/10/2022 20:36:00.585928+08",
          "result": -1
        },
        {
          "id": "e2304af42d7847db79958d9815307757",
          "problem_id": 20,
          "create_time": "18/10/2022 21:57:57.080691+08",
          "result": -1
        },
        {
          "id": "3255a6ec93deec0bcbae94ce25a5da3f",
          "problem_id": 2,
          "create_time": "18/10/2022 20:04:09.3097+08",
          "result": -1
        },
        {
          "id": "72e82f5ea5cabae2b65c330bb6b0e71d",
          "problem_id": 20,
          "create_time": "18/10/2022 21:38:19.203463+08",
          "result": 4
        },
        {
          "id": "69aeece03b16f82b9e37b4bac8fe2759",
          "problem_id": 19,
          "create_time": "18/10/2022 20:33:14.622913+08",
          "result": -1
        },
        {
          "id": "49358ec22dfce8e4a20032d2ed4e262d",
          "problem_id": 20,
          "create_time": "18/10/2022 20:13:31.155086+08",
          "result": -1
        },
        {
          "id": "f2d48a845a2bf727204d99e79c9713bd",
          "problem_id": 1,
          "create_time": "18/10/2022 20:26:05.36929+08",
          "result": 0
        },
        {
          "id": "8eac3f56d4da6f84065abce6768ae2c1",
          "problem_id": 1,
          "create_time": "18/10/2022 20:24:51.156277+08",
          "result": -2
        },
        {
          "id": "1cb0cf358c151df791a9d819d94902b0",
          "problem_id": 20,
          "create_time": "18/10/2022 20:27:34.937533+08",
          "result": -1
        },
        {
          "id": "5c05b0de0fd2743f84564c940b0593b8",
          "problem_id": 20,
          "create_time": "18/10/2022 21:48:34.41163+08",
          "result": -1
        },
        {
          "id": "5d3a3b645feb74b55800dd218ea3dc38",
          "problem_id": 21,
          "create_time": "18/10/2022 20:46:50.709317+08",
          "result": -1
        },
        {
          "id": "d5f4c3e7afe8b1aff06240e587d4b28d",
          "problem_id": 1,
          "create_time": "18/10/2022 20:50:28.53856+08",
          "result": -1
        },
        {
          "id": "53e061ad11337a6c5bc5cb1244e4663e",
          "problem_id": 20,
          "create_time": "18/10/2022 21:38:57.844532+08",
          "result": 4
        },
        {
          "id": "38965a9bc4ed0a65f0a49b28543eca46",
          "problem_id": 20,
          "create_time": "19/10/2022 11:28:45.075906+08",
          "result": -1
        },
        {
          "id": "d3e28dc21b24a2d9e1527a5275ef4394",
          "problem_id": 19,
          "create_time": "18/10/2022 20:32:37.978885+08",
          "result": -2
        },
        {
          "id": "4c1bdf5fae220c9bfa7b875721eabd10",
          "problem_id": 20,
          "create_time": "18/10/2022 21:48:39.004953+08",
          "result": -1
        },
        {
          "id": "11623a21c63cab81c11994e666043096",
          "problem_id": 20,
          "create_time": "18/10/2022 22:37:28.902211+08",
          "result": -1
        },
        {
          "id": "24f0d60f056ca5c4159954fec4b981a5",
          "problem_id": 1,
          "create_time": "18/10/2022 20:34:04.109879+08",
          "result": -2
        },
        {
          "id": "c214a42ec09abe041cac5a1a683d9196",
          "problem_id": 20,
          "create_time": "18/10/2022 20:42:20.892536+08",
          "result": -1
        },
        {
          "id": "35a47bd507e28877d5b4323331f18c88",
          "problem_id": 20,
          "create_time": "18/10/2022 21:39:35.271442+08",
          "result": -1
        },
        {
          "id": "c7c30ff9d8f1c1657f58ac8a76a178f0",
          "problem_id": 20,
          "create_time": "18/10/2022 21:49:05.504367+08",
          "result": -2
        },
        {
          "id": "df9bf9cb9084977d2f9bd956d67b6b52",
          "problem_id": 20,
          "create_time": "18/10/2022 22:00:23.652473+08",
          "result": -1
        },
        {
          "id": "f06e9926f4a11d3c2b715c3923c18766",
          "problem_id": 20,
          "create_time": "18/10/2022 20:44:25.278054+08",
          "result": -1
        },
        {
          "id": "e0bb93e3beb369bf9dbfced83520c0ce",
          "problem_id": 20,
          "create_time": "19/10/2022 17:35:55.730487+08",
          "result": 4
        },
        {
          "id": "aaecc6e3c2b3896f8968846a4960bcb8",
          "problem_id": 20,
          "create_time": "18/10/2022 21:40:07.19975+08",
          "result": -1
        },
        {
          "id": "1a2e7bc3395585e7de14880d4b2f5b9e",
          "problem_id": 19,
          "create_time": "18/10/2022 21:49:12.393835+08",
          "result": 0
        },
        {
          "id": "18042b74c9287ce1207372d03820166c",
          "problem_id": 20,
          "create_time": "18/10/2022 22:09:42.118728+08",
          "result": -1
        },
        {
          "id": "d57dc57b0af9a4fc9c1ff1a7736f4807",
          "problem_id": 20,
          "create_time": "18/10/2022 20:47:18.804476+08",
          "result": -1
        },
        {
          "id": "8a0dde7f16e3a5746768f004cafe9129",
          "problem_id": 20,
          "create_time": "19/10/2022 17:59:04.394723+08",
          "result": -1
        },
        {
          "id": "ab15bc0611fa482da6caf5e93956744e",
          "problem_id": 20,
          "create_time": "18/10/2022 21:41:05.034396+08",
          "result": -1
        },
        {
          "id": "392d3d015664f56f5bd901f52d0e68b7",
          "problem_id": 2,
          "create_time": "18/10/2022 21:49:41.898023+08",
          "result": -1
        },
        {
          "id": "723cbc3de67f85f33a829fb5c7c1072a",
          "problem_id": 1,
          "create_time": "18/10/2022 22:01:08.996561+08",
          "result": -1
        },
        {
          "id": "105d2ffee7ae2c22a83b8acd6ad334fd",
          "problem_id": 1,
          "create_time": "18/10/2022 20:51:58.147159+08",
          "result": -1
        },
        {
          "id": "d9b86e58989ace0f0e72a3b53be5e369",
          "problem_id": 20,
          "create_time": "18/10/2022 20:56:26.688921+08",
          "result": 0
        },
        {
          "id": "264f207362027b0e833a7a8b8b007484",
          "problem_id": 20,
          "create_time": "18/10/2022 21:41:45.863157+08",
          "result": -1
        },
        {
          "id": "ddd278783a1695055421ba1841cb23a9",
          "problem_id": 20,
          "create_time": "18/10/2022 21:52:32.001418+08",
          "result": -1
        },
        {
          "id": "fc6a5f15377c7e9b0d891c870dc4b43f",
          "problem_id": 21,
          "create_time": "18/10/2022 22:15:10.817464+08",
          "result": -2
        },
        {
          "id": "de7a0575d2a588001f1038adf9c5387e",
          "problem_id": 1,
          "create_time": "18/10/2022 20:52:41.131704+08",
          "result": -1
        },
        {
          "id": "ffc6efa1c4fe7d67aff2e8020650ca54",
          "problem_id": 20,
          "create_time": "19/10/2022 12:28:51.629315+08",
          "result": -1
        },
        {
          "id": "bc915b77576503ce7c44945326e7a96c",
          "problem_id": 2,
          "create_time": "21/10/2022 22:18:28.029221+08",
          "result": -2
        },
        {
          "id": "70abe2edb13255b4b0bf06080e63f7d4",
          "problem_id": 1,
          "create_time": "18/10/2022 21:42:04.716213+08",
          "result": -1
        },
        {
          "id": "a3cf94bdbc760a5c055aa57e4af816a5",
          "problem_id": 20,
          "create_time": "18/10/2022 21:53:01.649752+08",
          "result": -1
        },
        {
          "id": "d27ca49262abd95d1b1a896d2dfaf09d",
          "problem_id": 1,
          "create_time": "18/10/2022 22:01:43.751233+08",
          "result": 0
        },
        {
          "id": "4dbbccb9578bb0be9072814a789deaf8",
          "problem_id": 21,
          "create_time": "18/10/2022 20:52:42.961335+08",
          "result": 4
        },
        {
          "id": "82fca499e74dc79b4d8547cdc81056f0",
          "problem_id": 2,
          "create_time": "18/10/2022 20:52:56.929772+08",
          "result": -1
        },
        {
          "id": "bd48387c9475a42e3f8620ea71a06686",
          "problem_id": 2,
          "create_time": "18/10/2022 20:53:28.125972+08",
          "result": -1
        },
        {
          "id": "3a87f5842cb3b9a03eb96a41c770f9d1",
          "problem_id": 2,
          "create_time": "18/10/2022 20:54:01.792635+08",
          "result": -1
        },
        {
          "id": "f1808955cf60ed6834a88b730de92a75",
          "problem_id": 2,
          "create_time": "21/10/2022 19:10:39.615649+08",
          "result": -1
        },
        {
          "id": "95e4ec2d6dee7852275588f4aa7f1c62",
          "problem_id": 21,
          "create_time": "18/10/2022 20:54:36.010667+08",
          "result": 4
        },
        {
          "id": "8d5393022732ced917e1ab7cba75b194",
          "problem_id": 1,
          "create_time": "18/10/2022 21:42:59.343961+08",
          "result": 0
        },
        {
          "id": "de34e23174a2fd0e8a7d193b0fe394fe",
          "problem_id": 2,
          "create_time": "19/10/2022 11:47:22.864195+08",
          "result": 0
        },
        {
          "id": "d562273966f22db1047706ff5aec8880",
          "problem_id": 20,
          "create_time": "18/10/2022 21:53:18.634451+08",
          "result": -1
        },
        {
          "id": "2831b1aeba38f1633be2087b2962d12f",
          "problem_id": 21,
          "create_time": "18/10/2022 22:15:20.284446+08",
          "result": -1
        },
        {
          "id": "b902fd638f1844e747fcbb012f489742",
          "problem_id": 20,
          "create_time": "18/10/2022 20:54:53.076273+08",
          "result": 1
        },
        {
          "id": "3361505c947cea2a56f79e9bd60d243e",
          "problem_id": 20,
          "create_time": "18/10/2022 20:55:20.385419+08",
          "result": 1
        },
        {
          "id": "d5e36c87c78d491232f9a7fb5768a693",
          "problem_id": 21,
          "create_time": "18/10/2022 20:58:36.079088+08",
          "result": 4
        },
        {
          "id": "b787ca3897a647b83c26201e7ea8938f",
          "problem_id": 21,
          "create_time": "18/10/2022 21:01:42.054941+08",
          "result": -1
        },
        {
          "id": "b1dbe683b5b9736f92f33f5e4cdbfb70",
          "problem_id": 20,
          "create_time": "18/10/2022 22:37:04.280878+08",
          "result": -2
        },
        {
          "id": "2b0fedd0acaebb43d64c6538eb0725ce",
          "problem_id": 21,
          "create_time": "18/10/2022 20:59:25.755251+08",
          "result": -1
        },
        {
          "id": "d4b178b7908ab004f7d2fa0be24f08be",
          "problem_id": 21,
          "create_time": "18/10/2022 21:00:51.167622+08",
          "result": -1
        },
        {
          "id": "dd4abc4f78eb6f38076342ff6f3d0139",
          "problem_id": 20,
          "create_time": "18/10/2022 21:14:00.287008+08",
          "result": -1
        },
        {
          "id": "bd14c5facd026401d431806f5863bd49",
          "problem_id": 20,
          "create_time": "18/10/2022 21:14:08.817196+08",
          "result": -1
        },
        {
          "id": "758318233b6e0624bee1c19cf87b2bd0",
          "problem_id": 21,
          "create_time": "18/10/2022 21:17:52.383473+08",
          "result": -1
        },
        {
          "id": "53a446b2d55822df15b8d6346eac6874",
          "problem_id": 2,
          "create_time": "18/10/2022 21:30:18.765899+08",
          "result": -1
        },
        {
          "id": "e40861024e964f8ede25798ab9140279",
          "problem_id": 2,
          "create_time": "18/10/2022 21:43:29.427391+08",
          "result": -1
        },
        {
          "id": "eb45c3710e45bdd431f5d2369ffd8b0c",
          "problem_id": 21,
          "create_time": "18/10/2022 21:48:29.447335+08",
          "result": -1
        },
        {
          "id": "7e1061ed902e37a6e0874b3f0a373676",
          "problem_id": 20,
          "create_time": "18/10/2022 21:20:03.153295+08",
          "result": -1
        },
        {
          "id": "dd2c4284e65a08f426ab169dec54a78c",
          "problem_id": 20,
          "create_time": "19/10/2022 12:33:50.958468+08",
          "result": -1
        },
        {
          "id": "ae8568e4cbbe04245efb78f3f8df38b2",
          "problem_id": 21,
          "create_time": "18/10/2022 21:56:15.983176+08",
          "result": 0
        },
        {
          "id": "d0c4a47ddac086951f14472418804e30",
          "problem_id": 20,
          "create_time": "19/10/2022 14:27:32.464209+08",
          "result": 0
        },
        {
          "id": "75812bf3b80af6e6a34660dd90a0adfb",
          "problem_id": 21,
          "create_time": "18/10/2022 22:15:28.941716+08",
          "result": -2
        },
        {
          "id": "bcecdb120fff1d1c4917e2a7ecc146c2",
          "problem_id": 20,
          "create_time": "18/10/2022 21:27:13.764874+08",
          "result": -2
        },
        {
          "id": "de00f9453ca60e6f4e8c106b83f6bdca",
          "problem_id": 21,
          "create_time": "19/10/2022 19:19:16.477332+08",
          "result": -1
        },
        {
          "id": "7d46f2f0c3d8e4d5de416506faf3d417",
          "problem_id": 21,
          "create_time": "19/10/2022 12:06:26.858298+08",
          "result": 0
        },
        {
          "id": "823896a6244baeb47e66795adab7474d",
          "problem_id": 20,
          "create_time": "18/10/2022 21:27:30.264368+08",
          "result": -1
        },
        {
          "id": "490af6baae3278487f51a1d167c0da4a",
          "problem_id": 2,
          "create_time": "18/10/2022 21:44:56.517771+08",
          "result": 0
        },
        {
          "id": "e0a69bd506833294c7001c718ca6d5b7",
          "problem_id": 21,
          "create_time": "18/10/2022 21:56:17.15317+08",
          "result": -2
        },
        {
          "id": "b52a4f13585cae6d8fe239fe27f70195",
          "problem_id": 20,
          "create_time": "18/10/2022 21:29:01.406112+08",
          "result": -1
        },
        {
          "id": "dd1ae313e56014211ec3865a718762d5",
          "problem_id": 21,
          "create_time": "19/10/2022 16:55:08.709246+08",
          "result": -1
        },
        {
          "id": "acb3ccfc413b5382335989d70fc00dae",
          "problem_id": 20,
          "create_time": "18/10/2022 21:29:51.822754+08",
          "result": -1
        },
        {
          "id": "9e9deec810853ab9ce28590ea5531248",
          "problem_id": 21,
          "create_time": "18/10/2022 21:44:57.369565+08",
          "result": -2
        },
        {
          "id": "b6f138bf2872996bebe7d10d04e24ec3",
          "problem_id": 21,
          "create_time": "18/10/2022 22:15:58.327532+08",
          "result": -2
        },
        {
          "id": "e91d87e578b41f802e042ec4cb60e30d",
          "problem_id": 20,
          "create_time": "19/10/2022 11:29:17.060068+08",
          "result": -1
        },
        {
          "id": "835c3214f030ff4a87802eb68eaed1c6",
          "problem_id": 19,
          "create_time": "18/10/2022 21:31:36.545426+08",
          "result": -1
        },
        {
          "id": "e6349c0b437434f20692f8cebaff60ea",
          "problem_id": 20,
          "create_time": "18/10/2022 21:44:59.080728+08",
          "result": -1
        },
        {
          "id": "66d6f0f1bda0bd5b3d454e7ce4a9b2fd",
          "problem_id": 21,
          "create_time": "18/10/2022 21:56:28.129847+08",
          "result": -1
        },
        {
          "id": "40ff58e28a6f4a37e21e3e22b8c67913",
          "problem_id": 19,
          "create_time": "18/10/2022 22:04:06.221061+08",
          "result": 0
        },
        {
          "id": "cf5978e6a45e1cff4b627d6f9c748eae",
          "problem_id": 21,
          "create_time": "18/10/2022 22:16:37.65505+08",
          "result": -1
        },
        {
          "id": "1d7bbbfdb91eab8f17ad76d90e8cda01",
          "problem_id": 19,
          "create_time": "18/10/2022 22:27:01.50687+08",
          "result": 0
        },
        {
          "id": "f9e09c6c07acaa892998330793bc0015",
          "problem_id": 20,
          "create_time": "19/10/2022 17:37:46.536883+08",
          "result": 4
        },
        {
          "id": "26aa55e5193fc7cff592f00e0f0cbea2",
          "problem_id": 20,
          "create_time": "18/10/2022 22:28:36.638743+08",
          "result": -1
        },
        {
          "id": "f43a107e9ddc0bc237812571aa3ee95c",
          "problem_id": 20,
          "create_time": "19/10/2022 11:31:10.409845+08",
          "result": -1
        },
        {
          "id": "44ebd4e75029c88257e7106097cb2ced",
          "problem_id": 20,
          "create_time": "19/10/2022 12:33:39.972182+08",
          "result": -1
        },
        {
          "id": "fc46ae88e725aa7cfa9f6cec90071bc1",
          "problem_id": 20,
          "create_time": "18/10/2022 22:29:06.821719+08",
          "result": -1
        },
        {
          "id": "4409c5fc510cc9247f84b8bbd89e67ea",
          "problem_id": 20,
          "create_time": "19/10/2022 12:41:15.674018+08",
          "result": -2
        },
        {
          "id": "4ab8881132aee307173cd1e6d32826b0",
          "problem_id": 20,
          "create_time": "19/10/2022 11:32:51.290294+08",
          "result": -1
        },
        {
          "id": "adc214a9ae4bd843ce68e3344c60baa2",
          "problem_id": 20,
          "create_time": "19/10/2022 18:52:51.090061+08",
          "result": 1
        },
        {
          "id": "96cfbeaf508e681a677a35cc6c3ab984",
          "problem_id": 20,
          "create_time": "19/10/2022 14:33:11.806545+08",
          "result": -1
        },
        {
          "id": "26f865cb9969f082a5764ab733ede8e7",
          "problem_id": 20,
          "create_time": "19/10/2022 14:47:02.601811+08",
          "result": -1
        },
        {
          "id": "91f9362850f96790c2da8b740c3eef3b",
          "problem_id": 20,
          "create_time": "18/10/2022 22:29:53.305169+08",
          "result": -1
        },
        {
          "id": "aa9402797733aea6479f6917551985da",
          "problem_id": 20,
          "create_time": "19/10/2022 14:59:53.173821+08",
          "result": 4
        },
        {
          "id": "29c169b7573f9906e634255d42ac83fc",
          "problem_id": 21,
          "create_time": "18/10/2022 22:36:44.923293+08",
          "result": 4
        },
        {
          "id": "42aa39e2862614fb54246502730668cf",
          "problem_id": 21,
          "create_time": "18/10/2022 22:38:00.903145+08",
          "result": -2
        },
        {
          "id": "58428a4bba42f8a54a27e14eb661bd75",
          "problem_id": 1,
          "create_time": "18/10/2022 22:49:33.183358+08",
          "result": -1
        },
        {
          "id": "940fae7534d9420cf2df4f2c9c2a559c",
          "problem_id": 21,
          "create_time": "18/10/2022 22:37:35.007364+08",
          "result": -2
        },
        {
          "id": "9899e5f55237a2ca0536c8d1329d8097",
          "problem_id": 21,
          "create_time": "19/10/2022 16:59:15.120565+08",
          "result": -1
        },
        {
          "id": "981dd19923f0c04483e62999c14a4e0e",
          "problem_id": 20,
          "create_time": "19/10/2022 17:37:55.520059+08",
          "result": 4
        },
        {
          "id": "9cf83c60e42f264f19f84daf19b99756",
          "problem_id": 20,
          "create_time": "18/10/2022 22:44:41.77107+08",
          "result": -1
        },
        {
          "id": "97188efe9e1d07bd67d90e0e17a5d4dc",
          "problem_id": 1,
          "create_time": "19/10/2022 11:39:44.699634+08",
          "result": -1
        },
        {
          "id": "e80ee9f10ea1e2f1a9c6ec5fdf40c171",
          "problem_id": 21,
          "create_time": "18/10/2022 22:50:57.354052+08",
          "result": -1
        },
        {
          "id": "7de8d06b6d56ca22970fafc609ac1894",
          "problem_id": 20,
          "create_time": "18/10/2022 22:37:47.030678+08",
          "result": -1
        },
        {
          "id": "87dc760d4a081e6becaaa744ba9e1b07",
          "problem_id": 20,
          "create_time": "20/10/2022 00:39:47.03099+08",
          "result": 4
        },
        {
          "id": "2ee1ea9805afe53a7e52b10e37b0dbbf",
          "problem_id": 20,
          "create_time": "18/10/2022 22:39:32.779205+08",
          "result": -1
        },
        {
          "id": "cf1fdee1765003838773e8d56edc8a3f",
          "problem_id": 21,
          "create_time": "18/10/2022 23:05:40.632168+08",
          "result": -1
        },
        {
          "id": "7d4471cd9943969742f7f5c9e10e7ac3",
          "problem_id": 21,
          "create_time": "19/10/2022 00:41:53.924405+08",
          "result": -1
        },
        {
          "id": "3d66aa2bd8cefceb8abf90b148f8b0db",
          "problem_id": 20,
          "create_time": "18/10/2022 22:38:07.293049+08",
          "result": -1
        },
        {
          "id": "b641bfa3365a229492de0a68bd2c74e3",
          "problem_id": 20,
          "create_time": "19/10/2022 18:00:25.265889+08",
          "result": -1
        },
        {
          "id": "e007ec5921419cac1b39070e36f4847e",
          "problem_id": 1,
          "create_time": "18/10/2022 22:50:11.531086+08",
          "result": -1
        },
        {
          "id": "bab306a5572e268a6d682be483386837",
          "problem_id": 19,
          "create_time": "18/10/2022 23:15:12.36969+08",
          "result": -2
        },
        {
          "id": "345a4f92b5b18e3c4acc90f349018d4e",
          "problem_id": 19,
          "create_time": "18/10/2022 23:15:19.364691+08",
          "result": 0
        },
        {
          "id": "f0eaa50831d59cd73f8aa0d0a94c40b5",
          "problem_id": 21,
          "create_time": "18/10/2022 22:40:00.755151+08",
          "result": 1
        },
        {
          "id": "b42f07c8506adbae6bcb9fbd4b83897d",
          "problem_id": 21,
          "create_time": "19/10/2022 12:00:59.346169+08",
          "result": -2
        },
        {
          "id": "37851d917f45e9d8090e1dba23a2d6ee",
          "problem_id": 20,
          "create_time": "19/10/2022 15:00:22.97835+08",
          "result": 4
        },
        {
          "id": "ab5ccd8e909a3eaf2b4bf07accb5cd44",
          "problem_id": 21,
          "create_time": "18/10/2022 22:41:34.096088+08",
          "result": 0
        },
        {
          "id": "1a4326af49b6b9860ad86729f6d546d0",
          "problem_id": 21,
          "create_time": "18/10/2022 22:44:35.035372+08",
          "result": 0
        },
        {
          "id": "f2e780012eba910cdf87933d13f6dce5",
          "problem_id": 2,
          "create_time": "18/10/2022 22:46:36.6439+08",
          "result": -1
        },
        {
          "id": "10ddedbc33c0b878406d7d02c1caad2a",
          "problem_id": 1,
          "create_time": "18/10/2022 22:49:12.772599+08",
          "result": -1
        },
        {
          "id": "d4231dcf7e571216b3eeadfd6a7235e2",
          "problem_id": 21,
          "create_time": "19/10/2022 12:01:06.812729+08",
          "result": -1
        },
        {
          "id": "57799f3da31f893ee0a1775e2ce053d7",
          "problem_id": 20,
          "create_time": "19/10/2022 14:21:40.040402+08",
          "result": -1
        },
        {
          "id": "9deee246c69d1e37f630c391e4944892",
          "problem_id": 20,
          "create_time": "19/10/2022 14:58:40.167235+08",
          "result": -1
        },
        {
          "id": "75c19908e6e20a49ae8d2d45a3e20f4d",
          "problem_id": 20,
          "create_time": "19/10/2022 16:33:11.445925+08",
          "result": -1
        },
        {
          "id": "e9d8ea5dbc5f141162b417d31879d1d3",
          "problem_id": 20,
          "create_time": "18/10/2022 22:50:13.331942+08",
          "result": 0
        },
        {
          "id": "1f6c3047e9e6dbd32d47f2c107643cb2",
          "problem_id": 20,
          "create_time": "18/10/2022 22:50:18.726681+08",
          "result": -1
        },
        {
          "id": "337f765b8845b61c8dd0d1489000dc12",
          "problem_id": 20,
          "create_time": "18/10/2022 22:54:00.233434+08",
          "result": -1
        },
        {
          "id": "6ead1beaa8198d9deec50f16e72f8520",
          "problem_id": 20,
          "create_time": "18/10/2022 22:59:56.053279+08",
          "result": -1
        },
        {
          "id": "acea398d45306be01ba24a207ced0c9a",
          "problem_id": 20,
          "create_time": "18/10/2022 23:03:25.677966+08",
          "result": -1
        },
        {
          "id": "4afdfa87d65417c1c3f8e8183739de0d",
          "problem_id": 21,
          "create_time": "18/10/2022 23:13:19.480603+08",
          "result": 0
        },
        {
          "id": "2de177548a30096bbc85e1de0c801e3d",
          "problem_id": 20,
          "create_time": "19/10/2022 15:05:32.746752+08",
          "result": -1
        },
        {
          "id": "ec38f3bcbf1bfe999f7ef1d3a0f9f754",
          "problem_id": 20,
          "create_time": "18/10/2022 23:04:41.76757+08",
          "result": -1
        },
        {
          "id": "5c1fef5d5318f742ee783e922ef4df08",
          "problem_id": 20,
          "create_time": "18/10/2022 23:07:03.797551+08",
          "result": -1
        },
        {
          "id": "1ea81a1682bc31b0007a973fba465ec4",
          "problem_id": 21,
          "create_time": "18/10/2022 23:08:55.326045+08",
          "result": -1
        },
        {
          "id": "25051d27a9d988e6535102bc7db84f43",
          "problem_id": 1,
          "create_time": "18/10/2022 23:09:32.06968+08",
          "result": 0
        },
        {
          "id": "119f7a6e07f12b560d77c0d8e894a45f",
          "problem_id": 21,
          "create_time": "19/10/2022 10:30:47.259225+08",
          "result": 0
        },
        {
          "id": "16f29119b71301d471292773ef11e707",
          "problem_id": 20,
          "create_time": "18/10/2022 23:07:34.395542+08",
          "result": -1
        },
        {
          "id": "1f6d014eca7adce5a5fb117c87ea91e3",
          "problem_id": 21,
          "create_time": "20/10/2022 11:16:38.752063+08",
          "result": -1
        },
        {
          "id": "fcdba38c05cbc3a14fcb7ae4ed312e94",
          "problem_id": 20,
          "create_time": "18/10/2022 23:14:55.579208+08",
          "result": -1
        },
        {
          "id": "a6cab6db1be1cd17502b78368af6eeaa",
          "problem_id": 20,
          "create_time": "19/10/2022 12:41:42.144992+08",
          "result": -1
        },
        {
          "id": "49500d0c021fbcfdfa942b3fe52e7cf2",
          "problem_id": 21,
          "create_time": "19/10/2022 22:52:05.863971+08",
          "result": -2
        },
        {
          "id": "7fde7e8c151316d6b71809fde76b475e",
          "problem_id": 20,
          "create_time": "18/10/2022 23:19:20.555458+08",
          "result": -1
        },
        {
          "id": "24f7a8779c1f1a525a4fb429fae7c2cc",
          "problem_id": 20,
          "create_time": "19/10/2022 12:06:52.636708+08",
          "result": 0
        },
        {
          "id": "3cdd77f184c01ced4c8ffa553bb81d4e",
          "problem_id": 20,
          "create_time": "19/10/2022 18:06:50.199433+08",
          "result": 1
        },
        {
          "id": "ec3508b860d0f45e25af7f16f6bc2234",
          "problem_id": 21,
          "create_time": "18/10/2022 23:23:15.211274+08",
          "result": -1
        },
        {
          "id": "9ee0674815ca1e8c8cab4d54dc314cf9",
          "problem_id": 21,
          "create_time": "18/10/2022 23:24:01.666051+08",
          "result": -1
        },
        {
          "id": "90b5abe3b24397f79fc941eb6a79b87b",
          "problem_id": 20,
          "create_time": "21/10/2022 23:47:18.953639+08",
          "result": -2
        },
        {
          "id": "267e46159d0bbd97758f48f369d0f927",
          "problem_id": 21,
          "create_time": "18/10/2022 23:26:00.680212+08",
          "result": -2
        },
        {
          "id": "28d052c1a4cfc48f6bccd2fed23755d9",
          "problem_id": 19,
          "create_time": "19/10/2022 12:08:50.296912+08",
          "result": -2
        },
        {
          "id": "c0e20a327a7725b1585d610196a0b159",
          "problem_id": 20,
          "create_time": "19/10/2022 12:43:35.673389+08",
          "result": -1
        },
        {
          "id": "ece37c595878c31470f9cac94d272b3a",
          "problem_id": 21,
          "create_time": "18/10/2022 23:27:00.017852+08",
          "result": 0
        },
        {
          "id": "fc76afb62878a770de3c3cedf54a5521",
          "problem_id": 21,
          "create_time": "19/10/2022 00:43:49.96344+08",
          "result": -1
        },
        {
          "id": "cec4dd36b3f233db041312f300ddc1f5",
          "problem_id": 20,
          "create_time": "19/10/2022 17:38:05.762413+08",
          "result": 4
        },
        {
          "id": "b18fa2d103e168242121aa80255f51b5",
          "problem_id": 19,
          "create_time": "19/10/2022 12:09:42.290987+08",
          "result": -1
        },
        {
          "id": "3bd9984e9eaa30cf663ba93b358f545e",
          "problem_id": 20,
          "create_time": "19/10/2022 14:17:01.273949+08",
          "result": -1
        },
        {
          "id": "167d560a3251eb3ca4ae024aa85e1289",
          "problem_id": 21,
          "create_time": "19/10/2022 14:23:42.647008+08",
          "result": 0
        },
        {
          "id": "5ef42cafd68e451e3c60dbaec6330957",
          "problem_id": 20,
          "create_time": "19/10/2022 14:34:47.737626+08",
          "result": -1
        },
        {
          "id": "2d8183fe1914f5645d6f3e5b0249bb47",
          "problem_id": 21,
          "create_time": "19/10/2022 00:44:45.512686+08",
          "result": 0
        },
        {
          "id": "a6df6f932f309cf8ec8ebcac7c0128ce",
          "problem_id": 20,
          "create_time": "19/10/2022 09:36:22.911138+08",
          "result": -1
        },
        {
          "id": "8a1c9e395504d0e415c9f913fb83ea65",
          "problem_id": 20,
          "create_time": "19/10/2022 12:10:04.526048+08",
          "result": -1
        },
        {
          "id": "92507480dcf0452b38243a00b0c683de",
          "problem_id": 20,
          "create_time": "19/10/2022 18:53:28.742567+08",
          "result": 4
        },
        {
          "id": "38591b4f44f9d4ff82684f09a423c10d",
          "problem_id": 20,
          "create_time": "19/10/2022 14:17:12.813727+08",
          "result": -1
        },
        {
          "id": "ed9a95c80e5c7e6f1b72abdd884d38e9",
          "problem_id": 20,
          "create_time": "19/10/2022 14:58:22.784017+08",
          "result": -1
        },
        {
          "id": "e20ea468304e891de91334f041d956c5",
          "problem_id": 21,
          "create_time": "19/10/2022 09:44:24.730418+08",
          "result": -1
        },
        {
          "id": "b5ac85cce191d929c815c249e926c7f0",
          "problem_id": 21,
          "create_time": "19/10/2022 10:15:02.632603+08",
          "result": 4
        },
        {
          "id": "698d24c68f2794de1309ce0d4197c5ce",
          "problem_id": 19,
          "create_time": "19/10/2022 12:11:48.277337+08",
          "result": 0
        },
        {
          "id": "9de6f22faa3f53dd53fb0dfea484c013",
          "problem_id": 2,
          "create_time": "19/10/2022 21:10:18.731896+08",
          "result": -1
        },
        {
          "id": "1141c7a1d68d5ad3d39f152c03e8c817",
          "problem_id": 20,
          "create_time": "19/10/2022 14:18:20.687434+08",
          "result": -1
        },
        {
          "id": "e10ed72d2939be60a387baca1156701d",
          "problem_id": 20,
          "create_time": "19/10/2022 14:25:06.049349+08",
          "result": -1
        },
        {
          "id": "40e998bf266a922bbf6eb3a9c84e08a5",
          "problem_id": 20,
          "create_time": "19/10/2022 14:45:46.366651+08",
          "result": -1
        },
        {
          "id": "154d0a32719a8e6b2dcd04a78f02dd7e",
          "problem_id": 21,
          "create_time": "19/10/2022 10:00:57.01374+08",
          "result": 4
        },
        {
          "id": "ba55ff5fed04606d9eb1c96df5dc388d",
          "problem_id": 20,
          "create_time": "19/10/2022 17:39:15.626667+08",
          "result": 4
        },
        {
          "id": "8826fa58d39db857aefe4424dcf8e167",
          "problem_id": 20,
          "create_time": "19/10/2022 12:16:23.808247+08",
          "result": -1
        },
        {
          "id": "8672f62593cfb61d1c0ee68c22eb14ae",
          "problem_id": 20,
          "create_time": "19/10/2022 14:25:30.145636+08",
          "result": -1
        },
        {
          "id": "df2248b56c95cb6d1fe0c85efd0e4052",
          "problem_id": 1,
          "create_time": "19/10/2022 15:22:05.931964+08",
          "result": -1
        },
        {
          "id": "eed2c5fa6992d8185b47612d08fd8755",
          "problem_id": 20,
          "create_time": "19/10/2022 16:29:58.331426+08",
          "result": -1
        },
        {
          "id": "3ee17ae8e1b5536ba9accd487adb459d",
          "problem_id": 21,
          "create_time": "19/10/2022 10:01:56.103205+08",
          "result": 4
        },
        {
          "id": "1423e6f13dee6bd4b3e5c35fca6a956b",
          "problem_id": 21,
          "create_time": "19/10/2022 10:02:06.420164+08",
          "result": 4
        },
        {
          "id": "5e41d750c3e1ad19766bc58c2539d801",
          "problem_id": 21,
          "create_time": "19/10/2022 10:11:09.264402+08",
          "result": 4
        },
        {
          "id": "ee32cf53f53fd5b01a52c3c5c4721afd",
          "problem_id": 20,
          "create_time": "19/10/2022 12:18:33.28615+08",
          "result": -1
        },
        {
          "id": "fc15c7589cdf0ed0fb0a5066b70cc8ae",
          "problem_id": 21,
          "create_time": "19/10/2022 15:22:58.717097+08",
          "result": -1
        },
        {
          "id": "7740f15d079ac03b38717ce6a0a24d39",
          "problem_id": 21,
          "create_time": "19/10/2022 16:53:18.978557+08",
          "result": -1
        },
        {
          "id": "caa1f9a7baecb6ebb94c1ffe99a2c298",
          "problem_id": 20,
          "create_time": "19/10/2022 15:20:25.576781+08",
          "result": 4
        },
        {
          "id": "4e103029f8d98c432dc76138ae3df798",
          "problem_id": 21,
          "create_time": "19/10/2022 10:02:53.021495+08",
          "result": 4
        },
        {
          "id": "496ff318aef91a263fe0097da2956ec6",
          "problem_id": 19,
          "create_time": "20/10/2022 10:24:38.884541+08",
          "result": 1
        },
        {
          "id": "38365ed5129fa05c0cc4079cdc6ea5b8",
          "problem_id": 20,
          "create_time": "19/10/2022 10:31:03.597364+08",
          "result": -1
        },
        {
          "id": "9b2be3cfccbb56d4f430ef1749a8d1a1",
          "problem_id": 20,
          "create_time": "19/10/2022 12:19:37.339435+08",
          "result": -1
        },
        {
          "id": "3c6c504b0c07995e3c08cdf9a934d2da",
          "problem_id": 20,
          "create_time": "19/10/2022 14:21:29.108181+08",
          "result": -1
        },
        {
          "id": "81a82fdcb9a265390b27547fb49a0e3f",
          "problem_id": 20,
          "create_time": "19/10/2022 14:21:49.642096+08",
          "result": -1
        },
        {
          "id": "c8f82e11346172b23fd0bd0c208c00c6",
          "problem_id": 2,
          "create_time": "19/10/2022 21:10:37.512987+08",
          "result": -1
        },
        {
          "id": "f255d98040a8ad3c78f81bbfcddf452e",
          "problem_id": 20,
          "create_time": "19/10/2022 17:41:00.588222+08",
          "result": -1
        },
        {
          "id": "dfe5f81de4f652489ffc431bf17de875",
          "problem_id": 20,
          "create_time": "19/10/2022 18:14:14.755255+08",
          "result": -1
        },
        {
          "id": "b869be9e26481ae090ca5412542eb126",
          "problem_id": 20,
          "create_time": "19/10/2022 15:01:33.741569+08",
          "result": -1
        },
        {
          "id": "bba6c09eabb211c6773b4891f3b08430",
          "problem_id": 20,
          "create_time": "19/10/2022 15:01:41.563288+08",
          "result": 4
        },
        {
          "id": "59ffb35e88d42877fc80b87c5dd74645",
          "problem_id": 21,
          "create_time": "19/10/2022 15:23:49.820691+08",
          "result": -2
        },
        {
          "id": "7eb585578c943ad492a9f5b8e195533c",
          "problem_id": 19,
          "create_time": "20/10/2022 11:19:32.449082+08",
          "result": 0
        },
        {
          "id": "ed39a17f7eabbfcb7443d21927a15505",
          "problem_id": 20,
          "create_time": "19/10/2022 15:01:52.381582+08",
          "result": 4
        },
        {
          "id": "25cf4c1c858c8b002e60abdf523d4d8e",
          "problem_id": 20,
          "create_time": "19/10/2022 18:53:42.024744+08",
          "result": 0
        },
        {
          "id": "890265d83c64adc9093777c234545c95",
          "problem_id": 20,
          "create_time": "19/10/2022 18:59:16.799528+08",
          "result": 0
        },
        {
          "id": "bf90ed38109afa9d580117de5911f57e",
          "problem_id": 20,
          "create_time": "19/10/2022 19:25:08.854283+08",
          "result": 4
        },
        {
          "id": "68a34bf34874fdb076c03f4dbb2c3f90",
          "problem_id": 21,
          "create_time": "19/10/2022 15:22:34.230571+08",
          "result": -2
        },
        {
          "id": "16c06a6ea2124b5f95eaf6c02dee9947",
          "problem_id": 20,
          "create_time": "19/10/2022 17:41:01.926894+08",
          "result": 4
        },
        {
          "id": "5abc75e82483c06176d65899b4e399f4",
          "problem_id": 21,
          "create_time": "19/10/2022 15:23:14.450025+08",
          "result": -2
        },
        {
          "id": "937119c505391b4fc0ae8995807c3836",
          "problem_id": 19,
          "create_time": "20/10/2022 10:26:04.342025+08",
          "result": 0
        },
        {
          "id": "dede55ba2e1f76f0e2ef3b9db5c9a23d",
          "problem_id": 21,
          "create_time": "19/10/2022 15:23:43.401588+08",
          "result": -2
        },
        {
          "id": "d23eb2188c0989b9bfc99189cdc8fa9a",
          "problem_id": 20,
          "create_time": "19/10/2022 17:41:30.808447+08",
          "result": 4
        },
        {
          "id": "bd53ff0606fcffb187507acfb173ace1",
          "problem_id": 20,
          "create_time": "19/10/2022 15:24:15.315056+08",
          "result": -2
        },
        {
          "id": "30630b4130681ac291104c5517b85da3",
          "problem_id": 20,
          "create_time": "19/10/2022 15:24:36.220802+08",
          "result": -1
        },
        {
          "id": "9a7dd3359cb8c0664ae18db3592e9fa3",
          "problem_id": 20,
          "create_time": "19/10/2022 17:42:19.176674+08",
          "result": 4
        },
        {
          "id": "ce5aca4645d7c9a1b737bf1946df40aa",
          "problem_id": 21,
          "create_time": "19/10/2022 15:25:00.242812+08",
          "result": -1
        },
        {
          "id": "75522fb3a8403bbcf1a1aa52038aa1cb",
          "problem_id": 20,
          "create_time": "19/10/2022 18:19:33.942567+08",
          "result": -1
        },
        {
          "id": "9405c82c716918a9c960bc8341c616e6",
          "problem_id": 20,
          "create_time": "19/10/2022 15:32:25.729966+08",
          "result": -1
        },
        {
          "id": "464544c5b280e158f7a5e84a8c810ff4",
          "problem_id": 20,
          "create_time": "19/10/2022 18:55:26.231787+08",
          "result": 4
        },
        {
          "id": "7f00cc48cc9561fbe8e5fb54e6b0900b",
          "problem_id": 2,
          "create_time": "20/10/2022 11:20:32.457753+08",
          "result": 4
        },
        {
          "id": "bb77bfc0a4a276de238cb75b42e2fe6d",
          "problem_id": 21,
          "create_time": "19/10/2022 15:25:45.006491+08",
          "result": -1
        },
        {
          "id": "7f44b27bdf43ddfece7c0ee1501e34ae",
          "problem_id": 20,
          "create_time": "19/10/2022 15:31:23.851312+08",
          "result": -2
        },
        {
          "id": "943db58d5f7aea967de05a154f1dfbe1",
          "problem_id": 20,
          "create_time": "19/10/2022 15:31:27.734983+08",
          "result": -2
        },
        {
          "id": "b433a7e9a2b6cf1e68e8a40935717794",
          "problem_id": 20,
          "create_time": "19/10/2022 19:02:41.458378+08",
          "result": -1
        },
        {
          "id": "5b86c43f8d8fbfc5d8d0226115efe620",
          "problem_id": 20,
          "create_time": "19/10/2022 15:31:38.860327+08",
          "result": -1
        },
        {
          "id": "53e354093f1c7337a7cd7feb13c45318",
          "problem_id": 20,
          "create_time": "19/10/2022 17:42:32.700569+08",
          "result": -1
        },
        {
          "id": "def7a68fbc2e50393b60087fcf486175",
          "problem_id": 20,
          "create_time": "19/10/2022 18:28:05.376041+08",
          "result": 4
        },
        {
          "id": "d6aa9d770081d261bed84dec528d1a45",
          "problem_id": 20,
          "create_time": "19/10/2022 18:39:35.116896+08",
          "result": -1
        },
        {
          "id": "632d64a0ebdc0ba3760ce32282ca67be",
          "problem_id": 20,
          "create_time": "19/10/2022 16:36:52.612566+08",
          "result": -1
        },
        {
          "id": "2a1a12240aee53598bdfd87fde6b392d",
          "problem_id": 20,
          "create_time": "19/10/2022 18:55:54.655828+08",
          "result": -1
        },
        {
          "id": "4b554c7c967b5b32b4a27c05f2ca8896",
          "problem_id": 1,
          "create_time": "19/10/2022 16:53:14.409573+08",
          "result": -1
        },
        {
          "id": "bd6eaf44baabd5e361367966f387b295",
          "problem_id": 20,
          "create_time": "19/10/2022 16:55:16.4635+08",
          "result": -1
        },
        {
          "id": "8afe770547450277a992deea8655ac88",
          "problem_id": 20,
          "create_time": "19/10/2022 18:56:13.436967+08",
          "result": 4
        },
        {
          "id": "4d350862eb8c6823bf1c855c106a113c",
          "problem_id": 20,
          "create_time": "19/10/2022 18:59:34.052135+08",
          "result": -1
        },
        {
          "id": "333e5c0114281c0f79c35f1e6ec6bffe",
          "problem_id": 20,
          "create_time": "19/10/2022 17:42:54.8569+08",
          "result": 4
        },
        {
          "id": "5b2a8b3014280b49805740e0f514b722",
          "problem_id": 20,
          "create_time": "19/10/2022 16:56:40.219243+08",
          "result": -1
        },
        {
          "id": "aa0ae7c95bdf6d78a5f1c6b717adb232",
          "problem_id": 20,
          "create_time": "19/10/2022 16:58:33.059297+08",
          "result": -1
        },
        {
          "id": "83f54eef339cbe0a6d5581841b2d2aa5",
          "problem_id": 20,
          "create_time": "19/10/2022 17:07:33.566261+08",
          "result": 4
        },
        {
          "id": "93331008ff01490f2f5da90be9e8c4d1",
          "problem_id": 20,
          "create_time": "19/10/2022 17:12:27.884483+08",
          "result": -1
        },
        {
          "id": "2bd2c45eea2b24d52d38f1efb41be22d",
          "problem_id": 2,
          "create_time": "20/10/2022 11:20:52.319125+08",
          "result": 4
        },
        {
          "id": "59bbeaf947090c67d556b26c8e91ee87",
          "problem_id": 20,
          "create_time": "19/10/2022 17:11:01.115551+08",
          "result": -1
        },
        {
          "id": "98fdb995302bc4ed93411e60614cf590",
          "problem_id": 20,
          "create_time": "19/10/2022 17:45:31.955473+08",
          "result": -1
        },
        {
          "id": "c4a48a4be8a0c09f6c6f30d092cf0c1e",
          "problem_id": 20,
          "create_time": "19/10/2022 17:13:09.211427+08",
          "result": -1
        },
        {
          "id": "68819b45c4339d83f42a9a67a528af66",
          "problem_id": 20,
          "create_time": "19/10/2022 17:13:12.92785+08",
          "result": -1
        },
        {
          "id": "cd3c3012e1ba3fc78a0ab7bb5816118e",
          "problem_id": 20,
          "create_time": "19/10/2022 17:22:47.514706+08",
          "result": -1
        },
        {
          "id": "1973120de6077d0c29992d4c8f3ca19a",
          "problem_id": 20,
          "create_time": "19/10/2022 17:13:26.155206+08",
          "result": -2
        },
        {
          "id": "27fc968908dc025b956e7bfd8f934555",
          "problem_id": 20,
          "create_time": "19/10/2022 17:13:58.551111+08",
          "result": 4
        },
        {
          "id": "a879490a0777d01a009f486400d8005d",
          "problem_id": 20,
          "create_time": "19/10/2022 18:28:40.342955+08",
          "result": 4
        },
        {
          "id": "1f0ed0e2c4adb2aab6ec7efd65530830",
          "problem_id": 20,
          "create_time": "19/10/2022 17:16:20.124888+08",
          "result": -1
        },
        {
          "id": "a67d42471d53595a692929c7ad6d3fa3",
          "problem_id": 20,
          "create_time": "19/10/2022 17:32:31.966466+08",
          "result": 4
        },
        {
          "id": "810d4b7306df26f141517f76491c3d73",
          "problem_id": 20,
          "create_time": "19/10/2022 17:47:07.373392+08",
          "result": 4
        },
        {
          "id": "cd960785b3cffd6fd4155003bfa0aee5",
          "problem_id": 20,
          "create_time": "19/10/2022 17:16:34.256935+08",
          "result": -1
        },
        {
          "id": "8e0fb0aa0842212ef0481ac645369efe",
          "problem_id": 20,
          "create_time": "19/10/2022 17:18:28.348774+08",
          "result": -1
        },
        {
          "id": "2b7196a8d9cb8bdcf9b7b7dd7f09edae",
          "problem_id": 20,
          "create_time": "19/10/2022 19:25:47.816912+08",
          "result": 4
        },
        {
          "id": "b3609f2766c1c435eb307c63d0240c75",
          "problem_id": 20,
          "create_time": "19/10/2022 17:20:12.474857+08",
          "result": -1
        },
        {
          "id": "4dcceb800a0561eb0d7adf8bd26378fe",
          "problem_id": 20,
          "create_time": "19/10/2022 17:32:55.614066+08",
          "result": 4
        },
        {
          "id": "9ebc0d6b620c98b29e2c4dae74e79424",
          "problem_id": 20,
          "create_time": "19/10/2022 17:23:48.770494+08",
          "result": -1
        },
        {
          "id": "5d21d20f11b16697e83e849dba1dd637",
          "problem_id": 20,
          "create_time": "19/10/2022 17:47:40.179939+08",
          "result": 4
        },
        {
          "id": "7adea6511edfd02b34c4797d57cde4ff",
          "problem_id": 20,
          "create_time": "19/10/2022 17:25:06.832668+08",
          "result": -1
        },
        {
          "id": "cbd2965ba3840b18aa40b4b2a486b467",
          "problem_id": 21,
          "create_time": "20/10/2022 11:21:17.301369+08",
          "result": -1
        },
        {
          "id": "d07bf59e715643f7e39ed889608d6960",
          "problem_id": 21,
          "create_time": "19/10/2022 19:43:10.022377+08",
          "result": 0
        },
        {
          "id": "f406266189c68df3cae685fa4636c4d8",
          "problem_id": 20,
          "create_time": "20/10/2022 00:41:32.405803+08",
          "result": 4
        },
        {
          "id": "a6418b42fb8d4ad8ea33d0dfcfad432f",
          "problem_id": 20,
          "create_time": "19/10/2022 17:25:23.667613+08",
          "result": -1
        },
        {
          "id": "c80d1b46ac2857a99e9081866db9ede7",
          "problem_id": 20,
          "create_time": "19/10/2022 17:47:58.372973+08",
          "result": 4
        },
        {
          "id": "170705d98df2e77869cc156bbc036ea7",
          "problem_id": 20,
          "create_time": "19/10/2022 17:26:17.302026+08",
          "result": -1
        },
        {
          "id": "ade1ea2349b762c27d019e787d16b108",
          "problem_id": 20,
          "create_time": "19/10/2022 19:02:54.087485+08",
          "result": 0
        },
        {
          "id": "ee905dfd5e2be7fc3225f23f5f6d0230",
          "problem_id": 20,
          "create_time": "19/10/2022 17:26:21.077569+08",
          "result": -1
        },
        {
          "id": "b4e840f3bde202b62257e6cfbcb0bd37",
          "problem_id": 20,
          "create_time": "19/10/2022 17:26:31.549923+08",
          "result": -1
        },
        {
          "id": "32d7ca883fc5cde9a916aa2762368d3a",
          "problem_id": 21,
          "create_time": "19/10/2022 19:56:13.193373+08",
          "result": -1
        },
        {
          "id": "f9457f18db7812be411053e1b4d5f287",
          "problem_id": 20,
          "create_time": "19/10/2022 17:51:44.528278+08",
          "result": -2
        },
        {
          "id": "d640232e755ba8e0e4e7e05114477e72",
          "problem_id": 20,
          "create_time": "19/10/2022 17:26:35.861984+08",
          "result": -1
        },
        {
          "id": "2f65966e0f3a160edebc60363dd3259c",
          "problem_id": 21,
          "create_time": "19/10/2022 19:46:03.524034+08",
          "result": -1
        },
        {
          "id": "71b8811ad3d9b7aac5c10e1a6e0ec420",
          "problem_id": 21,
          "create_time": "19/10/2022 20:21:23.184034+08",
          "result": -1
        },
        {
          "id": "f6c3ba5133fff99cf17d1207384cb186",
          "problem_id": 2,
          "create_time": "19/10/2022 21:11:30.709504+08",
          "result": -1
        },
        {
          "id": "cda371893cb2e32f0e7f4ec4776fd5c0",
          "problem_id": 20,
          "create_time": "19/10/2022 17:26:39.808007+08",
          "result": -1
        },
        {
          "id": "d5f2898a08e50e7a7215d538893b0112",
          "problem_id": 20,
          "create_time": "19/10/2022 17:52:46.074269+08",
          "result": 4
        },
        {
          "id": "b416500e050bd6cdd844f9162fa0bcb8",
          "problem_id": 1,
          "create_time": "20/10/2022 10:32:15.463608+08",
          "result": 0
        },
        {
          "id": "d99a32c5d540db1732bb39e13fa7a6a0",
          "problem_id": 20,
          "create_time": "19/10/2022 18:29:06.600409+08",
          "result": 4
        },
        {
          "id": "22eb9bbbebc94e32f8b5633e15c97fc1",
          "problem_id": 20,
          "create_time": "19/10/2022 19:01:24.562796+08",
          "result": 0
        },
        {
          "id": "89f5d49016706f1f9085869b7d8459f4",
          "problem_id": 20,
          "create_time": "19/10/2022 17:26:55.36657+08",
          "result": -1
        },
        {
          "id": "36d30eacf30ecae98c663996288294ec",
          "problem_id": 2,
          "create_time": "21/10/2022 19:11:38.950956+08",
          "result": 1
        },
        {
          "id": "acf9232c3d084a0fc9de908c085cb2d9",
          "problem_id": 21,
          "create_time": "19/10/2022 17:53:04.332417+08",
          "result": -1
        },
        {
          "id": "5064fd9766be7525b6ece3425a66b1f1",
          "problem_id": 20,
          "create_time": "19/10/2022 17:26:46.496766+08",
          "result": -1
        },
        {
          "id": "f2e394c9e58031e608aa7abfc25a3d12",
          "problem_id": 20,
          "create_time": "19/10/2022 17:26:44.752932+08",
          "result": -1
        },
        {
          "id": "928dd4209c17f6cfccd51852e7a112c2",
          "problem_id": 20,
          "create_time": "19/10/2022 18:56:39.597828+08",
          "result": -1
        },
        {
          "id": "bdcd0057e58196ad2e9a21d80228b3d0",
          "problem_id": 20,
          "create_time": "19/10/2022 19:06:50.871008+08",
          "result": 0
        },
        {
          "id": "c2283fd2b014f7f706bd49b13ec0700f",
          "problem_id": 20,
          "create_time": "19/10/2022 17:53:57.888137+08",
          "result": -1
        },
        {
          "id": "bc3c773b88d638af686a42c084fd4507",
          "problem_id": 20,
          "create_time": "19/10/2022 18:29:55.391128+08",
          "result": 4
        },
        {
          "id": "a05cd68bf2450856945559c5df8832bb",
          "problem_id": 20,
          "create_time": "19/10/2022 17:27:12.159854+08",
          "result": -1
        },
        {
          "id": "b55c9be66239d77422d29aa53de8aeca",
          "problem_id": 2,
          "create_time": "19/10/2022 21:13:17.730459+08",
          "result": -1
        },
        {
          "id": "3d441613a71451711c3e18ba3b436920",
          "problem_id": 20,
          "create_time": "19/10/2022 17:27:24.421365+08",
          "result": -1
        },
        {
          "id": "bf5d4072ff4c206bcc969008e5d1e49f",
          "problem_id": 21,
          "create_time": "19/10/2022 17:54:17.807039+08",
          "result": -1
        },
        {
          "id": "b6fb135e07f8a0eceaacce5f0ee01939",
          "problem_id": 20,
          "create_time": "19/10/2022 17:28:51.544524+08",
          "result": -1
        },
        {
          "id": "618978d5cfaaddc744bd9eea92dff277",
          "problem_id": 20,
          "create_time": "19/10/2022 18:57:19.295983+08",
          "result": -1
        },
        {
          "id": "978dd0311f2294df6ae8ee06515ccc50",
          "problem_id": 20,
          "create_time": "19/10/2022 17:29:08.896848+08",
          "result": -1
        },
        {
          "id": "2852510ceed2ef48b246902a9ed18189",
          "problem_id": 2,
          "create_time": "19/10/2022 21:13:43.181362+08",
          "result": -1
        },
        {
          "id": "a0e637bbf77dcefea1ea009b2139bc4b",
          "problem_id": 20,
          "create_time": "20/10/2022 00:43:43.639868+08",
          "result": 1
        },
        {
          "id": "1c1b5c373e5a1d535f006fb063c8169e",
          "problem_id": 21,
          "create_time": "19/10/2022 17:54:36.092854+08",
          "result": -1
        },
        {
          "id": "a2d880855d57f0856d07d240ef81d037",
          "problem_id": 20,
          "create_time": "19/10/2022 17:31:33.252673+08",
          "result": -1
        },
        {
          "id": "5eb2c9fd1c51c5e3d90d4ed83696e243",
          "problem_id": 20,
          "create_time": "19/10/2022 17:34:38.392865+08",
          "result": -2
        },
        {
          "id": "40d84dc213ded026f113a76549616076",
          "problem_id": 20,
          "create_time": "19/10/2022 20:24:51.072295+08",
          "result": -1
        },
        {
          "id": "c36d534861fb54c696e030cbbf178b4d",
          "problem_id": 20,
          "create_time": "19/10/2022 17:33:39.806572+08",
          "result": -1
        },
        {
          "id": "489586e2fe24c7dc96859e7be0ad5d6e",
          "problem_id": 20,
          "create_time": "19/10/2022 17:54:55.46292+08",
          "result": -1
        },
        {
          "id": "fc7d81c78834f1f7f54f664001bd7806",
          "problem_id": 20,
          "create_time": "19/10/2022 17:34:23.520878+08",
          "result": -1
        },
        {
          "id": "87a036e3b3c857cbe64d10eb813adeb7",
          "problem_id": 20,
          "create_time": "19/10/2022 18:57:26.826917+08",
          "result": -1
        },
        {
          "id": "3a6311f5595e38ceb179cd864ed02387",
          "problem_id": 20,
          "create_time": "19/10/2022 18:57:34.167314+08",
          "result": -2
        },
        {
          "id": "8c1db0c569b4af675a240105209a6c39",
          "problem_id": 2,
          "create_time": "19/10/2022 21:18:53.806433+08",
          "result": 0
        },
        {
          "id": "1699f4d560a10992810fd74bbe53a530",
          "problem_id": 20,
          "create_time": "19/10/2022 17:35:16.875127+08",
          "result": 4
        },
        {
          "id": "c66dcbd9a762d83a628427843e2a7ba2",
          "problem_id": 1,
          "create_time": "20/10/2022 10:26:54.288941+08",
          "result": -1
        },
        {
          "id": "1586708133ea2cea7c63e93e5be9101e",
          "problem_id": 21,
          "create_time": "19/10/2022 17:56:37.597018+08",
          "result": -1
        },
        {
          "id": "9dd22a63515c23f33676fbbab7bd2ad3",
          "problem_id": 20,
          "create_time": "19/10/2022 18:43:13.228682+08",
          "result": -1
        },
        {
          "id": "f0bb3e38425a327822290dbcaa1b91ea",
          "problem_id": 20,
          "create_time": "19/10/2022 19:01:54.855939+08",
          "result": 0
        },
        {
          "id": "62c5b3078cf5ed841f1ca410e29c0c4b",
          "problem_id": 20,
          "create_time": "19/10/2022 17:35:39.763298+08",
          "result": 4
        },
        {
          "id": "78554cd79e04a22f6d55f2b6f66a980b",
          "problem_id": 2,
          "create_time": "20/10/2022 11:21:25.207427+08",
          "result": 4
        },
        {
          "id": "5699e2c051c87591a64cec94a4db7b07",
          "problem_id": 21,
          "create_time": "19/10/2022 17:57:59.658671+08",
          "result": 0
        },
        {
          "id": "8ba4d02ec360bbeef24f6f667f0f5677",
          "problem_id": 20,
          "create_time": "19/10/2022 18:43:38.421032+08",
          "result": -1
        },
        {
          "id": "62e2e881532ebb5889d2843ff5e46f9d",
          "problem_id": 20,
          "create_time": "19/10/2022 18:58:04.389949+08",
          "result": -1
        },
        {
          "id": "9826bbff609541e35a79b2ab612b0dc8",
          "problem_id": 20,
          "create_time": "19/10/2022 19:02:02.896639+08",
          "result": -1
        },
        {
          "id": "53d097d7a7befe24725d26f2e52c6d21",
          "problem_id": 19,
          "create_time": "20/10/2022 10:34:19.934346+08",
          "result": 1
        },
        {
          "id": "e4a7e2f573a6971cffec06cc27b5e367",
          "problem_id": 21,
          "create_time": "19/10/2022 20:00:16.322582+08",
          "result": -1
        },
        {
          "id": "a4a57c613090e38aa894cbebeba20f8e",
          "problem_id": 21,
          "create_time": "20/10/2022 11:38:30.964847+08",
          "result": -1
        },
        {
          "id": "1b3ab02f5f96796e67b162cec1854914",
          "problem_id": 20,
          "create_time": "19/10/2022 20:31:13.77732+08",
          "result": -2
        },
        {
          "id": "36c43bd9dedde7f8b40f23e399d520c9",
          "problem_id": 20,
          "create_time": "19/10/2022 20:31:23.742627+08",
          "result": -1
        },
        {
          "id": "b15938bd9fbac213dba5d356e422cbd7",
          "problem_id": 20,
          "create_time": "19/10/2022 23:37:10.685125+08",
          "result": 4
        },
        {
          "id": "23faca023dfa5f083678c8c6380e6a85",
          "problem_id": 20,
          "create_time": "19/10/2022 20:31:26.716523+08",
          "result": -1
        },
        {
          "id": "ff2105684c54e011d56333c0edcb8ebd",
          "problem_id": 20,
          "create_time": "20/10/2022 00:44:08.942384+08",
          "result": 1
        },
        {
          "id": "c72345fadbf22136e8b894fbacac6fb3",
          "problem_id": 2,
          "create_time": "19/10/2022 20:37:08.061789+08",
          "result": 1
        },
        {
          "id": "afac04cbc89527b076ae5f4b475e3d0a",
          "problem_id": 1,
          "create_time": "19/10/2022 21:16:17.916668+08",
          "result": 0
        },
        {
          "id": "c278a9996cf75f2ecb5774fd2f51e8a9",
          "problem_id": 21,
          "create_time": "19/10/2022 22:52:30.50159+08",
          "result": -2
        },
        {
          "id": "d96e14c6f448baab97458ccb4a7e9575",
          "problem_id": 20,
          "create_time": "19/10/2022 20:34:54.573564+08",
          "result": 0
        },
        {
          "id": "2656af9327ddc91b85852db1fb399160",
          "problem_id": 20,
          "create_time": "19/10/2022 20:38:57.911733+08",
          "result": 0
        },
        {
          "id": "f1a39b9f0fd8adc8a6af9765f0e18595",
          "problem_id": 20,
          "create_time": "19/10/2022 23:03:52.085559+08",
          "result": -1
        },
        {
          "id": "8e0260155594b2c6cacfd865828ed77a",
          "problem_id": 21,
          "create_time": "20/10/2022 11:24:39.246474+08",
          "result": -1
        },
        {
          "id": "1b3ca878c835e1b275c0dc50e9f99037",
          "problem_id": 20,
          "create_time": "19/10/2022 20:40:57.492785+08",
          "result": 0
        },
        {
          "id": "30b5da5dd8cce15fc3d6e24d1877aece",
          "problem_id": 2,
          "create_time": "19/10/2022 21:10:30.64607+08",
          "result": -2
        },
        {
          "id": "1afd7c12e54631689746b9eb3379dd31",
          "problem_id": 21,
          "create_time": "20/10/2022 11:38:44.600016+08",
          "result": -1
        },
        {
          "id": "9c67b0ce2d516e62e4bcfca9eba75b21",
          "problem_id": 21,
          "create_time": "19/10/2022 21:34:43.997782+08",
          "result": -2
        },
        {
          "id": "c3f0fbef2fac4f7d0aa144c7b81745c1",
          "problem_id": 21,
          "create_time": "19/10/2022 21:35:35.142289+08",
          "result": 0
        },
        {
          "id": "bddd609ff0ffb07d04eea339fb0c4ef1",
          "problem_id": 21,
          "create_time": "21/10/2022 23:48:04.592163+08",
          "result": 0
        },
        {
          "id": "704aa535b1065ed497c3b7ccf557e142",
          "problem_id": 21,
          "create_time": "19/10/2022 22:52:16.638251+08",
          "result": -2
        },
        {
          "id": "60df87170ce03b7c7aadc7c91680bf4f",
          "problem_id": 20,
          "create_time": "20/10/2022 00:44:30.647708+08",
          "result": 1
        },
        {
          "id": "f2f861252cfc859a02e7dde13181126f",
          "problem_id": 1,
          "create_time": "21/10/2022 20:17:41.279673+08",
          "result": 0
        },
        {
          "id": "2d69448a8be7a632ded70e97fbad0866",
          "problem_id": 1,
          "create_time": "20/10/2022 10:28:26.9557+08",
          "result": -1
        },
        {
          "id": "52a8b6a447b5987910be0249278530b6",
          "problem_id": 21,
          "create_time": "20/10/2022 11:41:56.327864+08",
          "result": 0
        },
        {
          "id": "c120b886d6dcc3abf3f7517024ccfd4c",
          "problem_id": 21,
          "create_time": "19/10/2022 22:54:38.008339+08",
          "result": -1
        },
        {
          "id": "a2f7fe24d1fe2a35a6c5ecabd60f1154",
          "problem_id": 20,
          "create_time": "19/10/2022 22:56:03.697795+08",
          "result": 4
        },
        {
          "id": "a018caa1d4c88075ae6f91bb6d0319d9",
          "problem_id": 21,
          "create_time": "19/10/2022 22:56:20.929353+08",
          "result": -1
        },
        {
          "id": "d75890d7a62638b29aa00a873dd66933",
          "problem_id": 21,
          "create_time": "20/10/2022 04:37:59.004218+08",
          "result": -1
        },
        {
          "id": "1fc6c0e6a56f40188e444eb821de1430",
          "problem_id": 19,
          "create_time": "20/10/2022 10:34:42.499881+08",
          "result": 1
        },
        {
          "id": "4991ebc81c7aa6fb376b896bfecf209f",
          "problem_id": 21,
          "create_time": "19/10/2022 22:56:48.930017+08",
          "result": -1
        },
        {
          "id": "48197d1a2cc0c6bee416f63b7653ad68",
          "problem_id": 21,
          "create_time": "19/10/2022 22:58:43.870729+08",
          "result": -1
        },
        {
          "id": "42220b4435475ea7735659e9a860b8b7",
          "problem_id": 21,
          "create_time": "19/10/2022 22:59:45.480115+08",
          "result": -1
        },
        {
          "id": "b803580fa8a7eefa4271a61d549558f9",
          "problem_id": 20,
          "create_time": "20/10/2022 18:05:28.328816+08",
          "result": -1
        },
        {
          "id": "3455815c8222035289549fa9de4caa11",
          "problem_id": 1,
          "create_time": "19/10/2022 23:01:28.128603+08",
          "result": -1
        },
        {
          "id": "d046725c0c275a93524854202331b958",
          "problem_id": 21,
          "create_time": "19/10/2022 23:01:07.259276+08",
          "result": -1
        },
        {
          "id": "c0d1124ffd85db62a83cb72f6e13765e",
          "problem_id": 21,
          "create_time": "20/10/2022 04:40:32.390816+08",
          "result": 0
        },
        {
          "id": "4737b11518f936ef454cc43aeb4cd5a3",
          "problem_id": 2,
          "create_time": "19/10/2022 23:02:19.062374+08",
          "result": -2
        },
        {
          "id": "6ce1e62c465cda9c6cc5debdc72f80fd",
          "problem_id": 20,
          "create_time": "20/10/2022 10:50:07.42399+08",
          "result": -1
        },
        {
          "id": "a129cf68c84489b17605d877f25272df",
          "problem_id": 21,
          "create_time": "20/10/2022 11:34:34.698134+08",
          "result": -1
        },
        {
          "id": "ec8a3aa573e372d9f5bb79d4625a9ee4",
          "problem_id": 21,
          "create_time": "19/10/2022 23:02:18.306854+08",
          "result": -1
        },
        {
          "id": "12be329dfda40336095e31815a94aca2",
          "problem_id": 21,
          "create_time": "22/10/2022 00:58:31.455108+08",
          "result": 0
        },
        {
          "id": "7d2999a0422ac6577ec47992cd1333f1",
          "problem_id": 2,
          "create_time": "19/10/2022 23:03:15.494945+08",
          "result": -2
        },
        {
          "id": "d0d26205e404aaf0cf10dfbcbf2b6715",
          "problem_id": 20,
          "create_time": "20/10/2022 09:43:57.624085+08",
          "result": -1
        },
        {
          "id": "44b251d82a7e82f5f88440eead8875e8",
          "problem_id": 20,
          "create_time": "20/10/2022 11:25:57.936729+08",
          "result": 1
        },
        {
          "id": "ad8ca32976e314b796f99e2f4ef17953",
          "problem_id": 21,
          "create_time": "19/10/2022 23:04:17.222296+08",
          "result": -1
        },
        {
          "id": "9435fa46bb78089ab6d93b90b72960cd",
          "problem_id": 19,
          "create_time": "20/10/2022 10:30:03.077393+08",
          "result": -1
        },
        {
          "id": "78b287fbd2b44861de8afeea14a51944",
          "problem_id": 21,
          "create_time": "19/10/2022 23:10:31.644087+08",
          "result": -1
        },
        {
          "id": "a06e5c941a280b5c8d98b464b1d27883",
          "problem_id": 21,
          "create_time": "19/10/2022 23:15:24.114613+08",
          "result": -1
        },
        {
          "id": "ccbba7c8bbfcf130d97a6e2ca354e385",
          "problem_id": 21,
          "create_time": "19/10/2022 23:18:22.784483+08",
          "result": 0
        },
        {
          "id": "4f45c48d703ea3ae446281b353c684b4",
          "problem_id": 1,
          "create_time": "21/10/2022 19:13:16.704523+08",
          "result": -1
        },
        {
          "id": "cc8f066bbbd8c1ac8caea55860d3913e",
          "problem_id": 20,
          "create_time": "20/10/2022 09:48:02.954471+08",
          "result": -1
        },
        {
          "id": "6d6d9ff50cbbac5cd923ae8aa86f04f9",
          "problem_id": 20,
          "create_time": "19/10/2022 23:19:16.905973+08",
          "result": 4
        },
        {
          "id": "3680179979e2facd260471b7d6f203af",
          "problem_id": 20,
          "create_time": "19/10/2022 23:20:41.775641+08",
          "result": 4
        },
        {
          "id": "c1f3956394c193f291cb4023ee6d3d49",
          "problem_id": 20,
          "create_time": "20/10/2022 09:52:41.963539+08",
          "result": -2
        },
        {
          "id": "ec0a33dfaebd92744aff1e2da3fedfb9",
          "problem_id": 19,
          "create_time": "20/10/2022 10:42:06.210845+08",
          "result": 0
        },
        {
          "id": "4ff860a47e7426b330019e5a5635dd5a",
          "problem_id": 20,
          "create_time": "20/10/2022 10:52:40.549973+08",
          "result": 1
        },
        {
          "id": "f748e6f34520ff2c8b01eb90349f4591",
          "problem_id": 20,
          "create_time": "19/10/2022 23:27:03.360229+08",
          "result": -2
        },
        {
          "id": "2fb22d81b6a5c44f771cf39c85b8c377",
          "problem_id": 20,
          "create_time": "20/10/2022 09:53:11.786412+08",
          "result": 4
        },
        {
          "id": "2ce9a0490b114d0300f51584f11e21bf",
          "problem_id": 20,
          "create_time": "20/10/2022 11:26:45.681943+08",
          "result": 4
        },
        {
          "id": "d046f869b8d5c91df10a4da0eac86b6c",
          "problem_id": 20,
          "create_time": "22/10/2022 02:38:06.277548+08",
          "result": -1
        },
        {
          "id": "d6f7a68e3d1329fb27a6372db8cb849e",
          "problem_id": 20,
          "create_time": "20/10/2022 13:52:23.750085+08",
          "result": -1
        },
        {
          "id": "ae91f103bf4199e7d673e4ba02aba06a",
          "problem_id": 20,
          "create_time": "19/10/2022 23:27:32.008308+08",
          "result": 4
        },
        {
          "id": "e33687c9b2d8edb2ca103bffd5ba088e",
          "problem_id": 20,
          "create_time": "19/10/2022 23:32:01.730677+08",
          "result": 4
        },
        {
          "id": "e56e2c0d99d9331f9e1433befdc6ec80",
          "problem_id": 20,
          "create_time": "20/10/2022 10:11:24.371804+08",
          "result": -1
        },
        {
          "id": "445018fc671f8d67e98dedf84c9040d0",
          "problem_id": 1,
          "create_time": "20/10/2022 10:30:51.7199+08",
          "result": -1
        },
        {
          "id": "8977285a85c2e3194177bcde18ea1e42",
          "problem_id": 20,
          "create_time": "20/10/2022 00:30:09.431769+08",
          "result": -2
        },
        {
          "id": "59f8f96ac5def49c52bb37cca300970b",
          "problem_id": 20,
          "create_time": "20/10/2022 00:30:21.287009+08",
          "result": 4
        },
        {
          "id": "afd55fbe9926d16fc7945ae9c71f0a00",
          "problem_id": 20,
          "create_time": "20/10/2022 00:30:44.510494+08",
          "result": 1
        },
        {
          "id": "ad5e2768bb6820096329564d675b5c65",
          "problem_id": 2,
          "create_time": "21/10/2022 19:14:20.08301+08",
          "result": 1
        },
        {
          "id": "dfb516ab4b74eb3049ff959f3e5eb8ca",
          "problem_id": 20,
          "create_time": "20/10/2022 00:31:08.134841+08",
          "result": 1
        },
        {
          "id": "8834b7bfb942c21e9346d2f27d0bd6ac",
          "problem_id": 20,
          "create_time": "20/10/2022 00:31:33.686824+08",
          "result": 4
        },
        {
          "id": "50d2bd832d9dfe8155fa8953470d9551",
          "problem_id": 20,
          "create_time": "20/10/2022 00:32:09.117358+08",
          "result": 1
        },
        {
          "id": "a35151db059ff1b4aaf157d4cc5df758",
          "problem_id": 20,
          "create_time": "20/10/2022 00:34:01.356891+08",
          "result": 1
        },
        {
          "id": "5eb01f35921d5c8ac2f172ae5028eb67",
          "problem_id": 21,
          "create_time": "20/10/2022 10:15:58.584572+08",
          "result": 0
        },
        {
          "id": "ab74893661579ca69b05abe11cff34ab",
          "problem_id": 20,
          "create_time": "20/10/2022 00:34:21.958296+08",
          "result": 4
        },
        {
          "id": "6e0c9869e7a6bc0dde03d959ea261653",
          "problem_id": 1,
          "create_time": "20/10/2022 10:31:28.277821+08",
          "result": -1
        },
        {
          "id": "814360ac5671b69f192a6e2cc4901e78",
          "problem_id": 20,
          "create_time": "20/10/2022 00:34:33.792324+08",
          "result": 4
        },
        {
          "id": "e384d60e6651700620ebb103219eae0b",
          "problem_id": 2,
          "create_time": "20/10/2022 11:15:07.607459+08",
          "result": -2
        },
        {
          "id": "7f5d6d019588272d95ba9ef70210cc99",
          "problem_id": 20,
          "create_time": "20/10/2022 10:53:03.564976+08",
          "result": 4
        },
        {
          "id": "12e06200acb3b75e6fb0adda518db59b",
          "problem_id": 1,
          "create_time": "21/10/2022 20:19:46.079125+08",
          "result": -1
        },
        {
          "id": "fe266a7e6b58af5702a6a3352134e835",
          "problem_id": 20,
          "create_time": "20/10/2022 10:53:25.489439+08",
          "result": 4
        },
        {
          "id": "b839b789bc309bb781ad47cb71edc4f6",
          "problem_id": 21,
          "create_time": "20/10/2022 11:15:20.616108+08",
          "result": -1
        },
        {
          "id": "3cf327e2c5a7fc49b2f8e62c398726ec",
          "problem_id": 19,
          "create_time": "20/10/2022 11:16:51.279143+08",
          "result": -1
        },
        {
          "id": "735d6453b4915fea07926ca347c8a93a",
          "problem_id": 2,
          "create_time": "21/10/2022 19:17:31.823721+08",
          "result": 1
        },
        {
          "id": "f6c9c361ce00801897dace41e6bd6c65",
          "problem_id": 20,
          "create_time": "20/10/2022 10:54:33.612126+08",
          "result": 4
        },
        {
          "id": "1186c200763f02ae3304152dfd2c3df6",
          "problem_id": 1,
          "create_time": "21/10/2022 20:28:56.263332+08",
          "result": -2
        },
        {
          "id": "7e9f5369fdc909c2975d4b52f6ae97c5",
          "problem_id": 20,
          "create_time": "21/10/2022 23:47:30.183464+08",
          "result": 0
        },
        {
          "id": "efb754a31f8ba20fc1388eecbb81b382",
          "problem_id": 19,
          "create_time": "20/10/2022 11:17:14.267866+08",
          "result": -1
        },
        {
          "id": "ac862191f4c0a355550890543c28777e",
          "problem_id": 1,
          "create_time": "21/10/2022 19:22:02.471161+08",
          "result": -1
        },
        {
          "id": "3f9c95a98f69e6018fabb327a130985f",
          "problem_id": 1,
          "create_time": "21/10/2022 20:29:17.117873+08",
          "result": -2
        },
        {
          "id": "ab918742724e595c7f78b7d0f169d60c",
          "problem_id": 21,
          "create_time": "21/10/2022 23:47:40.215923+08",
          "result": -1
        },
        {
          "id": "e29b92d4b4ec842fd8407c2c3e7b4d82",
          "problem_id": 19,
          "create_time": "20/10/2022 11:18:42.837279+08",
          "result": -2
        },
        {
          "id": "fcd982acb1c4069c5cb0f534d9075c08",
          "problem_id": 20,
          "create_time": "20/10/2022 11:25:31.416187+08",
          "result": 4
        },
        {
          "id": "876ca266a04629c0e1d91c101d90a711",
          "problem_id": 20,
          "create_time": "20/10/2022 11:27:12.096187+08",
          "result": 4
        },
        {
          "id": "3eb3fba2cca5a0cc123e0bbf78b8c4aa",
          "problem_id": 20,
          "create_time": "20/10/2022 11:28:25.396516+08",
          "result": 1
        },
        {
          "id": "731014b0cd369f5c85fcbacea9615d5a",
          "problem_id": 21,
          "create_time": "20/10/2022 14:28:27.676586+08",
          "result": -1
        },
        {
          "id": "fb2334c23656632a5b63e5b343313f2e",
          "problem_id": 20,
          "create_time": "20/10/2022 11:26:14.32623+08",
          "result": 1
        },
        {
          "id": "720c4cafa848b37806ef9f62e482dc5f",
          "problem_id": 20,
          "create_time": "20/10/2022 11:27:23.62796+08",
          "result": 4
        },
        {
          "id": "2edd464f7daf1f36ce4f1f91cb27abad",
          "problem_id": 20,
          "create_time": "20/10/2022 11:27:51.760954+08",
          "result": 4
        },
        {
          "id": "c65ed9b57d3d585ea72a18bd8a4659cf",
          "problem_id": 1,
          "create_time": "20/10/2022 19:02:37.81152+08",
          "result": 0
        },
        {
          "id": "660a9b301e7ca7f5ca3ddcde27ced0b3",
          "problem_id": 20,
          "create_time": "20/10/2022 22:25:07.02801+08",
          "result": -1
        },
        {
          "id": "d7670293fdbd5a2644af00404ce6ebec",
          "problem_id": 20,
          "create_time": "20/10/2022 11:27:35.487541+08",
          "result": 1
        },
        {
          "id": "84c55d915fcea435a7025a970ce93794",
          "problem_id": 20,
          "create_time": "20/10/2022 11:29:03.007192+08",
          "result": 4
        },
        {
          "id": "98ac0f53bcb985a4301835a60ab71479",
          "problem_id": 21,
          "create_time": "20/10/2022 11:38:12.145699+08",
          "result": -1
        },
        {
          "id": "1b430f5569165bea3885c4aaacff06e5",
          "problem_id": 1,
          "create_time": "21/10/2022 19:23:07.931267+08",
          "result": -1
        },
        {
          "id": "c08ec4e2dc15d47aaf15b0e6a4caec50",
          "problem_id": 20,
          "create_time": "20/10/2022 11:28:08.151952+08",
          "result": 1
        },
        {
          "id": "faa129ee81803333c1a65f75a4314bfc",
          "problem_id": 20,
          "create_time": "20/10/2022 11:28:51.871996+08",
          "result": 1
        },
        {
          "id": "51aec2ec7ca4068da92b6b789f714b9b",
          "problem_id": 21,
          "create_time": "20/10/2022 14:25:51.588268+08",
          "result": 4
        },
        {
          "id": "5a64aa91f9451cdf9e761879e7ce6f58",
          "problem_id": 1,
          "create_time": "21/10/2022 20:29:33.158439+08",
          "result": -1
        },
        {
          "id": "3a22bce6766ab305d86b4321b7c78b2a",
          "problem_id": 2,
          "create_time": "21/10/2022 20:17:53.596397+08",
          "result": 0
        },
        {
          "id": "8adef5d712f2deffd44534d9fba6bcb8",
          "problem_id": 20,
          "create_time": "20/10/2022 11:29:16.086111+08",
          "result": 4
        },
        {
          "id": "11bc6395c3857fbd22e1cb5701004bbe",
          "problem_id": 1,
          "create_time": "21/10/2022 19:23:21.181847+08",
          "result": -1
        },
        {
          "id": "6a668afcb9b72f14690ac671132b4760",
          "problem_id": 20,
          "create_time": "20/10/2022 11:30:49.765703+08",
          "result": 4
        },
        {
          "id": "fee84f61e2e8df17af8cae099580c781",
          "problem_id": 1,
          "create_time": "20/10/2022 11:45:56.966457+08",
          "result": -1
        },
        {
          "id": "494194a20cb740805677c2476df3af7b",
          "problem_id": 1,
          "create_time": "20/10/2022 11:46:38.415806+08",
          "result": -1
        },
        {
          "id": "a6b7c4a40278defe22672c7eccb7fa89",
          "problem_id": 20,
          "create_time": "20/10/2022 11:29:42.231523+08",
          "result": -2
        },
        {
          "id": "f2334e7896ca27ac11b838af5b705851",
          "problem_id": 20,
          "create_time": "20/10/2022 11:29:53.546774+08",
          "result": -2
        },
        {
          "id": "dd59ba5f517b88598680004d86cc485b",
          "problem_id": 1,
          "create_time": "21/10/2022 19:24:25.545239+08",
          "result": -1
        },
        {
          "id": "c668cfb5571bc13657ba6a38135e770f",
          "problem_id": 20,
          "create_time": "21/10/2022 23:50:21.881607+08",
          "result": -1
        },
        {
          "id": "7be731edcec09ac137599093c61d14d7",
          "problem_id": 20,
          "create_time": "20/10/2022 11:30:04.045973+08",
          "result": 4
        },
        {
          "id": "a157cd543044bff17eaff87e0db6954a",
          "problem_id": 20,
          "create_time": "20/10/2022 14:20:20.662103+08",
          "result": 4
        },
        {
          "id": "33c433e2d828c8e6acd5019270988572",
          "problem_id": 2,
          "create_time": "21/10/2022 19:28:04.401169+08",
          "result": -1
        },
        {
          "id": "1c676ae33fe9f19d510750b6092729e9",
          "problem_id": 21,
          "create_time": "21/10/2022 23:51:42.086769+08",
          "result": 0
        },
        {
          "id": "acacfd29fc656f645dfe665fcbf58acb",
          "problem_id": 20,
          "create_time": "20/10/2022 11:30:25.368857+08",
          "result": 4
        },
        {
          "id": "b0fdcc2b2fc854e7a176a0e1f09ef547",
          "problem_id": 20,
          "create_time": "20/10/2022 11:35:12.760298+08",
          "result": 4
        },
        {
          "id": "5e3d74176d5efc16121c74ac0cd82128",
          "problem_id": 1,
          "create_time": "20/10/2022 11:55:06.79998+08",
          "result": -1
        },
        {
          "id": "5debfcf2c021c947fb69494d6b78ee62",
          "problem_id": 2,
          "create_time": "21/10/2022 20:37:46.511999+08",
          "result": 0
        },
        {
          "id": "6cfe5073faee01c35193b483c9530c4f",
          "problem_id": 21,
          "create_time": "22/10/2022 00:02:39.598119+08",
          "result": -2
        },
        {
          "id": "a2138752e51f5fde46eccd68c025d63a",
          "problem_id": 1,
          "create_time": "21/10/2022 19:29:32.53368+08",
          "result": 0
        },
        {
          "id": "f84eed6468620d85e9969022524af5af",
          "problem_id": 20,
          "create_time": "20/10/2022 11:35:31.015595+08",
          "result": 4
        },
        {
          "id": "4bd7dfb4772c17854db0a0d7b17bd329",
          "problem_id": 20,
          "create_time": "20/10/2022 11:35:52.094553+08",
          "result": 1
        },
        {
          "id": "5f9268396fe6b2eb973d0682e82ea902",
          "problem_id": 19,
          "create_time": "20/10/2022 12:01:44.419796+08",
          "result": -1
        },
        {
          "id": "71fa9a6294ce6ba2dfa52b9cc60ddea1",
          "problem_id": 20,
          "create_time": "20/10/2022 11:36:19.807237+08",
          "result": 1
        },
        {
          "id": "d486a77b0860fb2e213d570c2f417296",
          "problem_id": 2,
          "create_time": "21/10/2022 19:30:18.201193+08",
          "result": -1
        },
        {
          "id": "d9b193fd3f376e556d92e47b8054e32e",
          "problem_id": 20,
          "create_time": "20/10/2022 11:36:35.681028+08",
          "result": 4
        },
        {
          "id": "1b355cc37c9cc1bd62acb9f58c8a71a9",
          "problem_id": 19,
          "create_time": "20/10/2022 12:02:15.927737+08",
          "result": 0
        },
        {
          "id": "88a0ddcd43ac8d3aaa58d1418637412f",
          "problem_id": 20,
          "create_time": "20/10/2022 11:38:44.365731+08",
          "result": 1
        },
        {
          "id": "ab83b0f6963c8513ceedae640da95cbf",
          "problem_id": 2,
          "create_time": "21/10/2022 19:31:14.48311+08",
          "result": 0
        },
        {
          "id": "fc949ff534c529cc991cece0be10c65d",
          "problem_id": 21,
          "create_time": "22/10/2022 00:02:59.452853+08",
          "result": -2
        },
        {
          "id": "7c84ed3d713cf2c3b5e572bd6e061ab1",
          "problem_id": 20,
          "create_time": "22/10/2022 00:59:40.337+08",
          "result": -1
        },
        {
          "id": "9e3c8a79f1b0b5941dfa4ea2db49e061",
          "problem_id": 21,
          "create_time": "22/10/2022 01:46:49.750703+08",
          "result": 0
        },
        {
          "id": "d3ade83c81e6a5ee12ac6843d48f954b",
          "problem_id": 1,
          "create_time": "20/10/2022 11:52:30.734603+08",
          "result": -1
        },
        {
          "id": "f5aa59dcf31491d8f6c39c3ef4fc935c",
          "problem_id": 1,
          "create_time": "20/10/2022 12:17:41.464272+08",
          "result": -1
        },
        {
          "id": "dd2c67b76d7af87176e05101f69aedc5",
          "problem_id": 20,
          "create_time": "20/10/2022 12:19:08.855054+08",
          "result": -1
        },
        {
          "id": "8512c5ddf690763397ac5562ab1efb71",
          "problem_id": 21,
          "create_time": "22/10/2022 00:08:02.393613+08",
          "result": -1
        },
        {
          "id": "603a24d6de9dbc3212f07d04113c1dba",
          "problem_id": 1,
          "create_time": "20/10/2022 14:31:09.347447+08",
          "result": 0
        },
        {
          "id": "8fca41555c1edb2fde7283d36836440a",
          "problem_id": 2,
          "create_time": "21/10/2022 19:52:38.172144+08",
          "result": -1
        },
        {
          "id": "e23fe3a8012a916da947771264a0fa20",
          "problem_id": 20,
          "create_time": "20/10/2022 12:29:39.464483+08",
          "result": -1
        },
        {
          "id": "310211f33da3514b8294aeabb1952405",
          "problem_id": 1,
          "create_time": "21/10/2022 19:56:27.160927+08",
          "result": 0
        },
        {
          "id": "f883535663374a3ad7ef9653ab8b183a",
          "problem_id": 2,
          "create_time": "21/10/2022 20:02:32.387294+08",
          "result": 0
        },
        {
          "id": "c2cf6a9ac50ba39653f6594ee474e8b0",
          "problem_id": 20,
          "create_time": "20/10/2022 13:50:51.1279+08",
          "result": -1
        },
        {
          "id": "6b8d231a179b17e33f2aa9197edd0898",
          "problem_id": 2,
          "create_time": "21/10/2022 19:52:59.663692+08",
          "result": 0
        },
        {
          "id": "efdc037c92536512feec38cd3e488d39",
          "problem_id": 2,
          "create_time": "21/10/2022 20:38:16.035424+08",
          "result": 4
        },
        {
          "id": "800fe3e9c1ac7c717086b9c05016920a",
          "problem_id": 1,
          "create_time": "21/10/2022 20:50:26.190377+08",
          "result": -1
        },
        {
          "id": "2842318734e17ddaf41d760377f536cd",
          "problem_id": 1,
          "create_time": "21/10/2022 21:43:17.679423+08",
          "result": 0
        },
        {
          "id": "46eaaee155e25cc011f5810769bdd436",
          "problem_id": 1,
          "create_time": "20/10/2022 14:30:59.733539+08",
          "result": -2
        },
        {
          "id": "d041828b248d4ada1e557a9d14e5b818",
          "problem_id": 21,
          "create_time": "20/10/2022 15:28:52.379151+08",
          "result": 0
        },
        {
          "id": "d5cbdfce415a64b057fe6c816846002a",
          "problem_id": 20,
          "create_time": "20/10/2022 18:05:12.592838+08",
          "result": -2
        },
        {
          "id": "6180cc9df265a1ed5405a2a59927188e",
          "problem_id": 19,
          "create_time": "20/10/2022 22:04:25.529988+08",
          "result": 0
        },
        {
          "id": "b1df5a66213c9faf19b999cd647dfa8c",
          "problem_id": 2,
          "create_time": "21/10/2022 20:38:38.063195+08",
          "result": -1
        },
        {
          "id": "b277fd16c806228208007b13728a71d7",
          "problem_id": 20,
          "create_time": "22/10/2022 02:33:22.143456+08",
          "result": 0
        },
        {
          "id": "c829a0207510e94c6778182966049cd2",
          "problem_id": 1,
          "create_time": "20/10/2022 14:35:12.004557+08",
          "result": -1
        },
        {
          "id": "369d93c3fff08614f1c4142d898d31bb",
          "problem_id": 21,
          "create_time": "20/10/2022 14:37:43.004245+08",
          "result": -1
        },
        {
          "id": "78b763d8faf1b423a25dad9f23fe3de1",
          "problem_id": 21,
          "create_time": "20/10/2022 23:02:25.882272+08",
          "result": -1
        },
        {
          "id": "977b8765ffd4e584203464360f3de001",
          "problem_id": 1,
          "create_time": "20/10/2022 14:38:11.862963+08",
          "result": -1
        },
        {
          "id": "811e8a4ae5fb3a3da6c924bb9118f2e0",
          "problem_id": 1,
          "create_time": "20/10/2022 14:42:09.523218+08",
          "result": -1
        },
        {
          "id": "3092804a47b6b4dcb52ebab462293864",
          "problem_id": 21,
          "create_time": "20/10/2022 14:55:26.160029+08",
          "result": 4
        },
        {
          "id": "5f22614c3feb8e336aa78699fcc9f629",
          "problem_id": 2,
          "create_time": "20/10/2022 20:28:16.408975+08",
          "result": -1
        },
        {
          "id": "cbb137ffdc10b778536a3289e1b50a7c",
          "problem_id": 20,
          "create_time": "20/10/2022 17:01:29.077776+08",
          "result": -1
        },
        {
          "id": "5e7bb0614942ccb5576b666a002b2bdf",
          "problem_id": 21,
          "create_time": "22/10/2022 00:10:41.845921+08",
          "result": 4
        },
        {
          "id": "dad49b69767f5a71b530bfe9e1a9b961",
          "problem_id": 1,
          "create_time": "21/10/2022 19:57:49.999506+08",
          "result": 0
        },
        {
          "id": "4fe43100a889ac30b1fbb6bbb001ed90",
          "problem_id": 20,
          "create_time": "20/10/2022 17:05:15.600766+08",
          "result": -1
        },
        {
          "id": "cd4ce6b3cba378625d52870fba183df2",
          "problem_id": 1,
          "create_time": "20/10/2022 18:58:28.12255+08",
          "result": 0
        },
        {
          "id": "616c4b6a5e4bb50fc80f284436070850",
          "problem_id": 20,
          "create_time": "20/10/2022 17:12:55.388535+08",
          "result": -1
        },
        {
          "id": "e9bd57ac09e96e4b4ab31266448234d1",
          "problem_id": 20,
          "create_time": "20/10/2022 17:14:22.780467+08",
          "result": 1
        },
        {
          "id": "92ad3bc42bcb709053f1c7cc35532a36",
          "problem_id": 20,
          "create_time": "20/10/2022 17:40:36.455275+08",
          "result": 0
        },
        {
          "id": "b36a856f9626dd1dabf7f5f21b824661",
          "problem_id": 20,
          "create_time": "20/10/2022 18:50:59.309099+08",
          "result": -1
        },
        {
          "id": "d3476f2bf070db2a3585d840e4487ddf",
          "problem_id": 2,
          "create_time": "20/10/2022 20:27:01.126286+08",
          "result": -1
        },
        {
          "id": "e560dd3bcae75f91b2675a25c8bb0eae",
          "problem_id": 20,
          "create_time": "20/10/2022 18:09:10.520022+08",
          "result": -1
        },
        {
          "id": "a668f714c2c90e5b0780e6344fcba769",
          "problem_id": 20,
          "create_time": "20/10/2022 19:07:42.299866+08",
          "result": -1
        },
        {
          "id": "cb68f9b6c96acb9b90fe00199d2cb8f9",
          "problem_id": 2,
          "create_time": "21/10/2022 19:01:48.434862+08",
          "result": 1
        },
        {
          "id": "abc18a2bc2ae773e6401fdeef1cbf3d3",
          "problem_id": 2,
          "create_time": "21/10/2022 20:47:47.309251+08",
          "result": 0
        },
        {
          "id": "b8c898cf53fe74710a3f5e51d134f9b3",
          "problem_id": 21,
          "create_time": "22/10/2022 00:11:08.39491+08",
          "result": 0
        },
        {
          "id": "3d4e2eae24c561cf9ba9f8960395b895",
          "problem_id": 21,
          "create_time": "20/10/2022 19:47:51.705295+08",
          "result": 4
        },
        {
          "id": "b5b9d63e9b018a818aca877d6721e436",
          "problem_id": 1,
          "create_time": "21/10/2022 19:57:56.394945+08",
          "result": 0
        },
        {
          "id": "d9d3903447c76aaed152750d5c943a32",
          "problem_id": 21,
          "create_time": "20/10/2022 22:03:06.469953+08",
          "result": -1
        },
        {
          "id": "a203af22a70b377dbb217568737a970b",
          "problem_id": 1,
          "create_time": "21/10/2022 20:45:00.814454+08",
          "result": -1
        },
        {
          "id": "e90e9d2dbb36f836031c04619591166f",
          "problem_id": 20,
          "create_time": "22/10/2022 00:13:12.112907+08",
          "result": -1
        },
        {
          "id": "968529bbee9da9fd2f4b629edb2cc770",
          "problem_id": 1,
          "create_time": "20/10/2022 20:25:59.982276+08",
          "result": -1
        },
        {
          "id": "be0657bcbac75aa19981af0bc6b7b3ab",
          "problem_id": 1,
          "create_time": "20/10/2022 20:28:43.283017+08",
          "result": -1
        },
        {
          "id": "9c1455ae02a65e3260ed1ebad09c61a3",
          "problem_id": 1,
          "create_time": "20/10/2022 11:46:27.967291+08",
          "result": -1
        },
        {
          "id": "bb3d7daec6363e9b19096119d6e3549e",
          "problem_id": 2,
          "create_time": "21/10/2022 20:00:11.230308+08",
          "result": 0
        },
        {
          "id": "124db24c2f6dfd6989efd349c69dcef2",
          "problem_id": 20,
          "create_time": "20/10/2022 21:32:26.978159+08",
          "result": -1
        },
        {
          "id": "2941872166361ff4ad220164a664d3cd",
          "problem_id": 20,
          "create_time": "21/10/2022 23:56:21.48434+08",
          "result": -1
        },
        {
          "id": "91f0284da17078b5ab83960360c6a800",
          "problem_id": 1,
          "create_time": "20/10/2022 22:24:52.59652+08",
          "result": -2
        },
        {
          "id": "9cb238c2abf6567db1931ca35240fec3",
          "problem_id": 1,
          "create_time": "20/10/2022 22:25:43.368767+08",
          "result": -1
        },
        {
          "id": "3744c7675d1c0bd6f5dbcbb41dcd6843",
          "problem_id": 2,
          "create_time": "20/10/2022 22:53:51.132783+08",
          "result": -1
        },
        {
          "id": "49582cf19f846a906b5073db0bfcb4b9",
          "problem_id": 1,
          "create_time": "20/10/2022 22:25:11.636588+08",
          "result": -1
        },
        {
          "id": "8718fa48206b63985c6ac45d1ec2bc26",
          "problem_id": 21,
          "create_time": "20/10/2022 23:23:30.6281+08",
          "result": -1
        },
        {
          "id": "c1b0fe39dcb266163d5116036a0056d9",
          "problem_id": 21,
          "create_time": "20/10/2022 23:25:49.700214+08",
          "result": -2
        },
        {
          "id": "39f3ca6f5a5990155cc21d8fbd6baab8",
          "problem_id": 20,
          "create_time": "22/10/2022 00:00:14.065382+08",
          "result": -1
        },
        {
          "id": "b36e6f5a5547fc8cbeb5ef6a57803526",
          "problem_id": 21,
          "create_time": "20/10/2022 23:26:06.599138+08",
          "result": -1
        },
        {
          "id": "90b8db8bc07853a9cd1f43e2dcb5fc97",
          "problem_id": 1,
          "create_time": "21/10/2022 20:03:45.888171+08",
          "result": 0
        },
        {
          "id": "c36e4842816788d37aa72d096f2ac07a",
          "problem_id": 20,
          "create_time": "20/10/2022 23:35:05.744821+08",
          "result": -1
        },
        {
          "id": "bd8826223b1d02eccf448396334b9ce8",
          "problem_id": 1,
          "create_time": "21/10/2022 19:03:28.474992+08",
          "result": 0
        },
        {
          "id": "f14c8f9d6be513ff7c211f781e14b98d",
          "problem_id": 1,
          "create_time": "21/10/2022 19:06:10.258145+08",
          "result": -1
        },
        {
          "id": "574abe984dd075df72dcfc59a34440bf",
          "problem_id": 20,
          "create_time": "20/10/2022 23:38:36.485477+08",
          "result": 4
        },
        {
          "id": "fb6f7afa5f77bda617b65e218b03cdd9",
          "problem_id": 1,
          "create_time": "21/10/2022 20:11:51.145976+08",
          "result": 0
        },
        {
          "id": "860b2158010aebed8c390c7a2b4d571b",
          "problem_id": 20,
          "create_time": "21/10/2022 23:58:04.514165+08",
          "result": -1
        },
        {
          "id": "92cbd5103cc337f4e5a24eb24a34cecb",
          "problem_id": 21,
          "create_time": "22/10/2022 00:02:18.683862+08",
          "result": -2
        },
        {
          "id": "417c982d4a7493c770b5516ebaf93a1c",
          "problem_id": 20,
          "create_time": "20/10/2022 23:38:48.13075+08",
          "result": 4
        },
        {
          "id": "bf1d93dac26753227925052ee151ded4",
          "problem_id": 21,
          "create_time": "20/10/2022 23:47:28.554174+08",
          "result": -1
        },
        {
          "id": "a112034d7a7b9b26aa529a4239ddd0f2",
          "problem_id": 2,
          "create_time": "21/10/2022 20:12:12.526019+08",
          "result": -1
        },
        {
          "id": "23f6b465edea48a5b6891f5ccc3cbecf",
          "problem_id": 1,
          "create_time": "21/10/2022 22:00:24.276457+08",
          "result": 0
        },
        {
          "id": "ae57273f773cc2d1513011ba7e801500",
          "problem_id": 2,
          "create_time": "21/10/2022 22:18:41.520744+08",
          "result": 0
        },
        {
          "id": "fc3d8a6be3c9d8e3942fa0f992d3397c",
          "problem_id": 21,
          "create_time": "22/10/2022 00:03:18.386726+08",
          "result": -1
        },
        {
          "id": "b562f972604f859b4f1ed74f4683cbdb",
          "problem_id": 21,
          "create_time": "22/10/2022 00:05:01.792865+08",
          "result": -1
        },
        {
          "id": "b97d68d06285a1bc3792bfd70f63d1ad",
          "problem_id": 20,
          "create_time": "22/10/2022 00:10:13.563292+08",
          "result": 0
        },
        {
          "id": "99c76f2c89c28e9215ac95b13e5c534a",
          "problem_id": 20,
          "create_time": "22/10/2022 00:12:53.318718+08",
          "result": -1
        },
        {
          "id": "73a1d4b8973c7f9fffd509fb652c3a64",
          "problem_id": 21,
          "create_time": "22/10/2022 00:35:03.897257+08",
          "result": 4
        },
        {
          "id": "cbfdc9876b7999a24cc6d4f1849a9f0c",
          "problem_id": 20,
          "create_time": "22/10/2022 00:52:41.644312+08",
          "result": 4
        },
        {
          "id": "56d583adcb82cdb75f4cd512e976c43a",
          "problem_id": 20,
          "create_time": "22/10/2022 01:17:55.690368+08",
          "result": 1
        },
        {
          "id": "ec675b87e1ff2f4f410c745ba9534915",
          "problem_id": 21,
          "create_time": "22/10/2022 00:35:25.613861+08",
          "result": 4
        },
        {
          "id": "f7d99d8b5ed18c443043f34889b3367a",
          "problem_id": 20,
          "create_time": "22/10/2022 00:53:34.17192+08",
          "result": -1
        },
        {
          "id": "b88c74192d52a3f8b08edeb29ed8868a",
          "problem_id": 21,
          "create_time": "22/10/2022 01:18:12.420811+08",
          "result": 0
        },
        {
          "id": "18990a01ec755ecce78f8806bba5140f",
          "problem_id": 21,
          "create_time": "22/10/2022 01:49:23.107856+08",
          "result": -1
        },
        {
          "id": "ba7e993c52eb65cdbcfe8b096d938901",
          "problem_id": 21,
          "create_time": "22/10/2022 00:36:16.187418+08",
          "result": -1
        },
        {
          "id": "7df87af04d963de84acba0a3664123ec",
          "problem_id": 20,
          "create_time": "22/10/2022 00:40:15.917459+08",
          "result": -1
        },
        {
          "id": "27c4ddb643b5968909bd19d02a7f8b7e",
          "problem_id": 20,
          "create_time": "22/10/2022 00:43:47.399444+08",
          "result": -1
        },
        {
          "id": "c1b3774ce2b356cbcd573a7248cd1fa3",
          "problem_id": 21,
          "create_time": "22/10/2022 01:03:24.016367+08",
          "result": 0
        },
        {
          "id": "f2e0b66e740e8efde83ef363c37b3e66",
          "problem_id": 20,
          "create_time": "22/10/2022 00:45:46.43946+08",
          "result": -2
        },
        {
          "id": "cf9e26b98f87d341ca0192e2efdef7d2",
          "problem_id": 20,
          "create_time": "22/10/2022 00:46:03.632272+08",
          "result": 0
        },
        {
          "id": "35f60d13b17d1449f6b796c1b77e5559",
          "problem_id": 20,
          "create_time": "22/10/2022 00:48:01.070655+08",
          "result": 0
        },
        {
          "id": "6037bed30e47b1bba3545c5a86824098",
          "problem_id": 21,
          "create_time": "22/10/2022 00:48:42.224282+08",
          "result": 0
        },
        {
          "id": "917c4b780a00637824920624abcf6db2",
          "problem_id": 21,
          "create_time": "22/10/2022 00:51:46.649406+08",
          "result": -1
        },
        {
          "id": "22b6eceb9e0457038d01dcbc5f4524d2",
          "problem_id": 20,
          "create_time": "22/10/2022 00:52:55.594164+08",
          "result": 4
        },
        {
          "id": "20d2b41752b56ab67fd98179e3c3f12f",
          "problem_id": 20,
          "create_time": "22/10/2022 00:58:19.324503+08",
          "result": 0
        },
        {
          "id": "c5d29c0ce114b9abf299a64d919f7de6",
          "problem_id": 20,
          "create_time": "22/10/2022 01:07:09.172198+08",
          "result": 0
        },
        {
          "id": "35c8855971bba873eebf8a7f4ab36a17",
          "problem_id": 21,
          "create_time": "22/10/2022 01:41:32.427225+08",
          "result": 0
        },
        {
          "id": "c1e642b21c169266460edc49557ea022",
          "problem_id": 20,
          "create_time": "22/10/2022 01:00:48.597147+08",
          "result": -1
        },
        {
          "id": "f9d9d2db785e0a4f5ca659db437be945",
          "problem_id": 21,
          "create_time": "22/10/2022 01:07:56.861773+08",
          "result": 0
        },
        {
          "id": "56fdcb38533991109600132703559b8d",
          "problem_id": 21,
          "create_time": "22/10/2022 01:49:01.519602+08",
          "result": 4
        },
        {
          "id": "1d43fadfc1647356ef3696565e5d4118",
          "problem_id": 20,
          "create_time": "22/10/2022 01:52:50.244233+08",
          "result": -1
        },
        {
          "id": "70df3034cdcbc8110c2f91d483491dfd",
          "problem_id": 20,
          "create_time": "22/10/2022 01:08:37.143923+08",
          "result": 0
        },
        {
          "id": "43a5909043dd33a305b270f79416af49",
          "problem_id": 21,
          "create_time": "22/10/2022 01:08:56.371414+08",
          "result": 0
        },
        {
          "id": "477a145b40fc81e31a1d094bd0fe08f5",
          "problem_id": 20,
          "create_time": "22/10/2022 01:12:00.396434+08",
          "result": -1
        },
        {
          "id": "9fb297e9847ab7a92162716c2a4c49bf",
          "problem_id": 21,
          "create_time": "22/10/2022 01:15:40.116348+08",
          "result": -2
        },
        {
          "id": "330964dbf1df270905cdc3e2db8d0b09",
          "problem_id": 20,
          "create_time": "22/10/2022 01:10:41.809253+08",
          "result": 4
        },
        {
          "id": "e4be5bb27c272b75cdce40bbd4d65ec4",
          "problem_id": 20,
          "create_time": "22/10/2022 01:12:33.941937+08",
          "result": 4
        },
        {
          "id": "a2288d27f9f019711123e5f3e11d3f53",
          "problem_id": 20,
          "create_time": "22/10/2022 01:14:39.315078+08",
          "result": 1
        },
        {
          "id": "27322e75b00862b39467ae0394c90aa8",
          "problem_id": 21,
          "create_time": "22/10/2022 01:16:06.93233+08",
          "result": 0
        },
        {
          "id": "c6cec33bd82d70d1915f6d3d97122db1",
          "problem_id": 20,
          "create_time": "22/10/2022 01:41:24.183735+08",
          "result": 1
        },
        {
          "id": "8cc45d8a393a6a4e367e7ba20c964b50",
          "problem_id": 20,
          "create_time": "22/10/2022 01:44:35.731975+08",
          "result": -1
        },
        {
          "id": "ef04c2dfd8fa17a09c305788e90ba1a0",
          "problem_id": 20,
          "create_time": "22/10/2022 01:45:10.621642+08",
          "result": -1
        },
        {
          "id": "ef4ed3aaf13a426aabd16f291adc120b",
          "problem_id": 20,
          "create_time": "22/10/2022 01:45:33.463245+08",
          "result": 0
        },
        {
          "id": "3d792030882d02ad6fcf01e38273462a",
          "problem_id": 20,
          "create_time": "22/10/2022 01:47:50.805053+08",
          "result": -1
        },
        {
          "id": "1d79c1d9c7ca6a10fcdf150e1ce21e62",
          "problem_id": 20,
          "create_time": "22/10/2022 01:53:17.651945+08",
          "result": -1
        },
        {
          "id": "144065bdd0f26a175fe1e0a551be6c2b",
          "problem_id": 20,
          "create_time": "22/10/2022 02:36:16.420021+08",
          "result": 0
        },
        {
          "id": "f3ba0869e07cd84d87479365f91d259e",
          "problem_id": 21,
          "create_time": "22/10/2022 01:51:27.812502+08",
          "result": 4
        },
        {
          "id": "18c3fea80bfeaa2fc16e07a92c0874d2",
          "problem_id": 20,
          "create_time": "22/10/2022 02:42:41.229707+08",
          "result": 0
        },
        {
          "id": "1eeb0b6bb7bf2a19d1b9d3635a72e52f",
          "problem_id": 20,
          "create_time": "22/10/2022 01:53:34.249063+08",
          "result": 0
        },
        {
          "id": "2bc86d2c35bce09d0837e87ab7aa54a2",
          "problem_id": 20,
          "create_time": "22/10/2022 02:38:46.173889+08",
          "result": 0
        }
      ]
    }
  },
  components: {
    CenterLeft1Chart
  },
  created() {
    for(let i = 0; i < this.allData.length; i++) {
      if(this.allData[i].problem_id === this.problem_id) {
        this.needData.push(this.allData[i])
        // console.log(this.allData[i])
        this.numberData[0].number.number[0]++;
        // console.log(this.numberData[0])
        if(this.allData[i].result === 0) {
          this.numberData[1].number.number[0]++;
        } else {
          this.numberData[3].number.number[0]++;
        }
      }
    }
  },
  mounted() {
    this.changeTiming()

    // for(let i = 0; i < this.allData.length; i++) {
    //   if(this.allData[i].problem_id === this.problem_id) {
    //     // console.log(this.allData[i])
    //     this.numberData[0].number.number[0]++;
    //     // console.log(this.numberData[0])
    //     // if(this.allData[i].result === 0) {
    //     //   this.numberData[0].number.number[1]++;
    //     // }
    //   }
    // }

    console.log(this.numberData)
  },
  methods: {
    changeTiming() {
      // setInterval(() => {
      //   this.changeNumber()
      // }, 3000)
    },
    changeNumber() {
      this.numberData.forEach((item, index) => {
        item.number.number[0] += ++index
        item.number = { ...item.number }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$box-width: 300px;
$box-height: 410px;

#centerLeft1 {
  padding: 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .dv-dec-3 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }

  .bottom-data {
    .item-box {
      & > div {
        padding-right: 5px;
      }
      font-size: 14px;
      float: right;
      position: relative;
      width: 50%;
      color: #d3d6dd;
      .dv-digital-flop {
        width: 120px;
        height: 30px;
      }
      // 金币
      .coin {
        position: relative;
        top: 6px;
        font-size: 20px;
        color: #ffc107;
      }
      .colorYellow {
        color: yellowgreen;
      }
      p {
        text-align: center;
      }
    }
  }
}
</style>
