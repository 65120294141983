<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: {
        indicatorData: [
          { name: "AC", max: 191 },
          { name: "CE", max: 637 },
          { name: "WA", max: 185 },
          { name: "TLE", max: 64},
          { name: "RE", max: 152 },
        ],
        dataBJ: [
          [94, 69, 114, 2.08, 73, 39, 22],
        ],
        dataGZ: [
          [84, 94, 140, 2.238, 68, 18, 22],
        ],
        dataSH: [
          [91, 45, 125, 0.82, 34, 23, 1],
        ]
      }
    }
  },
  components: {
    Chart,
  }
}
</script>